import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FormlyValidatorUtils } from '@ice';
import type { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { BehaviorSubject } from 'rxjs';
import type { FieldValidatorService } from 'services/validators/field.validator.service';
import { FormConfig, SearchExpressions } from './form-config';

export class WorksSearchExpressions extends SearchExpressions {
  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any) {
    if (key.includes('xref')) {
      key = 'xref';
    }
    switch (key) {
      case 'contributorsAgreementId':
        return `{"equals":{"contributions.agreements.agreementId": "${value}"}}`;
      case 'agreementId':
        return `{"equals":{"relations[XREF].otherId":"${this.formatExactIDSearch(value)}"}}`;
      case 'creator':
        const pattern = `{"or":[<<searchCreator>>]}`;
        const patternCreator = `{"wildcard":{"contributions.contributor.partyName.attributes.name":"*<<creator>>*"}},
        {"wildcard":{"contributions.contributor.partyName.attributes.firstName":"*<<creator>>*"}},
        {"wildcard":{"contributions.contributor.partyName.attributes.names.name":"*<<creator>>*"}},
        {"wildcard":{"contributions.contributor.partyName.attributes.names.firstName":"*<<creator>>*"}},
        {"wildcard":{"contributions.contributor.party.partyNames.partyName.attributes.names.name":"*<<creator>>*"}},
        {"wildcard":{"contributions.contributor.party.partyNames.partyName.attributes.names.firstName":"*<<creator>>*"}},
        {"wildcard":{"contributions.contributor.party.partyNames.partyName.attributes.name":"*<<creator>>*"}},
        {"wildcard":{"contributions.contributor.party.partyNames.partyName.attributes.firstName":"*<<creator>>*"}}`;

        let contentPattern;

        const valueArray = value.split(',');

        if (valueArray.length > 1) {
          contentPattern = valueArray.map(creator => patternCreator.replace(/<<creator>>/g, creator.trim())).join(',');
        } else {
          contentPattern = patternCreator.replace(/<<creator>>/g, value.trim());
        }

        return pattern.replace(/<<searchCreator>>/g, contentPattern.trim());
      case 'creatorIpiNameNumber':
        return `{"wildcard":{"contributions.contributor.partyName.relations[XREF].otherId": "IPI:*${value}*"}}`;
      case 'creatorIpNameKey':
        return `{
          "or": [
            {"wildcard":{"claims.claimant.partyName.relations.otherId": "ICE:*${value}*"}},
            {"wildcard":{"contributions.contributor.partyName.relations[XREF].otherId": "ICE:*${value}*"}}
          ]
        }`;
      case 'title':
        return `{"or":[{"wildcard":{"attributes.titles.*.titleValue":"*${value}*"}},{"wildcard":{"attributes.titles.*.national":"*${value}*"}}]}`;
      case 'xref':
        return `{"equals":{"relations[XREF].otherId":"${this.formatExactIDSearch(value)}"}}`;
      case 'artist':
        return `{"wildcard":{"partyNames.partyName.attributes.name":"*${value}*"}}`;
      case 'publisherName': {
        return `
          {
            "or": [
                {"wildcard":{"claims.claimant.partyName.attributes.names.name": "${value}"}},
                {"wildcard":{"claims.claimant.partyName.attributes.names.firstName": "${value}"}},
                {"wildcard":{"claims.claimant.partyName.attributes.name": "${value}"}}
            ]
          }
        `;
      }
      case 'publisherIpNameKey':
        return `{
          "or": [
            {"wildcard":{"claims.claimant.partyName.relations.otherId": "ICE:*${value}*"}}
          ]
        }`;
      case 'publisherIpiNameNumber':
        return `{
          "or": [
            {"wildcard":{"claims.claimant.partyName.relations.otherId": "IPI:*${value}*"}}
          ]
        }`;
    }
    return `{"wildcard":{"${'attributes.' + key}":"*${value}*"}}`;
  }

  getExtraSearchExpression() {
    return '';
  }
}

export class SearchCopyrightWorksForm implements FormConfig {
  legacyAgreementField: FormlyFieldConfig;
  protected legacyAgreementMessage: BehaviorSubject<string>;

  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private fieldValidatorService: FieldValidatorService) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.legacyAgreementMessage = new BehaviorSubject('');
  }

  getForm(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-5',
            key: 'title',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('WORKS.TITLE'), required: false },
          },
          {
            className: 'flex-4',
            key: 'xref',
            wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.XREF'),
              required: false,
              infoText: this.translate.instant('COMMON.REFERENCE_INFO'),
            },
            asyncValidators: {
              workRefValidation: FormlyValidatorUtils.getReferenceValidator(this.translate, 'WORKS.ERRORS.INVALID_WORKS_XREF'),
            },
          },
          {
            className: 'flex-4',
            key: 'creator',
            wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('WORKS.CREATOR'), required: false, infoText: this.translate.instant('COMMON.CREATOR_INFO') },
          },
          {
            className: 'flex-1',
            key: 'creatorIpiNameNumber',
            wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.CREATOR_ID'),
              required: false,
              infoText: this.translate.instant('COMMON.IPI_NAME_INFO'),
            },
            validators: {
              isNumber: FormlyValidatorUtils.isNumber({ translate: this.translate }),
            },
          },
          {
            className: 'flex-1',
            key: 'creatorIpNameKey',
            wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.CREATOR_IP_NAME_KEY'),
              required: false,
              infoText: this.translate.instant('COMMON.NAME_KEY_INFO'),
            },
            validators: {
              isNumber: FormlyValidatorUtils.isNumber({ translate: this.translate }),
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-4',
            key: 'publisherName',
            wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.PUBLISHER_NAME'),
              required: false,
              infoText: this.translate.instant('COMMON.WILDCARD_INFO'),
            },
          },
          {
            className: 'flex-1',
            key: 'publisherIpNameKey',
            wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.PUBLISHER_IP_NAME_KEY'),
              required: false,
              infoText: this.translate.instant('COMMON.NAME_KEY_INFO'),
            },
            validators: {
              isNumber: FormlyValidatorUtils.isNumber({ translate: this.translate }),
            },
          },
          {
            className: 'flex-1',
            key: 'publisherIpiNameNumber',
            wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.PUBLISHER_IPI_NAME_NUMBER'),
              required: false,
              infoText: this.translate.instant('COMMON.IPI_NAME_INFO'),
            },
            validators: {
              isNumber: FormlyValidatorUtils.isNumber({ translate: this.translate }),
            },
          },
          ...this.fieldValidatorService.agreementRefFieldAndLegacyValidator('agreementId', this.translate),
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'origin',
            type: 'select',
            templateOptions: {
              label: this.translate.instant('WORKS.WORK_TYPE'),
              options: [
                { label: 'Any', value: null },
                { label: 'Original', value: 'ORI' },
                { label: 'Modification/Version', value: 'MOD' },
              ],
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'artist',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('WORKS.ARTIST_NAME'), required: false },
          },
          {
            className: 'flex-1',
            key: 'purpose',
            type: 'select',
            templateOptions: {
              label: this.translate.instant('WORKS.PURPOSE'),
              options: this.getPurposeValues(),
              required: false,
            },
          },
        ],
      },
    ];
  }

  getPurposeValues() {
    return [
      { label: 'Any', value: null },
      { label: this.translate.instant('WORKS.PURPOSES.AUD'), value: 'AUD' },
      { label: this.translate.instant('WORKS.PURPOSES.COM'), value: 'COM' },
      { label: this.translate.instant('WORKS.PURPOSES.FIL'), value: 'FIL' },
      { label: this.translate.instant('WORKS.PURPOSES.GEN'), value: 'GEN' },
      { label: this.translate.instant('WORKS.PURPOSES.LIB'), value: 'LIB' },
      { label: this.translate.instant('WORKS.PURPOSES.MUL'), value: 'MUL' },
      { label: this.translate.instant('WORKS.PURPOSES.RAD'), value: 'RAD' },
      { label: this.translate.instant('WORKS.PURPOSES.TEL'), value: 'TEL' },
      { label: this.translate.instant('WORKS.PURPOSES.THR'), value: 'THR' },
      { label: this.translate.instant('WORKS.PURPOSES.VID'), value: 'VID' },
      { label: this.translate.instant('WORKS.PURPOSES.FIB'), value: 'FIB' },
      { label: this.translate.instant('WORKS.PURPOSES.FLL'), value: 'FLL' },
      { label: this.translate.instant('WORKS.PURPOSES.FIT'), value: 'FIT' },
      { label: this.translate.instant('WORKS.PURPOSES.FIV'), value: 'FIV' },
      { label: this.translate.instant('WORKS.PURPOSES.FIR'), value: 'FIR' },
      { label: this.translate.instant('WORKS.PURPOSES.TEB'), value: 'TEB' },
      { label: this.translate.instant('WORKS.PURPOSES.TLL'), value: 'TLL' },
      { label: this.translate.instant('WORKS.PURPOSES.TET'), value: 'TET' },
      { label: this.translate.instant('WORKS.PURPOSES.TEV'), value: 'TEV' },
      { label: this.translate.instant('WORKS.PURPOSES.TER'), value: 'TER' },
    ];
  }
}
