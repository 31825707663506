import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FieldType } from '@ngx-formly/material';
import { cloneDeep, filter, head, isString, toLower } from 'lodash';
import { SelectCodeItem } from 'models/selectCodeItem';
import { combineLatest, isObservable, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, startWith, takeUntil, tap } from 'rxjs/operators';
/**
 * @title Highlight the first autocomplete option
 */
@Component({
  selector: 'ice-autocomplete',
  templateUrl: './autocomplete-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteTypeComponent extends FieldType implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  filteredOptions: any | Observable<any[]>;
  clearing = false;
  currentOptions = [];
  filterSubject$ = new Subject();
  unsubscribeAll = new Subject();
  _value: string;
  flex: string;
  initialValue: string;
  tempTooltip = '';

  @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete: MatAutocompleteTrigger;
  @ViewChild('inputAutoComplete', { static: true }) autocompleteInput: ElementRef;

  constructor(public chDet: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.initialValue = cloneDeep(this.value);
    this.flex = this.to.flex || '90';
    const valuechanges$ = this.filterSubject$.pipe(
      debounceTime(250),
      startWith<string | any>(''),
      map(value => {
        return typeof value === 'string' ? value : value && value.label;
      }),
    );

    this.formControl.statusChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(status => {
      this.chDet.detectChanges();
    });

    this.formControl.valueChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(value => {
      // OnReset show all values
      if (value && value.value === this.initialValue) {
        this.filterSubject$.next(this.currentOptions);
      }
      if (value && this.to.onSearchEvent) {
        this.to.onSearchEvent(value.value || value);
      }
    });

    if (isObservable(this.to.options)) {
      this.filteredOptions = combineLatest([valuechanges$, this.to.options]).pipe(
        tap(([label, options]) => {
          this.currentOptions = options;
        }),
        map(([label, options]) => this._filter(label, options)),
      );
    } else {
      this.filteredOptions = valuechanges$.pipe(map(label => this._filter(label, this.to.options)));
      this.currentOptions = this.to.options;
    }
  }

  private _filter(value: string, options: any): any[] {
    this._value = value;
    const filteredOptions = (value && filter(options, option => toLower(option['label']).includes(value.toLowerCase()))) || [];
    return filteredOptions.length > 0 ? filteredOptions : options;
  }

  onClick(event, value) {
    this.formControl.setValue(value);
    this.filterSubject$.next(value);
  }

  displayFn(item?: SelectCodeItem): string | undefined {
    return isString(item) ? item : item?.label;
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  ngAfterViewChecked() {
    if (this.formControl?.value) {
      const filteredItem = filter(this.currentOptions, option => option['value'] === this.formControl.value);
      if (filteredItem?.length) {
        this.formControl.setValue(head(filteredItem));
        this.chDet.detectChanges();
      }
    }
  }

  setFilter(event) {
    const value = event && event.target && event.target.value;
    this.filterSubject$.next(value);
  }

  onBlurMethod(event) {
    const currentValue = this.formControl?.value?.label || this.formControl?.value;
    if (!!currentValue && !isObservable(currentValue)) {
      const currentOption = this.currentOptions?.find(option => currentValue?.toLowerCase() === toLower(option.label));
      if (!!currentOption) {
        this.formControl.setValue(currentOption);
      } else {
        if (!this.to.allowAnyValue) {
          this.formControl.setValue(null);
        }
      }
    }
    // To hide the options when the reset button is pressed in advanced search
    setTimeout(() => {
      if (this.clearing) {
        this.clearing = false;
      } else {
        this.autocomplete.closePanel();
      }
    }, 300);
  }

  clearValue() {
    this.clearing = true;
    setTimeout(() => {
      this.formControl.setValue(null);
      this.filterSubject$.next('');
      this.form.markAsDirty();
    }, 100);
  }

  doHighlight(event) {
    if (event) {
      event.stopPropagation();
      this.autocompleteInput.nativeElement.select();
    }
  }

  enterField(event, tooltipValue) {
    // If it's ellipsed
    if (event?.target && event.target.offsetWidth < event.target.scrollWidth) {
      this.tempTooltip = this.getTooltip(tooltipValue);
    }
  }

  leaveField() {
    this.tempTooltip = '';
  }

  getTooltip(tooltipValue) {
    return tooltipValue?.label || tooltipValue;
  }
}
