import { SharePictureParameters, SharePictureStage } from '@ice';

export interface Shares {
  share?: Share;
  loading?: boolean;
  salientSessionId: string;
  auditInfo?: SharePicturesAuditInfo;
}

export interface SharePicture {
  id: string;
  version: number;
  parameters: SharePictureParameters;
  stages: SharePictureStage[];
  auditInfo?: SharePicturesAuditInfo;
  tags?: any;
  salientSessionId?: any;
  error?: any;
  status?: any;
}

export interface SharePicturesAuditInfo {
  sharePictureId: string;
  version: number;
  triggeredRules: any;
}

export interface ShareMap {
  [id: string]: Share;
}

export interface Share {
  ownership?: Stage;
  payment?: Stage;
  data?: Stage;
  counter_claim: Stage;
  repertoire: Stage;
  timestamp?: Date;
  debugSharePicture?: any;
  status?: string;
  response?: SharePicture;
}

export interface Stage {
  rows?: any[];
  tree?: any;
  levels?: any;
}
export const orderList = [
  'copyright',
  'ownership_selection',
  'ownership_stim',
  'ownership_tono',
  'ownership_prs',
  'ownership_std',
  'ownership_buma',
  'ownership_koda',
  'ownership_mcps',
  'ownership_sabam',
  'ownership_teosto',
  'counterclaim',
  'counterclaim_payment',
  'payment_selection',
  'payment_stim',
  'payment_tono',
  'payment_prs',
  'payment_std',
  'payment_buma',
  'payment_koda',
  'payment_mcps',
  'payment_sabam',
  'payment_teosto',
];
