import {
  ActivityDetail,
  AgreementDetail,
  CopyrightOwnershipTableItem,
  IpDetail,
  IpDetailCleaned,
  RepertoireDetail,
  SharePictures,
  TerritoryDetail,
  WorkDetail,
  initialAgreementChainsState,
} from '@ice';
import { SectionsConfig } from 'config/sections-config';
import { ApiResponseStatus } from 'config/sections-config/api-call';
import { JobItem } from 'models/copyright/bulk-updates/jobs.model';
import { AgreementConflict } from 'models/copyright/detail/agreement-conflict';
import { AgreementGroup } from 'models/copyright/detail/agreement-group';
import { CounterClaimDetail } from 'models/copyright/detail/counterclaim';
import { EditMode, EditModeHistory } from 'models/copyright/detail/edit-mode';
import { OrganizationDetail } from 'models/copyright/detail/organizations';
import { SocietyDetail } from 'models/copyright/detail/society';
import { ExportMode } from 'models/copyright/search/export-mode';
import { SelectMode } from 'models/copyright/search/select-mode';
import { UpdateMode } from 'models/copyright/search/update-mode';
import { Shares } from 'models/copyright/shares/shares';
import { PageableData } from 'models/response/cube-register.response';
import { TisnTisaConversionItem } from 'models/tisnTisaConversionItem';
import { UserCleaned } from 'models/users/users.model';

export enum CopyrightItemView {
  Detail,
  Edit,
  New,
  Clone,
}

export interface CopyrightState {
  agreements: AgreementDetail;
  works: WorkDetail;
  shares: Shares;
  workToCompareSharePictures: SharePictures;
  ownershipTableItems: CopyrightOwnershipTableItem[];
  manuscriptTableSelected: boolean;
  ips: IpDetailCleaned & IpDetail;
  repertoires: RepertoireDetail;
  societies: SocietyDetail;
  territories: TerritoryDetail;
  'agreement-conflict': AgreementConflict;
  'agreement-group': AgreementGroup;
  activity: ActivityDetail;
  'counter-claims': CounterClaimDetail;
  itemView: CopyrightItemView;
  users: UserCleaned;
  allUsers?: any;
  organizations: OrganizationDetail;
  createMode: EditMode;
  editMode: EditMode;
  editModeHistory: EditModeHistory[];
  selectionMode: SelectMode;
  exportMode: ExportMode;
  updateMode: UpdateMode;
  loading: boolean;
  response: any;
  manuscriptData: any;
  isPopupOkButtonMarked: boolean;
  workAnalysis?: any;
  retentionWorks: any[];
  deleteItem: { deleting: boolean; error: string; msg: { text: string } };
  dummyIp?: { data?: any; status: string; error?: any };
  scroll: any;
  reports: any;
  dialogSearchData: PageableData<any>;
  apiCallStatus: ApiResponseStatus;
  mergeIpMode: boolean;
  mergeIpItems?: any[];
  allApiData?: any;
  transferIpWorksMode: boolean;
  'bulk-updates'?: { jobs: JobItem[] };
  allTerritories?: TisnTisaConversionItem[];
  ignoredClaims?: { claimId: string; ipiNameKey: string; name: string }[];
  selectionDatatableSelectableEnabled: boolean;
}

const defaultShares: Shares = {
  salientSessionId: null,
};

export const initialCopyrightState: CopyrightState = {
  agreements: { auditTypes: SectionsConfig.AGREEMENTS.auditTypes },
  works: { auditTypes: SectionsConfig.WORKS.auditTypes, openTrimClaims: false },
  shares: defaultShares,
  workToCompareSharePictures: null,
  ownershipTableItems: [],
  manuscriptTableSelected: false,
  ips: { auditTypes: SectionsConfig.IPS.auditTypes, agreementChains: initialAgreementChainsState },
  repertoires: { auditTypes: SectionsConfig.REPERTOIRES.auditTypes },
  societies: null,
  territories: null,
  activity: { auditTypes: SectionsConfig.CONFLICTS.auditTypes },
  'agreement-conflict': null,
  'agreement-group': { auditTypes: SectionsConfig.AGREEMENT_GROUP.auditTypes },
  'counter-claims': { auditTypes: SectionsConfig.COUNTERCLAIMS.auditTypes },
  'bulk-updates': null,
  itemView: null,
  users: null,
  organizations: null,
  createMode: null,
  editMode: null,
  editModeHistory: [],
  selectionMode: null,
  exportMode: null,
  updateMode: null,
  loading: false,
  manuscriptData: null,
  response: null,
  isPopupOkButtonMarked: false,
  retentionWorks: [],
  deleteItem: null,
  scroll: { activity: 0 },
  reports: null,
  apiCallStatus: null,
  dialogSearchData: null,
  mergeIpMode: false,
  transferIpWorksMode: false,
  selectionDatatableSelectableEnabled: false,
};
