import { ContributorsUtils, CopyrightUtils, RelationsUtils } from '@ice';
import { TranslateService } from '@ngx-translate/core';
import { NOT_AVAILABLE } from 'config/constants/global.constants';
import {
  DEFAULT_CISAC_GEMA,
  DEFAULT_COUNTRY,
  DEFAULT_DISTRIBUTION_SOCIETY_MR,
  DEFAULT_DISTRIBUTION_SOCIETY_PR,
  DEFAULT_FILTER_TERRITORY,
  DEFAULT_MR_CISAC,
  DEFAULT_MR_RIGHT_TYPE,
  DEFAULT_PR_CISAC,
  DEFAULT_PR_RIGHT_TYPE,
  GEMA,
  MIN_VERTICAL_LEVELS,
  NODE_COLORS,
  ROOT_COLOR,
  STATUS_NON_APPLICABLE,
  TRUNCATE_LENGHT,
} from 'config/constants/shares.constants';
import { UNAUTHORIZED } from 'config/constants/works.constants';
import { environment } from 'config/env';
import { cloneDeep, find, flatten, flattenDeep, get, includes, isNaN, last, maxBy, padStart, pickBy, replace, some, toPairs, size } from 'lodash';
import { CopyrightOwnershipTableItem, PayloadSharePicture, SharePictureFullClaim, SharePictureRow, SharePictureStage, SharePictureTypes, TreeData } from 'models';
import { IconInterface } from 'models/copyright/detail/icon';
import { Share, SharePicture, orderList } from 'models/copyright/shares/shares';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTimeUtils } from '../date-time/date-time.utils';
import { IpUtils } from '../ip/ip.utils';
import { getPercent2Decimals } from '../mathUtils';
import { SocietiesUtils } from '../societies/societies.utils';
import { StringUtils } from '../string/string.utils';
import { ClaimsUtils } from './claims.utils';

export class SharePictureUtils {
  static getRequestSharePicture(request, ns) {
    let mrDistributionSocietyId: string;
    let prDistributionSocietyId: string;
    const { workId, country, usageDate, distributionDate, mrRightType, prRightType, usageFactorMR, usageFactorPR, repertoire } = request;
    ({ mrDistributionSocietyId, prDistributionSocietyId } = request);
    const usageFactorPRString = (usageFactorPR && `"usage-factor-pr": ["${usageFactorPR}"]`) || '';
    const usageFactorMRString = (usageFactorMR && `"usage-factor-mr": ["${usageFactorMR}"]`) || '';
    const extendedParamsString = usageFactorPR && usageFactorMR ? `${usageFactorPRString},${usageFactorMRString}` : `${usageFactorPRString}${usageFactorMRString}`;

    mrDistributionSocietyId = mrDistributionSocietyId ? mrDistributionSocietyId : ns === GEMA ? DEFAULT_CISAC_GEMA : DEFAULT_MR_CISAC;
    prDistributionSocietyId = prDistributionSocietyId ? prDistributionSocietyId : ns === GEMA ? DEFAULT_CISAC_GEMA : DEFAULT_PR_CISAC;

    return {
      isGet: false,
      newProp: ['sharePicture'],
      url: `${environment.urlGetSharePicture}/work/CUBE/${workId}`,
      queryParams: {
        include: `work.attributes,
        parameters,auditInfo,
          stages.claims.claimant.partyName.attributes,
          stages.claims.claimant.partyName.relations[XREF],
          stages.claims.claimant.party.attributes,
          stages.claims.prShare,
          stages.claims.mrShare,
          stages.claims.claimant.party.relations[XREF]`,
      },
      query: `{
        "country": "${country || DEFAULT_COUNTRY}",
        "distributionDate": "${distributionDate || DateTimeUtils.formatDate(moment())}",
        "mrDistributionSocietyId": "${SharePictureUtils.parseSocietyId(mrDistributionSocietyId)}",
        "mrRightType": "${mrRightType || DEFAULT_MR_RIGHT_TYPE}",
        "prDistributionSocietyId": "${SharePictureUtils.parseSocietyId(prDistributionSocietyId)}",
        "prRightType": "${prRightType || DEFAULT_PR_RIGHT_TYPE}",
        "repertoire": ${repertoire || false},
        "usageDate": "${usageDate || DateTimeUtils.formatDate(moment())}",
        "extendedParams": {${extendedParamsString}},
        "populateCounterclaimIndicator": true
    }`,
      responseType: 'response',
    };
  }

  static parseSocietyId(societyId: string) {
    const prefix = parseFloat(societyId.split(':')[1]);
    return (societyId && societyId.split(':').length > 1 && societyId.split(':')[0] + ':' + (isNaN(prefix) ? societyId.split(':')[1] : prefix)) || '';
  }

  static fitlerToUrlParams(request) {
    return (request && toPairs(request).filter(([parameter, value]) => value && !['repertoire', 'ns', 'workId', 'timeout'].includes(parameter))) || [];
  }

  static filterQueryParams(queryParams) {
    return pickBy(queryParams, (value, key) =>
      [
        'country',
        'usageDate',
        'distributionDate',
        'mrRightType',
        'prRightType',
        'mrDistributionSocietyId',
        'prDistributionSocietyId',
        'repertoire',
        'removeE',
        'removeSE',
      ].includes(key),
    );
  }

  static generateSharePicturesRowsAndTree(sharePicture: SharePicture, translate: TranslateService, workTitle: string): { share: Share } {
    const sharePictureTreeNodes = {};
    const sharePictureRows = {};
    const levels = {};
    sharePicture.stages.map((stage: SharePictureStage, index: number) => {
      sharePictureTreeNodes[stage.stage] = this.getSharePictureTreeNodes(stage, sharePicture.id, workTitle);
      const { rows } = this.getSharePictureRows(stage, sharePicture.id, translate);
      sharePictureRows[stage.stage] = rows;
      levels[stage.stage] = rows.length ? this.getMaxLevelsFromRows(rows) : {};
    });

    const share = {
      ownership: {
        rows: sharePictureRows[SharePictureTypes.OWNERSHIP.toUpperCase()],
        tree: sharePictureTreeNodes[SharePictureTypes.OWNERSHIP.toUpperCase()],
        levels: levels[SharePictureTypes.OWNERSHIP.toUpperCase()],
      },
      payment: {
        rows: sharePictureRows[SharePictureTypes.PAYMENT.toUpperCase()],
        tree: sharePictureTreeNodes[SharePictureTypes.PAYMENT.toUpperCase()],
        levels: levels[SharePictureTypes.PAYMENT.toUpperCase()],
      },
      data: {
        rows: sharePictureRows[SharePictureTypes.DATA.toUpperCase()],
        tree: sharePictureTreeNodes[SharePictureTypes.DATA.toUpperCase()],
        levels: levels[SharePictureTypes.DATA.toUpperCase()],
      },
      repertoire: {
        rows: sharePictureRows[SharePictureTypes.PAYMENT.toUpperCase()],
        tree: sharePictureTreeNodes[SharePictureTypes.PAYMENT.toUpperCase()],
        levels: levels[SharePictureTypes.PAYMENT.toUpperCase()],
      },
      counter_claim: {
        rows: sharePictureRows[SharePictureTypes.COUNTER_CLAIM.toUpperCase()],
        tree: sharePictureTreeNodes[SharePictureTypes.COUNTER_CLAIM.toUpperCase()],
        levels: levels[SharePictureTypes.COUNTER_CLAIM.toUpperCase()],
      },
      timestamp: new Date(),
      status: sharePicture.status,
    };
    return { share };
  }

  static getSharePictureRows(stage: SharePictureStage, sharePictureId: string, translate: TranslateService): { rows: SharePictureRow[]; totalSharesRows: SharePictureRow[] } {
    if (!stage || !stage.claims) {
      return { rows: [], totalSharesRows: [] };
    }
    const claimsByParentId = new Map<string, SharePictureFullClaim[]>();
    claimsByParentId.set(sharePictureId, []);
    let rows: SharePictureRow[] = [];
    const totalSharesRows = SharePictureUtils.getCopyrightOwnershipTreeTableTotals(stage.claims, translate); // Check if totalSharesRows is used
    stage.claims.map((claim: SharePictureFullClaim) => {
      // set sharePictureId as the parentId for root claims (without parentId)
      const parentId = claim.parentId || sharePictureId;
      SharePictureUtils.addClaimToParentNode(claim, claimsByParentId, parentId);
    });
    const sharePictureRootClaims = claimsByParentId.get(sharePictureId);
    // sort claims in the desired order
    sharePictureRootClaims.sort((a, b) => {
      // if the role is 'E' move to the start of the array
      if (a.role === 'E') {
        return -1;
      }
      // Creator has children, move it to the start of the array
      const hasChildren = claimsByParentId.get(a.id).length > 0;
      if (hasChildren) {
        return -1;
      }
      return 1;
    });
    SharePictureUtils.calculateLevelsAndGenerateRows(1, 0, sharePictureId, claimsByParentId, 1, rows, translate);
    rows = SharePictureUtils.mergeSpecialRows(rows || []);
    return { rows, totalSharesRows };
  }

  static getMaxLevelsFromRows(rows: SharePictureRow[]): any {
    const hLevelRow = maxBy(rows, 'level');
    const vLevelRow = maxBy(rows, 'vLevel');
    return {
      maxHLevel: hLevelRow.level + 1,
      vLevel: vLevelRow.vLevel > 0 ? vLevelRow.vLevel : MIN_VERTICAL_LEVELS,
    };
  }

  static getSharePictureTreeNodes(stage: SharePictureStage, sharePictureId: string, workTitle: string): TreeData[] {
    if (!stage.claims) {
      return [];
    }
    const rootNode: TreeData = {
      label: sharePictureId || 'root',
      name: workTitle,
      parent: null,
      color: ROOT_COLOR,
    };
    const nodes = stage.claims.map((claim: SharePictureFullClaim) => SharePictureUtils.getClaimNode(claim, claim.parentId || sharePictureId || 'root'));
    return [rootNode, ...nodes];
  }

  static calculateLevelsAndGenerateRows(
    hLevel: number,
    vLevel: number,
    parentId: string,
    claimsByParentId: Map<string, SharePictureFullClaim[]>,
    maxHLevel: number,
    rows: any[],
    translate?: TranslateService,
  ): { hLevel: number; vLevel: number; maxHLevel: number } {
    hLevel++;
    const childrenFromParent = claimsByParentId.get(parentId);
    if (childrenFromParent?.length > 0) {
      if (childrenFromParent.length > 1) {
        vLevel += childrenFromParent.length;
      }
      childrenFromParent.map(child => {
        const row = this.generateRowFromChild(child, hLevel, vLevel, parentId, translate);
        rows.push(row);
        const levels = SharePictureUtils.calculateLevelsAndGenerateRows(hLevel, vLevel, child.id, claimsByParentId, maxHLevel, rows, translate);
        maxHLevel = levels.maxHLevel;
        vLevel = levels.vLevel;
      });
      hLevel--;
    }
    return {
      hLevel,
      vLevel: vLevel > 0 ? vLevel : MIN_VERTICAL_LEVELS,
      maxHLevel: hLevel > maxHLevel ? hLevel : maxHLevel,
    };
  }

  /**
   * Adds the claim to its corresponding parent node
   */
  static addClaimToParentNode(claim: SharePictureFullClaim, claimsByParentId: Map<string, SharePictureFullClaim[]>, parentId: string): void {
    // Compose tree structure to calculate horizontal and vertical levels
    const childrenFromParent = claimsByParentId.get(parentId);
    const currentClaim = claimsByParentId.get(claim.id);
    if (childrenFromParent) {
      childrenFromParent.push(claim);
    } else {
      claimsByParentId.set(parentId, [claim]);
    }
    if (!currentClaim) {
      claimsByParentId.set(claim.id, []);
    }
  }

  static getClaimNode(claim: SharePictureFullClaim, rootNodeId: string): any {
    const name = IpUtils.cleanNameLabel(ClaimsUtils.getClaimantFullNameFromClaim(claim), get(claim, 'claimant.party.attributes.typeOf'));
    const prShare = parseFloat(get(claim, 'prShare.totalShare', '0'));
    const mrShare = parseFloat(get(claim, 'mrShare.totalShare', '0'));

    return {
      label: claim.id,
      parent: claim.parentId || rootNodeId,
      name: SharePictureUtils.truncate(name, TRUNCATE_LENGHT),
      color: NODE_COLORS[claim.role],
      pr: prShare.toString() + '%',
      mr: mrShare.toString() + '%',
    };
  }

  static stringifyRightsTags(tags) {
    if (!tags) {
      return null;
    } else {
      return toPairs(tags)
        .map(pair => {
          const tagType = pair[0];
          switch (tagType) {
            case UNAUTHORIZED: {
              return pair[1][0] === 'true' ? tagType : '';
            }
            default: {
              return `${pair[1]}`;
            }
          }
        })
        .join('\n');
    }
  }

  static generateRowFromChild(child: SharePictureFullClaim, hLevel: number, vLevel: number, rootNodeId: string, translate: TranslateService): any {
    let ipiNameNumberRelation: Object;
    let ipiNameKeyRelation: Object;
    let ipiBaseNumberRelation: Object;
    let ipiBaseKeyRelation: Object;
    const { id, claimantPartyId, claimantPartyNameId, parentId, hasNotResolvedCounterclaim } = child;
    const partyNameRelations = get(child, 'claimant.partyName.relations');
    if (partyNameRelations) {
      ipiNameNumberRelation = partyNameRelations.find(elem => elem.otherId.indexOf('IPI') === 0);
      ipiNameKeyRelation = partyNameRelations.find(elem => elem.otherId.indexOf('ICE') === 0);
    }

    const partyRelations = get(child, 'claimant.party.relations');
    if (partyRelations) {
      ipiBaseNumberRelation = partyRelations.find(elem => elem.otherId.indexOf('IPI') === 0);
      ipiBaseKeyRelation = partyRelations.find(elem => elem.otherId.indexOf('ICE') === 0);
    }

    const prSocietyCode = get(child, 'prShare.usageDateSocietyId', '').replace('CISAC:', '');
    const mrSocietyCode = get(child, 'mrShare.usageDateSocietyId', '').replace('CISAC:', '');

    const prShare = parseFloat(get(child, 'prShare.totalShare', '0'));
    const mrShare = parseFloat(get(child, 'mrShare.totalShare', '0'));

    const prAgreementId = get(child, 'prShare.agreementId', ':').split(':')[1];
    const mrAgreementId = get(child, 'mrShare.agreementId', ':').split(':')[1];
    const isAgreementAdmin = get(child, 'prShare.agreement.attributes.administrator', false) || get(child, 'mrShare.agreement.attributes.administrator', false);

    const roleRaw = get(child, 'role');
    const isSpecial = /special/i.test(roleRaw);
    const roleLabel = ContributorsUtils.getRoleLabelFromRoleValue(roleRaw);
    const prStatus = get(child, 'prShare.status');
    const mrStatus = get(child, 'mrShare.status');
    const status = prStatus || mrStatus || ((!roleRaw || isSpecial) && STATUS_NON_APPLICABLE);

    const statusObject = ClaimsUtils.getClaimStatus([status], translate);

    const prSociety =
      !isSpecial && child.prShare ? `${SocietiesUtils.searchSocietyNameById(StringUtils.padZeros(prSocietyCode, 3))}:${StringUtils.padZeros(prSocietyCode, 3)}` : NOT_AVAILABLE;
    const mrSociety =
      !isSpecial && child.mrShare ? `${SocietiesUtils.searchSocietyNameById(StringUtils.padZeros(mrSocietyCode, 3))}:${StringUtils.padZeros(mrSocietyCode, 3)}` : NOT_AVAILABLE;

    const prRepertoireId = get(child, 'prShare.tags.RepertoireId');
    const prRepertoireKey = String((prShare && get(child, 'prShare.tags.RepertoireKey')) || '');
    let prRepertoireName = (prShare && get(child, 'prShare.tags.RepertoireName')) || '';
    if (prRepertoireName && prRepertoireKey) {
      prRepertoireName = prRepertoireName + '(' + prRepertoireKey.substring(prRepertoireKey.indexOf(':') + 1) + ')';
    }
    const mrRepertoireId = get(child, 'mrShare.tags.RepertoireId');
    const mrRepertoireKey = String((mrShare && get(child, 'mrShare.tags.RepertoireKey')) || '');
    let mrRepertoireName = (mrShare && get(child, 'mrShare.tags.RepertoireName')) || '';
    if (mrRepertoireName && mrRepertoireKey) {
      mrRepertoireName = mrRepertoireName + '(' + mrRepertoireKey.substring(mrRepertoireKey.indexOf(':') + 1) + ')';
    }

    let prInfoTooltip = this.stringifyRightsTags(get(child, 'prShare.tags', null));
    let mrInfoTooltip = this.stringifyRightsTags(get(child, 'mrShare.tags', null));

    const unauthorized = some([prInfoTooltip, mrInfoTooltip], el => includes(el, UNAUTHORIZED));
    const unauthorizedIcon = unauthorized ? CopyrightUtils.getUnauthorizedIconWithTooltip(translate) : undefined;
    prInfoTooltip = replace(prInfoTooltip, UNAUTHORIZED, '');
    mrInfoTooltip = replace(mrInfoTooltip, UNAUTHORIZED, '');
    const alert = unauthorized;
    const counterClaimIcon = hasNotResolvedCounterclaim ? CopyrightUtils.getCounterclaimIconWithTooltip() : undefined;
    const alertIcon = flattenDeep([unauthorizedIcon, counterClaimIcon].filter(item => item !== undefined));

    const mrExcluded = get(child, 'mrShare.excluded', null) === true ? translate.instant('WORKS.SHARE_PICTURE.EXCLUDED') : '';
    const prExcluded = get(child, 'prShare.excluded', null) === true ? translate.instant('WORKS.SHARE_PICTURE.EXCLUDED') : '';
    const typeOf = get(child, 'claimant.party.attributes.typeOf');

    const ipiNameNumber = RelationsUtils.getKeySuffixFromRelation(ipiNameNumberRelation);
    const ipiNameKey = RelationsUtils.getKeySuffixFromRelation(ipiNameKeyRelation);

    return {
      id,
      role: `${'&nbsp;'.repeat(hLevel)}${roleRaw || translate.instant('WORKS.SHARE_PICTURE.SPECIAL')}`,
      roleRaw,
      roleLabel,
      name: ClaimsUtils.getClaimantFullNameFromClaim(child),
      level: hLevel,
      type: child.type,
      vLevel,
      parentId: parentId || rootNodeId,
      partyId: claimantPartyId,
      ipiNameNumber,
      ipiNameNumberTooltip: `${translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_NUMBER_TOOLTIP')}: ${ipiNameNumber}<br>${translate.instant(
        'WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_KEY',
      )}: ${ipiNameKey}`,
      ipiNameKey,
      ipiBaseNumber: RelationsUtils.getKeySuffixFromRelation(ipiBaseNumberRelation),
      ipiBaseKey: RelationsUtils.getKeySuffixFromRelation(ipiBaseKeyRelation),
      prSociety,
      mrSociety,
      pr: `${prShare.toFixed(2)} %`,
      mr: `${mrShare.toFixed(2)} %`,
      prShare,
      mrShare,
      rawMrShare: child.mrShare,
      rawPrShare: child.prShare,
      agreementId: prAgreementId || mrAgreementId,
      agreementBadgeText: isAgreementAdmin ? translate.instant('WORKS.SHARE_PICTURE.AGREEMENT_ADMIN').charAt(0) : '',
      agreementBadgeTextTooltip: isAgreementAdmin ? translate.instant('WORKS.SHARE_PICTURE.AGREEMENT_ADMIN') : '',
      mrInfoTooltip,
      prInfoTooltip,
      mrExcluded,
      prExcluded,
      prRepertoireName,
      mrRepertoireName,
      prRepertoireId,
      mrRepertoireId,
      claimantPartyId,
      claimantPartyNameId,
      originalParentId: parentId,
      rawStatus: status,
      ...statusObject,
      typeOf,
      alert,
      alertIcon,
      rowClass: hasNotResolvedCounterclaim ? 'unresolved-cc-row' : '',
    };
  }

  static truncate(message, length) {
    if (!message) {
      return '';
    } else if (message.length > length) {
      return `${message.substr(0, length)}...`;
    }
    return message;
  }

  static cleanShare(share) {
    return share === 'N/A' ? 0 : parseFloat(share.slice(0, -1));
  }

  static getSharesTotals(ownershipTableItems: SharePicture) {
    const prParser = item => (item.prTooltip && item.prTooltip.length > 0 ? this.cleanShare(item.prTooltip) : item.pr ? this.cleanShare(item.pr) : '-');
    const mrParser = item => (item.mrTooltip && item.mrTooltip.length > 0 ? this.cleanShare(item.mrTooltip) : item.mr ? this.cleanShare(item.mr) : '-');
    return ClaimsUtils.calculateCopyrightOwnershipTotals(ownershipTableItems, prParser, mrParser);
  }

  static getWorkDetailTotals(workDetailTableItems) {
    const prParser = item => (item.prTooltip && item.prTooltip.length > 0 ? parseFloat(item.prTooltip.slice(0, -1)) : item.pr ? parseFloat(item.pr.slice(0, -1)) : '-');
    const mrParser = item => (item.mrTooltip && item.mrTooltip.length > 0 ? parseFloat(item.mrTooltip.slice(0, -1)) : item.mr ? parseFloat(item.mr.slice(0, -1)) : '-');
    return ClaimsUtils.calculateCopyrightOwnershipTotals(workDetailTableItems, prParser, mrParser);
  }

  static getCopyrightOwnershipTreeTableTotals(ownershipTableItems: SharePicture, translate: TranslateService) {
    if (ownershipTableItems) {
      const prParser = item => (item.prShare && item.prShare.totalShare ? parseFloat(item.prShare.totalShare) : 0);
      const mrParser = item => (item.mrShare && item.mrShare.totalShare ? parseFloat(item.mrShare.totalShare) : 0);
      const { totalMR, totalPR, publisherSubTotalPR, publisherSubTotalMR, writerSubTotalMR, writerSubTotalPR, specialSubTotalMR, specialSubTotalPR } =
        ClaimsUtils.calculateCopyrightOwnershipTotals(ownershipTableItems, prParser, mrParser);
      const { writerSubTotalSharesLabel, publisherSubTotalSharesLabel, specialSubTotalSharesLabel, totalSharesLabel } = this.getSharePictureFooterDatatableLabels(translate);
      return [
        {
          role: '',
          name: '',
          ipiNameNumber: '',
          ipiNameKey: '',
          ipiBaseNumber: '',
          ipiBaseKey: '',
          prSociety: '',
          mrSociety: writerSubTotalSharesLabel,
          pr: `${writerSubTotalPR}%`,
          mr: `${writerSubTotalMR}%`,
          prRepertoireName: '',
          mrRepertoireName: '',
        },
        {
          role: '',
          name: '',
          ipiNameNumber: '',
          ipiNameKey: '',
          ipiBaseNumber: '',
          ipiBaseKey: '',
          prSociety: '',
          mrSociety: publisherSubTotalSharesLabel,
          pr: `${publisherSubTotalPR}%`,
          mr: `${publisherSubTotalMR}%`,
          prRepertoireName: '',
          mrRepertoireName: '',
        },
        {
          role: '',
          name: '',
          ipiNameNumber: '',
          ipiNameKey: '',
          ipiBaseNumber: '',
          ipiBaseKey: '',
          prSociety: '',
          mrSociety: specialSubTotalSharesLabel,
          pr: `${specialSubTotalPR}%`,
          mr: `${specialSubTotalMR}%`,
          prRepertoireName: '',
          mrRepertoireName: '',
        },
        {
          role: '',
          name: '',
          ipiNameNumber: '',
          ipiNameKey: '',
          ipiBaseNumber: '',
          ipiBaseKey: '',
          prSociety: '',
          mrSociety: totalSharesLabel,
          pr: `${totalPR}%`,
          mr: `${totalMR}%`,
          prRepertoireName: '',
          mrRepertoireName: '',
        },
      ];
    }
    return [];
  }

  static getSharePictureFooterDatatableLabels(translate) {
    return {
      writerSubTotalSharesLabel: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_CREATOR_SUB_TOTAL'),
      publisherSubTotalSharesLabel: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_PUBLISHER_SUB_TOTAL'),
      specialSubTotalSharesLabel: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_SPECIAL_SUB_TOTAL'),
      totalSharesLabel: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_TOTAL'),
    };
  }

  static getManuscriptData(contributions, translate?): CopyrightOwnershipTableItem[] {
    const tableItems = [];
    if (contributions) {
      return contributions.map(contribution => {
        let prSocietyTemp: string;
        let prSociety: string;
        let prSocietyList: string;
        let prSocietyIsIce: boolean;
        let prSocietyIcons: IconInterface[];
        let mrSocietyTemp: string;
        let mrSociety: string;
        let mrSocietyList: string;
        let mrSocietyIsIce: boolean;
        let mrSocietyIcons: IconInterface[];
        let societyCodes: string;
        const partyName = get(contribution, 'contributor.partyName');
        const parties = get(partyName, 'parties');
        const typeOf = (parties && flatten(parties.map(party => get(party, 'party.attributes.typeOf')))[0]) || [];
        const contributorSocieties = (parties && flatten(parties.map(party => get(party, 'party.societies')))) || [];

        const prSocietyObject = SocietiesUtils.getPRorMRSocietyFromContributor(contributorSocieties, 'PR');
        if (prSocietyObject) {
          prSocietyTemp = (!prSocietyObject.multipleSocieties && prSocietyObject.societyCode) || '';
          prSociety = prSocietyTemp !== '' ? `${SocietiesUtils.searchSocietyNameById(prSocietyObject.societyCode)}:${prSocietyTemp}` : '';
          societyCodes = prSocietyObject.societyCodes;
          const prSocieties = (prSocietyObject.multipleSocieties && prSocietyObject.societyCodes) || '';
          prSocietyList = (prSocietyObject.multipleSocieties && prSocieties) || prSocietyTemp;
          prSocietyIcons = prSocietyObject.multipleSocieties ? SocietiesUtils.generateIsICESocietyIcons({ isICE: prSocietyObject.hasICESociety, societies: prSocieties }) : [];
          prSocietyIsIce = prSocietyObject.hasICESociety;
        }

        const mrSocietyObject = SocietiesUtils.getPRorMRSocietyFromContributor(contributorSocieties, 'MR');
        if (mrSocietyObject) {
          mrSocietyTemp = (!mrSocietyObject.multipleSocieties && mrSocietyObject.societyCode) || '';
          mrSociety = mrSocietyTemp !== '' ? `${SocietiesUtils.searchSocietyNameById(mrSocietyObject.societyCode)}:${mrSocietyTemp}` : '';
          mrSocietyIsIce = mrSocietyObject.hasICESociety;
          const mrSocieties = (mrSocietyObject.multipleSocieties && mrSocietyObject.societyCodes) || '';
          mrSocietyList = (mrSocietyObject.multipleSocieties && mrSocieties) || mrSocietyTemp;
          mrSocietyIcons = mrSocietyObject.multipleSocieties ? SocietiesUtils.generateIsICESocietyIcons({ isICE: mrSocietyObject.hasICESociety, societies: mrSocieties }) : [];
        }

        const name = (partyName && partyName.attributes && CopyrightUtils.extractPartyNameFirstname(partyName.attributes)) || '';
        const mrShare = this.getManuscriptShare(contribution.manuscriptShares, 'MR');
        const prShare = this.getManuscriptShare(contribution.manuscriptShares, 'PR');

        const partyNameRelations = ContributorsUtils.getContributionPartyNameRelations(contribution);
        const ref = IpUtils.selectIPINumber(partyNameRelations);
        const ipiNameNumber = ref.split(':')[1];
        const ipiNameKey = IpUtils.selectIpsKey(partyNameRelations, get(partyName, 'id', '')).split(':')[1];

        const partyRelations = ContributorsUtils.getContributionPartyRelations(contribution);
        const ipiBaseNumber = IpUtils.selectIPINumber(partyRelations).split(':')[1];
        const ipiBaseKey = IpUtils.selectIpsKey(partyRelations, get(partyName, 'id', '')).split(':')[1];

        const key = IpUtils.selectIpsKey(partyNameRelations, get(partyName, 'id', ''));
        const partyNameId = get(partyName, 'id', '');
        const ipiNameKeyRelation = partyNameRelations.find(elem => elem.otherId.indexOf('ICE') === 0);
        const ipiNameNumberTooltip =
          (translate &&
            `${translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_NUMBER_TOOLTIP')}: ${ipiNameNumber}<br>
            ${translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_KEY')}: ${
              (ipiNameKeyRelation && RelationsUtils.getKeySuffixFromRelation(ipiNameKeyRelation)) || ''
            }`) ||
          '';
        const roleLabel = ContributorsUtils.getRoleLabelFromRoleValue(contribution.role);

        return {
          contributorId: contribution.contributorId,
          role: contribution.role,
          name,
          ref,
          key,
          refLabel: ipiNameNumber,
          ip: contribution.workId,
          mrSociety,
          mrSocietyList,
          mrSocietyIsIce,
          mrSocietyIcons,
          mrSocietyCode: mrSocietyTemp,
          prSociety,
          prSocietyList,
          prSocietyIsIce,
          prSocietyIcons,
          prSocietyCode: prSocietyTemp,
          societyCodes,
          pr: prShare.share,
          mr: mrShare.share,
          prTooltip: prShare.tooltip,
          mrTooltip: mrShare.tooltip,
          ipiNameNumber,
          ipiNameKey,
          ipiBaseNumber,
          ipiBaseKey,
          partyNameId,
          copyrightOverrides: cloneDeep(contribution.copyrightOverrides),
          manuscripShares: cloneDeep(contribution.manuscriptShares) || [],
          typeOf,
          ipiNameNumberTooltip,
          roleLabel,
        };
      });
    }
    return tableItems;
  }

  static formatSociety(society) {
    return (society && `${society}:${SocietiesUtils.searchSocietyNameById(society.trim())}`) || '';
  }

  static getManuscriptShare(shares, type): { share: string; tooltip: string } {
    const share = find(shares, item => find(item.rightTypes, rightType => rightType === type));
    if (share && share.share !== undefined) {
      const share2Decimals = getPercent2Decimals(share.share);
      const sharePercent = share.share / 100;
      return { share: `${share2Decimals} %`, tooltip: share2Decimals !== sharePercent ? `${sharePercent} %` : '' };
    }
    return { share: '', tooltip: '' };
  }

  static getSharesFilterDefaults(filter, workId): any {
    const mrSociety = filter.mrSociety ? filter.mrSociety.value : DEFAULT_DISTRIBUTION_SOCIETY_MR;
    const prSociety = filter.prSociety ? filter.prSociety.value : DEFAULT_DISTRIBUTION_SOCIETY_PR;
    const territory = filter.territory ? filter.territory.value : DEFAULT_FILTER_TERRITORY;
    const usageDate = DateTimeUtils.formatDate(filter.usageDate._d);
    const distributionDate = DateTimeUtils.formatDate(filter.distributionDate._d);

    const requestSubmit: PayloadSharePicture = {
      ns: 'CUBE',
      workId,
      country: territory,
      usageDate,
      distributionDate,
      mrRightType: filter.mrRightType,
      prRightType: filter.prRightType,
      usageFactorMR: filter.usageFactorMR,
      usageFactorPR: filter.usageFactorPR,
      mrDistributionSocietyId: `CISAC:${mrSociety}`,
      prDistributionSocietyId: `CISAC:${prSociety}`,
      repertoire: filter.repertoire,
      timeout: 25,
    };
    return requestSubmit;
  }

  static formatSocietyCode(society: string) {
    const societyCode = last((society || '').split(':'));
    return societyCode.length < 3 ? `CISAC:${padStart(societyCode, 3, '0')}` : society;
  }

  static getSharePictureTriggeredRulesFormatted(triggeredRules: Observable<any>): Observable<any> {
    return triggeredRules.pipe(
      map(data => {
        const triggeredRulesFormatted: any = {};
        const preTriggeredRulesFormatted = pickBy(data, (value, attribute) => !['MAIN', 'claim_enhancement'].includes(attribute) && !attribute.includes('marshal'));
        Object.keys(preTriggeredRulesFormatted)
          .sort((a, b) => {
            const valueA = orderList.indexOf(a) === -1 ? Number.MAX_VALUE : orderList.indexOf(a);
            const valueB = orderList.indexOf(b) === -1 ? Number.MAX_VALUE : orderList.indexOf(b);
            return valueA - valueB;
          })
          .forEach((key: string) => {
            triggeredRulesFormatted[key] = preTriggeredRulesFormatted[key];
          });
        if (size(triggeredRulesFormatted) === 0) {
          return {
            info: 'No Audit Information was found at the moment',
          };
        }
        return triggeredRulesFormatted;
      }),
    );
  }

  static mergeSpecialRows(rows: any[]): any[] {
    const noSpecialRows = rows.filter(row => !!row.roleRaw);
    const specialRows = rows.filter(row => !row.roleRaw) || [];
    let cleanSpecialRows;
    if (specialRows.length > 1) {
      cleanSpecialRows = (specialRows || []).reduce((acc, next) => {
        return {
          ...acc,
          pr: `${(acc.prShare + next.prShare).toFixed(2)} %`,
          prSociety: acc.prSociety === 'N/A' ? acc.prSociety : next.prSociety,
          mr: `${(acc.mrShare + next.mrShare).toFixed(2)} %`,
          mrSociety: acc.mrSociety === 'N/A' ? acc.mrSociety : next.mrSociety,
          prInfoTooltip: `${acc.prInfoTooltip ? acc.prInfoTooltip : ''}${next.prInfoTooltip ? next.prInfoTooltip : ''}`,
          mrInfoTooltip: `${acc.mrInfoTooltip ? acc.mrInfoTooltip : ''}${next.mrInfoTooltip ? next.mrInfoTooltip : ''}`,
        };
      });
    } else {
      cleanSpecialRows = specialRows[0];
    }
    if (!!cleanSpecialRows) {
      return [...noSpecialRows, cleanSpecialRows];
    } else {
      return rows;
    }
  }
}
