import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, IceTemplate } from '@ice/components/data-table/data-table';
import { SocietiesUtils } from '@ice/utils/societies/societies.utils';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { CREATORS } from 'config/constants/global.constants';
import { mrRights, prRights } from 'config/constants/ips.constants';
import { map } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import * as fromRoot from 'store/root';
import { groupBy, size } from 'lodash';
import { RootDatatable } from './root-datatable';

export class CopyrightWorkClaimsDataTable extends RootDatatable {
  constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<any>,
    private permissionsService: PermissionsService,
  ) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }
  private readonly canEdit$ = this.store.select(fromRoot.getEditMode).pipe(map(editMode => this.permissionsService.can('works_edit_claim') && editMode));
  focusNextElement({ target }: { target: HTMLElement }) {
    const elementsWithTabindex: HTMLElement[] = Array.from(document.querySelectorAll('[tabindex]'));

    const elementsByTabIndex = groupBy(elementsWithTabindex, 'tabIndex');
    // tabindex could be any number, independently of how many groups we have
    const maxIndex = Math.max(...Object.keys(elementsByTabIndex).map(Number));
    const targetIndex = target.tabIndex;
    let group;
    let index = target.tabIndex;

    while (index <= maxIndex) {
      group = elementsByTabIndex[index];
      group?.sort((a, b) => a.tabIndex - b.tabIndex);
      // try to find an element with same tabindex
      if (targetIndex === index) {
        const currentIndex = group.findIndex(element => target === element);
        if (currentIndex < size(group) - 1) {
          group[currentIndex + 1].focus();
          return;
        }
      } else if (group) {
        // if not, focus the first element of next available tabindex
        group?.[0]?.focus();
        return;
      }
      index = index + 1;
    }
    target.blur();
  }

  getDataTable(onEditStatus?, openEditDialog?, removeClaimsAndChildren?): DataTableRow[] {
    const hideColumnFn = () => this.canEdit$.pipe(map(canEdit => !canEdit));
    return [
      {
        name: this.translate.instant('CLAIMS.ROLE'),
        prop: 'role',
        flexGrow: 1,
        tooltip: 'roleLabel',
        errorMessageField: 'errorMessage',
        cellClass: 'cursor-pointer ip-detail-editable-ipi',
        width: 40,
        hideEditIcon: true,
        editable: row => this.store.select(fromRoot.getEditMode).pipe(map(editMode => editMode && this.permissionsService.can('works_edit_claim'))),
        onEdit: (newValue: any) => {
          if (CREATORS.includes(newValue.roleRaw.toUpperCase()) && CREATORS.includes(newValue.role.toUpperCase())) {
            this.store.dispatch(
              new fromRoot.UpdateField({
                object: 'worksIpRoleClaimsInline',
                newValue,
                type: 'edit',
              }),
            );
          }
        },
      },
      {
        name: this.translate.instant('CLAIMS.NAME'),
        prop: 'name',
        flexGrow: 2,
        cellClass: 'name-cell',
      },
      {
        name: this.translate.instant('CLAIMS.IPI_NAME_NUMBER'),
        prop: 'ipiNumber',
        flexGrow: 2,
        headerTooltip: this.translate.instant('CLAIMS.IPI_NAME_NUMBER_TOOLTIP'),
      },
      {
        name: this.translate.instant('CLAIMS.PR_SOC'),
        prop: 'prSoc',
        flexGrow: 1,
        tooltip: 'prSocTooltip',
        headerTooltip: this.translate.instant('CLAIMS.PR_SOC_TOOLTIP'),
        iconRightOfText: { icon: 'prSocIcon' },
      },
      {
        name: this.translate.instant('CLAIMS.MR_SOC'),
        prop: 'mrSoc',
        flexGrow: 1,
        tooltip: 'mrSocTooltip',
        headerTooltip: this.translate.instant('CLAIMS.MR_SOC_TOOLTIP'),
        iconRightOfText: { icon: 'mrSocIcon' },
      },
      {
        name: this.translate.instant('CLAIMS.PRIOR_ROYALTY_DATE'),
        prop: 'priorDate',
        flexGrow: 1.5,
        headerTooltip: this.translate.instant('CLAIMS.PRIOR_ROYALTY_DATE_TOOLTIP'),
      },
      {
        name: this.translate.instant('CLAIMS.START_DATE'),
        prop: 'startDate',
        flexGrow: 1.5,
      },
      {
        name: this.translate.instant('CLAIMS.END_DATE'),
        prop: 'endDate',
        flexGrow: 1.5,
      },
      {
        name: this.translate.instant('CLAIMS.POST_TERM_COLLECTION_DATE'),
        prop: 'postTermCollectionDate',
        flexGrow: 1.5,
        headerTooltip: this.translate.instant('CLAIMS.POST_TERM_COLLECTION_DATE_TOOLTIP'),
      },
      {
        name: this.translate.instant('CLAIMS.TERRITORY'),
        prop: 'territoriesTisa',
        flexGrow: 2,
        tooltip: 'territoriesTooltip',
        tooltipDuration: 250,
      },
      {
        name: this.translate.instant('CLAIMS.RIGHTS_PR_MR'),
        prop: 'rights',
        flexGrow: 2,
      },
      {
        name: this.translate.instant('CLAIMS.PR_SHARES'),
        prop: 'prShares',
        errorMessageField: 'errorMessage',
        cellClass: 'align-right-table-cell pr-shares-cell',
        headerClass: 'align-right-table-cell',
        canAutoResize: false,
        resizeable: false,
        width: 90,
        hideEditIcon: true,
        iceTemplate: IceTemplate.PERCENTAGE,
        disableInput: row => this.canEdit$.pipe(map(canEdit => !(canEdit && SocietiesUtils.checkClaimHasRights(row.rightTypes, prRights)))),
        onKeydown: (row, event) => {
          if (event.code === 'Tab') {
            event.stopPropagation();
            event.preventDefault();
            const target = event.target as HTMLElement;
            this.focusNextElement({ target });
          }
        },
        onBlur: (newValue: any) => {
          this.store.dispatch(
            new fromRoot.UpdateField({
              object: 'workPrClaimsInline',
              newValue,
              type: 'edit',
            }),
          );
        },
      },
      {
        name: this.translate.instant('CLAIMS.MR_SHARES'),
        prop: 'mrShares',
        errorMessageField: 'errorMessage',
        cellClass: 'align-right-table-cell mr-shares-cell',
        headerClass: 'align-right-table-cell',
        canAutoResize: false,
        resizeable: false,
        width: 90,
        hideEditIcon: true,
        iceTemplate: IceTemplate.PERCENTAGE,
        disableInput: row => this.canEdit$.pipe(map(canEdit => !(canEdit && SocietiesUtils.checkClaimHasRights(row.rightTypes, mrRights)))),
        onKeydown: (row, event) => {
          if (event.code === 'Tab') {
            event.stopPropagation();
            event.preventDefault();
            const target = event.target as HTMLElement;
            this.focusNextElement({ target });
          }
        },
        onBlur: newValue => {
          this.store.dispatch(
            new fromRoot.UpdateField({
              object: 'workMrClaimsInline',
              newValue,
              type: 'edit',
            }),
          );
        },
      },
      {
        cellClass: 'ice-warning',
        name: this.translate.instant('CLAIMS.ALERT'),
        prop: 'alert',
        canAutoResize: false,
        resizeable: false,
        hideTextProperty: true,
        icons: 'alertIcons',
        width: 90,
        flexGrow: 1,
        headerTooltip: ' ',
      },
      {
        name: '',
        prop: 'editBtn',
        actionButtonIcon: 'edit',
        resizeable: false,
        flexGrow: 0.001,
        maxWidth: 40,
        minWidth: 40,
        action: field => {
          openEditDialog(field.claimId);
        },
        hideActionButton: hideColumnFn,
      },
      {
        cellClass: 'remove',
        name: '',
        prop: 'removeBtn',
        actionButtonIcon: 'cancel',
        flexGrow: 0.001,
        maxWidth: 40,
        minWidth: 40,
        resizeable: false,
        tooltip: this.translate.instant('CLAIMS.REMOVE_CLAIM'),
        action: field => removeClaimsAndChildren(field.claimId),
        hideActionButton: hideColumnFn,
      },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }
}
