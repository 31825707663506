import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import * as fromApiCalls from 'config/api-calls';
import { RELATIONTYPES } from 'config/constants/relation.constants';
import { RootDatatable } from 'config/data-table-builders/root-datatable';
import { DialogAddMultipleAgreements } from 'config/dialog-builders/dialog-add-multiple-agreements';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { SectionsConfig } from 'config/sections-config';
import { AgreementRelation, AgreementRelationType } from 'models/copyright/detail/agreement-relation';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Agreement } from '@ice';
import { SortInfo } from '@ice/components/data-table/data-table';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { SectionTabConfig } from '../tabs-data';

const { GROUP, RECIPIENT } = RELATIONTYPES;

export class TabAgreementGroupDetail extends RootDatatable implements SectionTabConfig {
  private dialogAddMultipleAgreements: DialogAddMultipleAgreements;
  private dialogRef: MatDialogRef<any, any>;

  // Permissions
  private canAddOrRemoveAgreement: boolean;

  constructor(
    protected translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: null,
    private detailService: null,
    private nsService: null,
    private iceFacade: null,
    private fieldValidatorService: FieldValidatorService,
    protected permissionsService: PermissionsService,
  ) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
    this.canAddOrRemoveAgreement = this.permissionsService.can(`agreement-group_edit`);
    this.dialogAddMultipleAgreements = new DialogAddMultipleAgreements(this.translate, dialog, store, fieldValidatorService);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'assignor.name', dir: 'asc' }];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'assignor.name':
        return { prop: `assignor.partyName.attributes.name`, dir: sort.dir };
      case 'assignee.name':
        return { prop: `assignee.partyName.attributes.name`, dir: sort.dir };
      case 'admin':
        return { prop: `attributes.administrator`, dir: sort.dir };
      case 'key':
      case 'territoriesText':
      case 'pr':
      case 'mr':
    }
    return { prop: `attributes.${sort.prop}`, dir: sort.dir };
  }

  getConf(): IceGroupComponent[] {
    const cardWithDataTableHeight = '380px';
    return [
      {
        group: [
          {
            type: 'cardWithForm',
            config: {
              class: 'card-agreements-details-group',
              title: this.getTranslate('DETAILS', 'TITLE'),
              model: this.store.pipe(select(fromRoot.getCopyrightAgreementGroup)),
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getDetailsFormly(),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.getTranslate('INCLUDED_AGREEMENTS', 'TAB_TITLE'),
              class: 'card-included-agreements',
              model: this.store.pipe(select(fromRoot.getIncludedAgreements)),
              columnMode: 'flex',
              loadingIndicator: true,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              schema: this.getIncludedAgreementsSchema(),
              onSelect: (event: any[]) => {
                this.store.dispatch(
                  new fromRoot.Go({
                    path: [`copyright/${SectionsConfig.AGREEMENTS.name}/${event[0].key}`],
                  }),
                );
              },
              onMouseSelect: event => {
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`copyright/${SectionsConfig.AGREEMENTS.name}/${event.key}`],
                  }),
                );
              },
              tableWidth: '100',
              height: cardWithDataTableHeight,
              actionButtons: of(
                this.canAddOrRemoveAgreement
                  ? [
                      {
                        icon: 'add',
                        tooltip: this.getTranslate('INCLUDED_AGREEMENTS', 'ADD_AGREEMENT'),
                        class: 'mat-white-icon',
                        onClick: () => this.onClickAddIncludedAgreements(),
                      },
                    ]
                  : [],
              ),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.getTranslate('RELATED_AGREEMENTS', 'TAB_TITLE'),
              class: 'card-related-agreements',
              model: this.store.pipe(select(fromRoot.getRelatedAgreements)),
              columnMode: 'flex',
              loadingIndicator: true,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              schema: this.getRelatedAgreementsSchema(),
              onSelect: (event: any[]) => {
                this.store.dispatch(
                  new fromRoot.Go({
                    path: [`copyright/${SectionsConfig.AGREEMENTS.name}/${event[0].key}`],
                  }),
                );
              },
              onMouseSelect: event => {
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`copyright/${SectionsConfig.AGREEMENTS.name}/${event.key}`],
                  }),
                );
              },
              tableWidth: '100',
              height: cardWithDataTableHeight,
              actionButtons: of(
                this.canAddOrRemoveAgreement
                  ? [
                      {
                        icon: 'add',
                        tooltip: this.getTranslate('RELATED_AGREEMENTS', 'ADD_AGREEMENT'),
                        class: 'mat-white-icon',
                        onClick: () => this.onClickAddRelatedAgreements(),
                      },
                    ]
                  : [],
              ),
            },
          },
        ],
      },
    ];
  }

  private getDetailsFormly(): any[] {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'attributes.groupId',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate('DETAILS', 'GROUP_ID'), required: false, disabled: true },
          },
          {
            className: 'flex-1',
            key: 'attributes.owner',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate('DETAILS', 'OWNER'), required: false, disabled: true },
          },
          {
            className: 'flex-1',
            key: 'attributes.description',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate('DETAILS', 'DESCRIPTION'), required: false, disabled: true },
          },
        ],
      },
    ];
  }

  private getIncludedAgreementsSchema(): any[] {
    return [
      {
        name: this.getTranslate('INCLUDED_AGREEMENTS', 'ASSIGNOR'),
        prop: 'assignor.name',
        icons: 'assignor.icons',
        class: 'ice-txt-size-14 agreements-assignor-name',
        flexGrow: 1.5,
        comparator: () => this.serverSideSorting(),
      },
      {
        name: this.getTranslate('INCLUDED_AGREEMENTS', 'ASSIGNEE'),
        prop: 'assignee.name',
        class: 'ice-txt-size-14 agreements-assignee-name',
        flexGrow: 1.5,
        comparator: () => this.serverSideSorting(),
      },
      {
        name: this.getTranslate('INCLUDED_AGREEMENTS', 'ADMIN'),
        prop: 'admin',
        icons: 'administratorIcon',
        class: 'ice-txt-size-14 agreements-admin',
        flexGrow: 0.75,
        comparator: (valueA, valueB, modelA, modelB) => this.adminComparator(modelA, modelB),
      },
      {
        name: this.getTranslate('INCLUDED_AGREEMENTS', 'AGR_NUMBER'),
        prop: 'key',
        flexGrow: 1,
      },
      {
        name: this.getTranslate('INCLUDED_AGREEMENTS', 'TYPE'),
        prop: 'agreementType',
        flexGrow: 0.75,
      },
      {
        name: this.getTranslate('INCLUDED_AGREEMENTS', 'TERRITORIES'),
        prop: 'territoriesText',
        icons: 'territoriesIcon',
        tooltip: 'territoriesTooltip',
        tooltipDuration: 250,
        class: 'ice-txt-size-14 agreements-territories',
        flexGrow: 1,
      },
      {
        name: this.getTranslate('INCLUDED_AGREEMENTS', 'PR_IN_OUT'),
        prop: 'pr',
        flexGrow: 0.75,
      },
      {
        name: this.getTranslate('INCLUDED_AGREEMENTS', 'MR_IN_OUT'),
        prop: 'mr',
        flexGrow: 0.75,
      },
      {
        name: this.getTranslate('INCLUDED_AGREEMENTS', 'TERMINATION_DATE'),
        prop: 'endDate',
        flexGrow: 0.75,
      },
      {
        name: this.getTranslate('INCLUDED_AGREEMENTS', 'POSTTERM_COLLECTION_DATE'),
        prop: 'postTermCollectionDate',
        flexGrow: 0.75,
      },
      {
        actionButtonIcon: 'delete',
        tooltip: this.getTranslate('INCLUDED_AGREEMENTS', 'REMOVE_AGREEMENT'),
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        hideActionButton: () => of(!this.canAddOrRemoveAgreement),
        action: row => this.onClickDeleteAgreement(row),
      },
    ];
  }

  private getRelatedAgreementsSchema(): any[] {
    return [
      {
        name: this.getTranslate('RELATED_AGREEMENTS', 'ASSIGNOR'),
        prop: 'assignor.name',
        icons: 'assignor.icons',
        class: 'ice-txt-size-14 agreements-assignor-name',
        flexGrow: 1.5,
        comparator: () => this.serverSideSorting(),
      },
      {
        name: this.getTranslate('RELATED_AGREEMENTS', 'ASSIGNEE'),
        prop: 'assignee.name',
        class: 'ice-txt-size-14 agreements-assignee-name',
        flexGrow: 1.5,
        comparator: () => this.serverSideSorting(),
      },
      {
        name: this.getTranslate('RELATED_AGREEMENTS', 'ADMIN'),
        prop: 'admin',
        icons: 'administratorIcon',
        class: 'ice-txt-size-14 agreements-admin',
        flexGrow: 0.75,
        comparator: (valueA, valueB, modelA, modelB) => this.adminComparator(modelA, modelB),
      },
      {
        name: this.getTranslate('RELATED_AGREEMENTS', 'AGR_NUMBER'),
        prop: 'key',
        flexGrow: 1,
      },
      {
        name: this.getTranslate('RELATED_AGREEMENTS', 'TYPE'),
        prop: 'agreementType',
        flexGrow: 0.75,
      },
      {
        name: this.getTranslate('RELATED_AGREEMENTS', 'TERRITORIES'),
        prop: 'territoriesText',
        icons: 'territoriesIcon',
        tooltip: 'territoriesTooltip',
        tooltipDuration: 250,
        class: 'ice-txt-size-14 agreements-territories',
        flexGrow: 1,
      },
      {
        name: this.getTranslate('RELATED_AGREEMENTS', 'PR_IN_OUT'),
        prop: 'pr',
        flexGrow: 0.75,
      },
      {
        name: this.getTranslate('RELATED_AGREEMENTS', 'MR_IN_OUT'),
        prop: 'mr',
        flexGrow: 0.75,
      },
      {
        name: this.getTranslate('RELATED_AGREEMENTS', 'TERMINATION_DATE'),
        prop: 'endDate',
        flexGrow: 0.75,
      },
      {
        name: this.getTranslate('RELATED_AGREEMENTS', 'POSTTERM_COLLECTION_DATE'),
        prop: 'postTermCollectionDate',
        flexGrow: 0.75,
      },
      {
        actionButtonIcon: 'delete',
        tooltip: this.getTranslate('RELATED_AGREEMENTS', 'REMOVE_AGREEMENT'),
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        hideActionButton: () => of(!this.canAddOrRemoveAgreement),
        action: row => this.onClickDeleteAgreement(row),
      },
    ];
  }

  adminComparator(modelA, modelB) {
    const valueA: number = modelA[`administrator`] || 0;
    const valueB: number = modelB[`administrator`] || 0;
    return valueA > valueB ? 1 : -1;
  }

  private getTranslate(basePath: string, fieldTranslatePath: string): string {
    return this.translate.instant(`AGREEMENT_GROUP.${basePath}.${fieldTranslatePath}`);
  }

  private openDialogForAddAgreements = (relationType: AgreementRelationType) => {
    this.dialogRef = this.dialogAddMultipleAgreements.openDialog((newAgreementIds: string[]) => {
      this.addAgreements(newAgreementIds, relationType);
    });
  };

  private onClickAddIncludedAgreements = () => this.openDialogForAddAgreements(GROUP);

  private onClickAddRelatedAgreements = () => this.openDialogForAddAgreements(RECIPIENT);

  private onClickDeleteAgreement(agreement: Agreement) {
    const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
    const dialogRefInfo = dialogInfo.openDialog(
      this.translate.instant('AGREEMENT_GROUP.DIALOG.DELETE_TITLE'),
      this.translate.instant('AGREEMENT_GROUP.DIALOG.DELETE_MESSAGE'),
      () => {
        this.deleteAgreement(agreement?.key);
        this.dialog.closeAll();
      },
      () => dialogRefInfo.close(),
    );
  }

  private addAgreements = (newAgreementIds: string[], relationType: AgreementRelationType) => {
    this.fetchGroupId().subscribe(groupId => {
      if (!groupId) {
        console.error('Group ID is missing. Cannot proceed with create API call.');
        return;
      }

      const agreements = newAgreementIds.map(id => ({
        agreementId: id,
        relation: relationType,
      }));

      const body = JSON.stringify({ agreements });
      this.store.dispatch(
        new fromRoot.StartApiCall({
          apiCall: fromApiCalls.addToAgreementGroup,
          apiCallData: { labels: { ns: 'CUBE', id: groupId }, body },
          callBack: (response, error) => {
            const dialogMessageKey = this.getDialogMessageKey(error);
            this.dialogAddMultipleAgreements.openInfoDialog(this.translate.instant(dialogMessageKey));
          },
        }),
      );
    });
  };

  private deleteAgreement = (agreementIdToDelete: string) => {
    this.fetchGroupId().subscribe(groupId => {
      if (!groupId) {
        console.error('Group ID is missing. Cannot proceed with delete API call.');
        return;
      }
      this.store.dispatch(
        new fromRoot.StartApiCall({
          apiCall: fromApiCalls.deleteFromAgreementGroup,
          apiCallData: { labels: { ns: 'CUBE', id: groupId, agreementId: agreementIdToDelete } },
          callBack: (response, error) => {
            const dialogMessageKey = this.getDialogMessageKey(error);
            this.dialogAddMultipleAgreements.openInfoDialog(this.translate.instant(dialogMessageKey));
          },
        }),
      );
    });
  };

  private getDialogMessageKey(error: any): string {
    if (!error) {
      return 'AGREEMENT_GROUP.DIALOG.SUCCESS_MESSAGE';
    }

    if (error?.status === 500 && error?.error?.message?.toLowerCase().includes('timed out')) {
      return 'AGREEMENT_GROUP.DIALOG.TIMEOUT_ERROR';
    }

    return 'AGREEMENT_GROUP.DIALOG.ERROR_MESSAGE';
  }

  private fetchGroupId(): Observable<string | null> {
    return this.store.pipe(
      select(fromRoot.getCopyrightAgreementGroup),
      take(1),
      map(groupData => groupData?.id || null),
    );
  }
}
