import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CounterClaimUtils } from '@ice';
import { SortInfo } from '@ice/components/data-table/data-table';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/home-builders';
import { getCounterclaimActionsForDashboard } from 'config/api-calls/counter-claims/counter-claims.api-calls';
import { CountersGroups, CountersQueryPublishersPattern, DashboardCountersTypes } from 'config/constants/counter-claims.constants';
import { OrganizationType } from 'config/constants/organizations.constants';
import { MAX_SEARCH_SIZE } from 'config/constants/search.constants';
import { ConflictsActionsDataTable } from 'config/data-table-builders/conflicts.actions';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import { SearchConflictsCounterClaimsActionsForm } from 'config/search-form-builders/search-conflicts-actions';
import { SectionHomeConfig, SectionsConfig } from 'config/sections-config';
import { of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';

const HEIGHT_TOP_CARDS = 170;
const HEIGHT_CONTENT_TOP_CARDS = 225;

export class DashboardConflictsCounterClaims implements SectionHomeConfig {
  queryPattern: string;
  searchCasesCounterClaimsActionsForm: SearchConflictsCounterClaimsActionsForm;
  selectionDatatable: SelectionDatatableBuilder;
  casesActionsDataTable: ConflictsActionsDataTable;
  constructor(
    private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private storeNewItem: Store<fromForm.NewSectionItemState>,
    private permissionsService: PermissionsService,
  ) {
    this.translationLoader.loadTranslations(english);
    this.searchCasesCounterClaimsActionsForm = new SearchConflictsCounterClaimsActionsForm(this.translate, this.translationLoader);
    this.casesActionsDataTable = new ConflictsActionsDataTable(this.translate, this.translationLoader, store, storeNewItem, permissionsService);

    this.store
      .pipe(
        select(fromRoot.getUserCurrentOrganization),
        filter(organization => !!organization),
        take(1),
      )
      .subscribe(organization => {
        const isPublisher = organization.type === OrganizationType.publisher;
        const accessPartyNames = organization.accessPartyNames || [];

        this.queryPattern = isPublisher
          ? CountersQueryPublishersPattern
          : accessPartyNames.map(partyName => `{"equals":{"counterclaim.participants.claimant.relations[XREF].otherId":"${partyName}"}}`).join(',');
      });

    this.initialSearch();

    this.selectionDatatable = new SelectionDatatableBuilder(
      this.store,
      [...this.casesActionsDataTable.getDataTable(), ...this.casesActionsDataTable.getDataTableAdditionalFields()],
      null,
      getCounterclaimActionsForDashboard,
      of([
        'workId',
        'workTitle',
        'counterclaimIdFormatted',
        'createdDate',
        'resolutionOwner',
        'type',
        'status',
        'territoriesLabel',
        'actionType',
        'actionStatus',
        'actionOwner',
        'actionResponse',
        'actionDeadline',
      ]),
      MAX_SEARCH_SIZE,
      this.store.select(fromRoot.getCounterClaimDashboardSearch).pipe(map(search => search.result?.items || [])),
    );
  }
  getInitialModel(): Object {
    throw new Error('Method not implemented.');
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithExpansionList',
            flex: 32,
            config: {
              class: 'flex-1 other-information',
              type: 'labeledList',
              title: this.translate.instant('COUNTER_CLAIMS.ACTION_ON_ME.TITLE'),
              model: this.store.pipe(
                select(fromRoot.getCopyrightDashboard),
                map(dashboard => CounterClaimUtils.getDashboardCountersType(dashboard, CountersGroups.ACTION_ON_ME, this.translate)),
              ),
              minHeight: HEIGHT_TOP_CARDS,
              maxHeight: HEIGHT_TOP_CARDS,
              customContentHeight: HEIGHT_CONTENT_TOP_CARDS,
              expanded: of(true),
              filterItemsOn: 10,
              selectable: of(true),
              onSelect: row => this.countersTypeSearch(row),
              showCounter: true,
              counterSumValues: true,
              actionEdit: of(null),
              hideToggle: true,
              blockExpansion: true,
              onHeaderTitleClick: counterValue => this.countersTypeSearchByGroup(CountersGroups.ACTION_ON_ME, counterValue),
              isHidden: of(!this.permissionsService.can('counter-claims_dashboard_cards')),
            },
          },
          {
            type: 'cardWithExpansionList',
            flex: 32,
            config: {
              class: 'flex-1 other-information',
              type: 'labeledList',
              title: this.translate.instant('COUNTER_CLAIMS.ACTION_ON_ICE.TITLE'),
              model: this.store.pipe(
                select(fromRoot.getCopyrightDashboard),
                map(dashboard => CounterClaimUtils.getDashboardCountersType(dashboard, CountersGroups.ACTION_ON_ICE, this.translate)),
              ),
              minHeight: HEIGHT_TOP_CARDS,
              maxHeight: HEIGHT_TOP_CARDS,
              customContentHeight: HEIGHT_CONTENT_TOP_CARDS,
              expanded: of(true),
              filterItemsOn: 10,
              selectable: of(true),
              onSelect: row => this.countersTypeSearch(row),
              showCounter: true,
              counterSumValues: true,
              actionEdit: of(null),
              hideToggle: true,
              blockExpansion: true,
              onHeaderTitleClick: counterValue => this.countersTypeSearchByGroup(CountersGroups.ACTION_ON_ICE, counterValue),
              isHidden: of(!this.permissionsService.can('counter-claims_dashboard_cards')),
            },
          },
          {
            type: 'cardWithExpansionList',
            flex: 32,
            config: {
              class: 'flex-1 other-information',
              type: 'labeledList',
              title: this.translate.instant('COUNTER_CLAIMS.INFORMED_PARTY.TITLE'),
              model: this.store.pipe(
                select(fromRoot.getCopyrightDashboard),
                map(dashboard => CounterClaimUtils.getDashboardCountersType(dashboard, CountersGroups.INFORMED_PARTY, this.translate)),
              ),
              minHeight: HEIGHT_TOP_CARDS,
              maxHeight: HEIGHT_TOP_CARDS,
              customContentHeight: HEIGHT_CONTENT_TOP_CARDS,
              expanded: of(true),
              filterItemsOn: 10,
              selectable: of(true),
              onSelect: row => this.countersTypeSearch(row),
              showCounter: true,
              counterSumValues: true,
              actionEdit: of(null),
              hideToggle: true,
              blockExpansion: true,
              onHeaderTitleClick: counterValue => this.countersTypeSearchByGroup(CountersGroups.INFORMED_PARTY, counterValue),
              // We've made a decision to temporarily remove the "Informed Party" card from view, as it
              // might confuse new users onboarding. The plan (as of October 2024) is to re-add this
              // functionality sometime in the near-ish future. For that reason, much of the logic around
              // INFORMED_PARTY is being left active in the codebase for now, to facilitate "turning it back
              // on." If this comment is still here a year or two later, consider either reintroducing this
              // functionality or removing the dead code from the codebase altogether.
              isHidden: of(true),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('COUNTER_CLAIMS.COUNTER_CLAIM_SEARCH.TITLE'),
              class: 'ice-limit-form',
              model: this.store.select(fromRoot.getCounterClaimDashboardSearch).pipe(map(search => search.result?.items || [])),
              sorts: [],
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              apiCall: getCounterclaimActionsForDashboard,
              ...this.selectionDatatable.getSelectionDatatableConfig(),
              columnMode: 'flex',
              tableWidth: '100',
              toggleButtons: of(),
              sortConfig: { format: this.formatSort },
              onMouseSelect: event => this.openCounterClaimOnNewTab(event),
              filter: {
                formBuilder: this.searchCasesCounterClaimsActionsForm.getDashboardForm(),
                model: this.store.select(fromRoot.getCounterClaimDashboardSearch).pipe(
                  map(search => {
                    return { ...search.filter };
                  }),
                ),
                submitAvailable: true,
                resetAvailable: true,
                submitLabel: this.translate.instant('COUNTER_CLAIMS.COUNTER_CLAIM_SEARCH.SUBMIT'),
                onSubmit: model => {
                  const { dashboardQueryType, dashboardQueryTypePrefix, ...filterModel } = model;
                  this.store.dispatch(new fromRoot.SetCounterclaimDashboardSearchFilter(filterModel));
                },
                onReset: () => this.initialSearch(),
              },
              submitShortcutEnable: true,
            },
          },
        ],
      },
    ];
  }

  openCounterClaimOnNewTab(event) {
    const path =
      (event.activatedColumn === 'counterclaimIdFormatted' && [`${SectionsConfig.COUNTERCLAIMS.domainName}/${SectionsConfig.COUNTERCLAIMS.name}/${event.counterclaimId}`]) ||
      ((event.activatedColumn === 'workId' || event.activatedColumn === 'workTitle') && [
        `${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/ICE:${event.workId}/details`,
      ]);
    if (path) {
      this.store.dispatch(
        new fromRoot.OpenNewTab({
          path: path as any,
        }),
      );
    }
  }

  countersTypeSearchByGroup(group, counterValue) {
    this.countersTypeSearch({
      value: counterValue,
      type: DashboardCountersTypes.filter(type => type.countersGroup === group).map(type => type.apiType),
    });
  }

  countersTypeSearch(row) {
    if (row) {
      if (row.type && this.queryPattern) {
        this.store.dispatch(new fromRoot.SetCounterclaimDashboardSearchFilter({ dashboardQueryType: row.type, dashboardQueryTypePrefix: this.queryPattern }));
      } else {
        this.initialSearch();
      }
    }
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'id':
      case 'idFormatted':
        return { prop: `id`, dir: sort.dir };
      case 'workId':
        return { prop: `counterclaim.work.id`, dir: sort.dir };
      case 'workTitle':
        return { prop: `counterclaim.work.attributes.titles.OT.titleValue`, dir: sort.dir };
      case 'counterclaimIdFormatted':
        return { prop: `attributes.counterclaimId`, dir: sort.dir };
      case 'createdDate':
        return { prop: `counterclaim.attributes.createdDate`, dir: sort.dir };
      case 'resolutionOwner':
        return { prop: `counterclaimParticipant.resolutionOwner`, dir: sort.dir };
      case 'type':
        return { prop: `counterclaim.attributes.type`, dir: sort.dir };
      case 'status':
        return { prop: `counterclaim.attributes.status`, dir: sort.dir };
      case 'territoriesLabel':
        return null;
      case 'actionType':
        return { prop: `attributes.type`, dir: sort.dir };
      case 'actionStatus':
        return { prop: `attributes.status`, dir: sort.dir };
      case 'actionOwner':
        return { prop: `ownerName.attributes.name`, dir: sort.dir };
      case 'actionResponse':
        return { prop: `attributes.response`, dir: sort.dir };
      case 'actionDeadline':
        return { prop: `attributes.deadline`, dir: sort.dir };
    }
  }

  initialSearch() {
    this.store.dispatch(new fromRoot.SetCounterclaimDashboardSearchFilter());
  }
}
