<div class="simple tabbed" *ngIf="components">
  <div class="ice-p-12" fusePerfectScrollbar id="analysis-base" fxLayout="column">
    <ice-form
      *ngIf="(components.comparisonFilter?.isVisible | async) && components.comparisonFilter; let cf"
      [ngClass]="components.comparisonFilter?.formClass ? components.comparisonFilter?.formClass : components.comparisonFilter?.formInline ? 'bg-filter-form ice-p-12' : ''"
      [resetAvailable]="cf.resetAvailable"
      [submitInlineAvailable]="cf.submitAvailable"
      [filterToggleConfig]="cf.filterToggleConfig"
      [submitAvailable]="false"
      [button1Available]="cf.button1Available"
      [formBuilder]="cf.formBuilder | async"
      [model]="cf.model"
      [submitLabel]="cf.submitLabel"
      [button1Label]="cf.button1Label"
      [submitShortcutEnable]="cf.submitShortcutEnable"
      [formInline]="components.comparisonFilter?.formInline"
      [className]="components.comparisonFilter?.className"
      (submit)="cf.onSubmit($event)"
      (button1Clicked)="cf.onButton1 && cf.onButton1($event)"
      #filterForm
    >
    </ice-form>

    <div>
      <ice-card-with-data-table
        class="p-0"
        *ngIf="components.cardConflictScopeDataTable; let conflicts"
        [config]="conflicts"
        [ngStyle]="{ 'width.%': conflicts.tableWidth || '100' }"
        class="{{ conflicts.class }} card-with-data-table-one"
      >
      </ice-card-with-data-table>
    </div>
    <div class="mat-slide-content cy-toogle-highlight" *ngIf="components.toggleHighlightResults?.toggle">
      <mat-slide-toggle
        [matTooltip]="components.toggleHighlightResults.tooltip | async"
        [matTooltipClass]="'ice-tooltip ice-tooltip-slide'"
        [matTooltipPosition]="'above'"
        (change)="components.toggleHighlightResults.switchChange($event)"
        class="headerSwitch"
        [checked]="components.toggleHighlightResults.checked"
      >
        {{ components.toggleHighlightResults.label | async }}
      </mat-slide-toggle>
    </div>
    <div fxLayout="row">
      <div
        id="analysis-left-col"
        [ngClass]="{
          'three-d': true,
          'from-right': fromLeftClass
        }"
      >
        <ng-container *ngIf="components.cardWithFormWorkToCompare; let cwf">
          <ice-extended-work-selector
            *ngIf="cwf?.extendedWorkSelector"
            [extendedWorkSelectorTitle]="cwf.extendedWorkSelectorTitle"
            [extendedWorkSelectorPlaceHolder]="cwf.extendedWorkSelectorPlaceHolder"
            [options]="cwf.options | async"
            [selected]="cwf.optionSelected | async"
            (select)="cwf.onSelect?.($event)"
          >
          </ice-extended-work-selector>
          <ice-card-with-form-dropdown
            fxFlex="47"
            [className]="cwf.className"
            [buttonTitle]="cwf.buttonTitle"
            [buttonTitleTooltip]="cwf.buttonTitleTooltip"
            [title]="cwf.title"
            [titleTooltip]="cwf.titleTooltip"
            [iswc]="cwf.iswc"
            [wsm]="cwf.wsm"
            [expanded]="cwf.expanded"
            [submitAvailable]="cwf.submitAvailable"
            [resetAvailable]="cwf.resetAvailable"
            [formBuilder]="cwf.formBuilder"
            [model]="cwf.model | async"
            [options]="cwf.options | async"
            [showCounterclaimIndicator]="cwf.showCounterclaimIndicator | async"
            [selected]="cwf.optionSelected | async"
            [extendedWorkSelector]="cwf.extendedWorkSelector"
            (newTab)="cwf.openNewTab && cwf.openNewTab($event)"
            (select)="cwf.onSelect?.($event)"
            (open)="cwf.open()"
            (close)="cwf.close()"
          >
          </ice-card-with-form-dropdown>
        </ng-container>
        <ice-card-with-expansion-list
          *ngIf="components.workSocietyMarkerLeft; let wscLeft"
          data-testid="work-society-marker-left"
          fxFlex="100"
          [config]="wscLeft"
          class="ice-card-with-expansion-list ice-mb-0 {{ wscLeft.class || '' }}"
        ></ice-card-with-expansion-list>

        <ice-expansion-list-data-table
          fxFlex="47"
          *ngIf="components.expansionListTitlesLeft; let el0"
          id="expansion-list-all-titles-compare"
          data-testid="expansion-list-all-titles-compare"
          [expanded]="el0.expanded"
          [title]="el0.title + ' (' + (el0.model.model | async).length + ')'"
          [className]="el0.className"
          [selectable]="el0.selectable"
          [model]="el0.model"
          [showTotal]="false"
          [extraData]="el0.extraData"
          [buttonTitle]="el0.buttonTitle"
          [iconConflict]="el0.iconConflict"
          [tooltipIconConflict]="el0.tooltipIconConflict"
          [listIdentifier]="el0.listIdentifier"
          [height]="el0.height"
          [maxHeight]="el0.maxHeight"
          (selectedRowEmit)="el0.onSelect?.($event)"
          (mouseWheelClickRowEmit)="el0.onMouseSelect($event)"
          (newTab)="el0.openNewTab && el0.openNewTab($event)"
          (open)="el0.open()"
          (close)="el0.close()"
        >
        </ice-expansion-list-data-table>
        <ice-expansion-list
          fxFlex="47"
          *ngIf="components.expansionListXrefsLeft; let el0"
          id="expansion-list-xrefs-compare"
          data-testid="expansion-list-xrefs-compare-right"
          [expanded]="el0.expanded"
          [title]="el0.title"
          [listIdentifier]="el0.listIdentifier"
          [selectable]="el0.selectable"
          [model]="el0.model | async"
          [extraData]="el0.extraData"
          [buttonTitle]="el0.buttonTitle"
          [iconConflict]="el0.iconConflict"
          [tooltipIconConflict]="el0.tooltipIconConflict"
          [height]="el0.height"
          [maxHeight]="el0.maxHeight"
          [cardClass]="el0.cardClass"
          (selectedItemEmit)="el0.onSelect?.($event)"
          (mouseWheelClickRowEmit)="el0.onMouseSelect($event)"
          (newTab)="el0.openNewTab && el0.openNewTab($event)"
          (open)="el0.open()"
          (close)="el0.close()"
          (filteringBy)="filterChanged()"
          [filterItemsOn]="100000"
        >
        </ice-expansion-list>
        <div *ngIf="components.hasClaimsHeader" class="claims-header">
          {{ components.claimsHeaderToggle?.text }}
          <mat-radio-group (change)="components.claimsHeaderToggle?.onChange($event)">
            <mat-radio-button
              attr.data-testid="analysis-option-{{ op.label }}"
              class="option"
              *ngFor="let op of components.claimsHeaderToggle?.options"
              [value]="op.value"
              [checked]="op.checked"
            >
              {{ op.label }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <ice-expansion-list-data-table
          fxFlex="47"
          *ngIf="components.expansionListDataTableClaimsLeft; let el0"
          (tableResized)="el0.onResized && el0.onResized($event)"
          id="compare-expansion-list-datatable"
          data-testid="compare-expansion-list-datatable"
          [expanded]="el0.expanded"
          [title]="el0.title"
          [className]="el0.className"
          [buttonTitle]="el0.buttonTitle"
          [buttonTitleTooltip]="el0.buttonTitleTooltip"
          [iconConflict]="el0.iconConflict"
          [listIdentifier]="el0.listIdentifier"
          [tooltipIconConflict]="el0.tooltipIconConflict"
          [selectable]="el0.selectable"
          [showTotal]="false"
          [model]="el0.model"
          [extraData]="el0.extraData"
          [height]="el0.height"
          [maxHeight]="el0.maxHeight"
          [singleSelection]="el0.singleSelection"
          (selectedCheckboxEmit)="el0.onSelectedCheckbox($event, el0)"
          (selectedRowEmit)="el0.onSelect?.($event)"
          (mouseWheelClickRowEmit)="el0.onMouseSelect($event)"
          (newTab)="el0.openNewTab && el0.openNewTab($event)"
          (open)="el0.open()"
          (close)="el0.close()"
        >
        </ice-expansion-list-data-table>
        <ice-expansion-list-data-table
          fxFlex="47"
          *ngIf="components.expansionListDataTableInstrumentsLeft; let el0"
          (tableResized)="el0.onResized && el0.onResized($event)"
          id="expansion-list-instruments-compare"
          data-testid="expansion-list-instruments-compare"
          [expanded]="el0.expanded"
          [title]="el0.title"
          [className]="el0.className"
          [buttonTitle]="el0.buttonTitle"
          [iconConflict]="el0.iconConflict"
          [listIdentifier]="el0.listIdentifier"
          [tooltipIconConflict]="el0.tooltipIconConflict"
          [selectable]="el0.selectable"
          [showTotal]="true"
          [model]="el0.model"
          [extraData]="el0.extraData"
          [height]="el0.height"
          (selectedRowEmit)="el0.onSelect?.($event)"
          (mouseWheelClickRowEmit)="el0.onMouseSelect($event)"
          (newTab)="el0.openNewTab && el0.openNewTab($event)"
          (open)="el0.open()"
          (close)="el0.close()"
        >
        </ice-expansion-list-data-table>
        <ice-expansion-list-data-table
          fxFlex="47"
          *ngIf="components.expansionListDataTableExtendedToPLeft; let el0"
          (tableResized)="el0.onResized && el0.onResized($event)"
          id="compare-expansion-list-datatable"
          data-testid="compare-expansion-list-datatable"
          [expanded]="el0.expanded"
          [title]="el0.title"
          [className]="el0.className"
          [buttonTitle]="el0.buttonTitle"
          [iconConflict]="el0.iconConflict"
          [listIdentifier]="el0.listIdentifier"
          [tooltipIconConflict]="el0.tooltipIconConflict"
          [selectable]="el0.selectable"
          [showTotal]="true"
          [model]="el0.model"
          [extraData]="el0.extraData"
          [height]="el0.height"
          (selectedRowEmit)="el0.onSelect?.($event)"
          (mouseWheelClickRowEmit)="el0.onMouseSelect($event)"
          (newTab)="el0.openNewTab && el0.openNewTab($event)"
          (open)="el0.open()"
          (close)="el0.close()"
        >
        </ice-expansion-list-data-table>
        <ice-expansion-list-data-table
          fxFlex="47"
          *ngIf="components.expansionListDataTableInstrumentationLeft; let el0"
          (tableResized)="el0.onResized && el0.onResized($event)"
          id="expansion-list-datatable"
          data-testid="expansion-list-datatable"
          [expanded]="el0.expanded"
          [title]="el0.title"
          [className]="el0.className"
          [buttonTitle]="el0.buttonTitle"
          [iconConflict]="el0.iconConflict"
          [listIdentifier]="el0.listIdentifier"
          [tooltipIconConflict]="el0.tooltipIconConflict"
          [selectable]="el0.selectable"
          [showTotal]="true"
          [model]="el0.model"
          [extraData]="el0.extraData"
          [height]="el0.height"
          (selectedRowEmit)="el0.onSelect?.($event)"
          (mouseWheelClickRowEmit)="el0.onMouseSelect($event)"
          (newTab)="el0.openNewTab && el0.openNewTab($event)"
          (open)="el0.open()"
          (close)="el0.close()"
        >
        </ice-expansion-list-data-table>
        <ice-expansion-list-data-table
          fxFlex="47"
          *ngIf="components.otherPartiesLeft; let cop"
          id="expansion-list-other-parties-compare"
          data-testid="expansion-list-other-parties-compare"
          [expanded]="cop.expanded"
          [title]="cop.title"
          [className]="cop.className"
          [selectable]="cop.selectable"
          [model]="cop.model"
          [showTotal]="true"
          [extraData]="cop.extraData"
          [buttonTitle]="cop.buttonTitle"
          [iconConflict]="cop.iconConflict"
          [tooltipIconConflict]="cop.tooltipIconConflict"
          [listIdentifier]="cop.listIdentifier"
          [height]="cop.height"
          [maxHeight]="cop.maxHeight"
          (selectedRowEmit)="cop.onSelect?.($event)"
          (mouseWheelClickRowEmit)="cop.onMouseSelect($event)"
          (newTab)="cop.openNewTab && cop.openNewTab($event)"
          (open)="cop.open()"
          (close)="cop.close()"
        >
        </ice-expansion-list-data-table>
      </div>
      <div
        id="analysis-right-col"
        [ngClass]="{
          'three-d': true,
          'from-right': fromRightClass
        }"
      >
        <ng-container>
          <ng-container *ngIf="components.cardWithFormMasteredWork; let cwfm">
            <ng-container *ngIf="cwfm.options | async as options">
              <ng-container *ngIf="options.length > 3; else divTemplate">
                <ice-extended-work-selector
                  [extendedWorkSelectorTitle]="cwfm.extendedWorkSelectorTitle"
                  [extendedWorkSelectorPlaceHolder]="cwfm.extendedWorkSelectorPlaceHolder"
                  [options]="options"
                  [selected]="cwfm.optionSelected | async"
                  (select)="cwfm.onSelect?.($event)"
                  class="cy-work-analysis-selector"
                >
                </ice-extended-work-selector>
              </ng-container>
              <ng-template #divTemplate>
                <div class="ice-extended-work-selector cy-empty-work-div"></div>
              </ng-template>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="components.cardWithFormMasteredWork && !components.cardWithFormMasteredWork.className.includes('compare-card')">
            <div class="ice-extended-work-selector cy-empty-work-div"></div>
          </ng-container>
          <ice-card-with-form-dropdown
            fxFlex="47"
            *ngIf="components.cardWithFormMasteredWork; let cwf"
            [buttonTitle]="cwf.buttonTitle"
            [buttonTitleTooltip]="cwf.buttonTitleTooltip"
            [title]="cwf.title"
            [titleTooltip]="cwf.titleTooltip"
            [iswc]="cwf.iswc"
            [wsm]="cwf.wsm"
            [expanded]="cwf.expanded"
            [referenceId]="cwf.referenceId"
            [className]="cwf.className"
            [submitAvailable]="cwf.submitAvailable"
            [resetAvailable]="cwf.resetAvailable"
            [formBuilder]="cwf.formBuilder"
            [model]="cwf.model | async"
            (select)="cwf.onSelect && cwf.onSelect?.($event)"
            (newTab)="cwf.openNewTab && cwf.openNewTab($event)"
            [options]="cwf.options | async"
            [showCounterclaimIndicator]="cwf.showCounterclaimIndicator | async"
            [extendedWorkSelector]="cwf.extendedWorkSelector"
            [selected]="cwf.extendedWorkSelector?.optionSelected | async"
            (open)="cwf.open()"
            (close)="cwf.close()"
          >
          </ice-card-with-form-dropdown>
        </ng-container>
        <ice-card-with-expansion-list
          *ngIf="components.workSocietyMarkerRight; let wscRight"
          data-testid="work-society-marker-right"
          fxFlex="100"
          [config]="wscRight"
          class="ice-card-with-expansion-list {{ wscRight.class || '' }}"
        ></ice-card-with-expansion-list>

        <ice-expansion-list-data-table
          fxFlex="47"
          *ngIf="components.expansionListTitlesRight; let el1"
          id="expansion-list-all-titles-main"
          [className]="el1.className"
          [expanded]="el1.expanded"
          [title]="el1.title + ' (' + (el1.model.model | async).length + ')'"
          [listIdentifier]="el1.listIdentifier"
          [selectable]="el1.selectable"
          [model]="el1.model"
          [showTotal]="false"
          [extraData]="el1.extraData"
          [buttonTitle]="el1.buttonTitle"
          [iconConflict]="el1.iconConflict"
          [tooltipIconConflict]="el1.tooltipIconConflict"
          [height]="el1.height"
          [maxHeight]="el1.maxHeight"
          (selectedRowEmit)="el1.onSelect?.($event)"
          (mouseWheelClickRowEmit)="el1.onMouseSelect($event)"
          (newTab)="el1.openNewTab && el1.openNewTab($event)"
          (open)="el1.open()"
          (close)="el1.close()"
        >
        </ice-expansion-list-data-table>
        <ice-expansion-list
          fxFlex="47"
          *ngIf="components.expansionListXrefsRight; let el1"
          id="expansion-list-xrefs-main"
          data-testid="expansion-list-xrefs-left"
          [className]="el1.className"
          [expanded]="el1.expanded"
          [title]="el1.title"
          [listIdentifier]="el1.listIdentifier"
          [selectable]="el1.selectable"
          [model]="el1.model | async"
          [extraData]="el1.extraData"
          [buttonTitle]="el1.buttonTitle"
          [iconConflict]="el1.iconConflict"
          [tooltipIconConflict]="el1.tooltipIconConflict"
          [height]="el1.height"
          [maxHeight]="el1.maxHeight"
          [cardClass]="el1.cardClass"
          (selectedItemEmit)="el1.onSelect?.($event)"
          (mouseWheelClickRowEmit)="el1.onMouseSelect($event)"
          (newTab)="el1.openNewTab && el1.openNewTab($event)"
          (open)="el1.open()"
          (close)="el1.close()"
          (filteringBy)="filterChanged()"
          [filterItemsOn]="100000"
        >
        </ice-expansion-list>
        <div *ngIf="components.hasClaimsHeader" class="claims-header">
          <ice-buttons-group
            *ngIf="components.claimsHeaderButtons; let claimsHeaderButtons"
            style="width: 100%"
            [config]="claimsHeaderButtons.config"
            (emitEvent)="claimsHeaderButtons.emitEvent($event)"
          ></ice-buttons-group>
        </div>
        <ice-expansion-list-data-table
          fxFlex="47"
          *ngIf="components.expansionListDataTableClaimsRight; let el0"
          id="mastered-expansion-list-datatable"
          [className]="el0.className"
          [buttonTitle]="el0.buttonTitle"
          [buttonTitleTooltip]="el0.buttonTitleTooltip"
          [listIdentifier]="el0.listIdentifier"
          [iconConflict]="el0.iconConflict"
          [tooltipIconConflict]="el0.tooltipIconConflict"
          [expanded]="el0.expanded"
          [title]="el0.title"
          [selectable]="el0.selectable"
          [showTotal]="false"
          [model]="el0.model"
          [extraData]="el0.extraData"
          [height]="el0.height"
          [maxHeight]="el0.maxHeight"
          [singleSelection]="el0.singleSelection"
          (selectedCheckboxEmit)="el0.onSelectedCheckbox($event, el0)"
          (selectedRowEmit)="el0.onSelect?.($event)"
          (mouseWheelClickRowEmit)="el0.onMouseSelect($event)"
          (newTab)="el0.openNewTab && el0.openNewTab($event)"
          (open)="el0.open()"
          (close)="el0.close()"
        >
        </ice-expansion-list-data-table>
        <ice-expansion-list-data-table
          fxFlex="47"
          *ngIf="components.expansionListDataTableInstrumentsRight; let el0"
          id="expansion-list-instruments-main"
          [className]="el0.className"
          [buttonTitle]="el0.buttonTitle"
          [iconConflict]="el0.iconConflict"
          [listIdentifier]="el0.listIdentifier"
          [tooltipIconConflict]="el0.tooltipIconConflict"
          [expanded]="el0.expanded"
          [title]="el0.title"
          [selectable]="el0.selectable"
          [showTotal]="true"
          [model]="el0.model"
          [extraData]="el0.extraData"
          [height]="el0.height"
          (selectedRowEmit)="el0.onSelect?.($event)"
          (mouseWheelClickRowEmit)="el0.onMouseSelect($event)"
          (newTab)="el0.openNewTab && el0.openNewTab($event)"
          (open)="el0.open()"
          (close)="el0.close()"
        >
        </ice-expansion-list-data-table>
        <ice-expansion-list-data-table
          fxFlex="47"
          *ngIf="components.expansionListDataTableExtendedToPRight; let el0"
          id="mastered-expansion-list-datatable"
          [className]="el0.className"
          [buttonTitle]="el0.buttonTitle"
          [iconConflict]="el0.iconConflict"
          [listIdentifier]="el0.listIdentifier"
          [tooltipIconConflict]="el0.tooltipIconConflict"
          [expanded]="el0.expanded"
          [title]="el0.title"
          [selectable]="el0.selectable"
          [showTotal]="true"
          [model]="el0.model"
          [extraData]="el0.extraData"
          [height]="el0.height"
          (selectedRowEmit)="el0.onSelect?.($event)"
          (mouseWheelClickRowEmit)="el0.onMouseSelect($event)"
          (newTab)="el0.openNewTab && el0.openNewTab($event)"
          (open)="el0.open()"
          (close)="el0.close()"
        >
        </ice-expansion-list-data-table>
        <ice-expansion-list-data-table
          fxFlex="47"
          *ngIf="components.expansionListDataTableInstrumentationRight; let el0"
          id="mastered-expansion-list-datatable"
          [className]="el0.className"
          [buttonTitle]="el0.buttonTitle"
          [iconConflict]="el0.iconConflict"
          [listIdentifier]="el0.listIdentifier"
          [tooltipIconConflict]="el0.tooltipIconConflict"
          [expanded]="el0.expanded"
          [title]="el0.title"
          [selectable]="el0.selectable"
          [showTotal]="true"
          [model]="el0.model"
          [extraData]="el0.extraData"
          [height]="el0.height"
          (selectedRowEmit)="el0.onSelect?.($event)"
          (mouseWheelClickRowEmit)="el0.onMouseSelect($event)"
          (newTab)="el0.openNewTab && el0.openNewTab($event)"
          (open)="el0.open()"
          (close)="el0.close()"
        >
        </ice-expansion-list-data-table>
        <ice-expansion-list-data-table
          fxFlex="47"
          *ngIf="components.otherPartiesRight; let cop"
          id="expansion-list-other-parties-main"
          [expanded]="cop.expanded"
          [title]="cop.title"
          [className]="cop.className"
          [selectable]="cop.selectable"
          [model]="cop.model"
          [showTotal]="true"
          [extraData]="cop.extraData"
          [buttonTitle]="cop.buttonTitle"
          [iconConflict]="cop.iconConflict"
          [tooltipIconConflict]="cop.tooltipIconConflict"
          [listIdentifier]="cop.listIdentifier"
          [height]="cop.height"
          [maxHeight]="cop.maxHeight"
          (selectedRowEmit)="cop.onSelect?.($event)"
          (mouseWheelClickRowEmit)="cop.onMouseSelect($event)"
          (newTab)="cop.openNewTab && cop.openNewTab($event)"
          (open)="cop.open()"
          (close)="cop.close()"
        >
        </ice-expansion-list-data-table>
      </div>
    </div>
  </div>
</div>
