import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { SearchUtils, FormlyValidatorUtils } from '@ice';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { AGREEMENTS_CONSTANTS, LINKED_WRITERS_IP_SEARCH } from 'config/constants/agreements.constants';
import { IpNature } from 'config/constants/ips.constants';
import { TYPE_PUBLISHER } from 'config/constants/shares.constants';
import { FormConfig, SearchExpressions } from './form-config';

export class IpsSearchExpressions extends SearchExpressions {
  getFieldsToCombine() {
    return [{ fields: ['name', 'firstName'], combinedField: 'fullName' }];
  }

  getSearchExpressionConfig(key: string, value: any, prefix: string, allUsers: any) {
    switch (key) {
      case 'fullName':
        return `{
          "or":[
              {
              "and":[
                ${SearchUtils.searchQueryWithComparator('attributes.name', value.name)},
                ${SearchUtils.searchQueryWithComparator('attributes.firstName', value.firstName)}
                ]
              },
                {
              "and":[
                ${SearchUtils.searchQueryWithComparator('attributes.names.name', value.name)},
                ${SearchUtils.searchQueryWithComparator('attributes.names.firstName', value.firstName)}
                ]
              }
            ]
        }`;
      case 'name':
      case 'firstName':
        return `{
          "or":[
                ${SearchUtils.searchQueryWithComparator(`attributes.${key}`, value)},
                ${SearchUtils.searchQueryWithComparator(`attributes.names.${key}`, value)}
            ]
        }`;
      case 'addByBase': // addByBase doesn't change the search
        return ``;
      case 'baseId':
        let baseId = value;
        if (!value.includes(':')) {
          baseId = prefix + ':' + value;
        }
        return `{"equals":{"parties.party.relations[XREF].otherId":"${baseId}"}}`;
      case 'existsIPI':
        return '{"wildcard":{"relations[XREF].otherId":"IPI:*"}}';
      case 'needsBase':
        return `{"not": {"equals":{"parties.party.id":""}}}`;
      case 'organizationMembership':
        return `{"equals":{"parties.party.societies.societyId":"${value}"}}`;
      case 'searchPartyNameFilterByRelationTypeExist':
        return `{"and":[{"wildcard":{"relations[XREF].otherId":"${value}:*"}}]}`;
      case 'includeInactive':
        if (!value) {
          return `{"equals":{"parties.party.attributes.status":"1"}}`;
        }
        return null;
      case 'typeOf':
        return `{"equals":{"${'parties.party.attributes.' + key}":"${value}"}}`;
      case 'typeOfName':
        return `{"equals":{"${'attributes.' + key}":"${value}"}}`;
      case 'territoriesMatchType':
      case 'type':
        return '';
      case 'xref':
        let pattern: string;
        let addPrefix = value.split(':').length > 1 ? false : true;

        switch (prefix) {
          case 'ALL':
            addPrefix = false;
            pattern = `{ "or":[{"<<type>>":{"relations[XREF].otherId":"<<value>>"}},{"<<type>>":{"parties.party.relations[XREF].otherId":"<<value>>"}}]}`;
            break;
          case 'SO*':
            const formatValue = addPrefix ? `*:${value}` : value;
            pattern = `{"wildcard":{"relations[XREF].otherId":"SIREF:${formatValue}*"}}`;
            break;
          case 'ICE-IPI':
            addPrefix = false;
            pattern = `{ "or":[{"equals":{"relations[XREF].otherId":"ICE:${value}"}},{"equals":{"relations[XREF].otherId":"IPI:${value}"}}]}`;
            break;
          case 'PB*':
          case 'IPI':
          case 'ICE':
            pattern = `{"<<type>>":{"relations[XREF].otherId":"<<value>>"}}`;
        }
        if (pattern) {
          const formatValue = addPrefix && (prefix === 'IPI' || prefix === 'ICE') ? `${prefix}:${value}` : value;
          return this.formatReferenceSearch(pattern, formatValue.trim(), prefix, addPrefix);
        }
        // SAYT with #
        pattern = pattern || `{"<<type>>":{"relations[XREF].otherId":"<<value>>"}}`;
        return this.formatReferenceSearch(pattern, this.formatExactIDSearch(value.trim()), prefix);
      case 'xrefs':
        return `{"or":[${value
          .split(',')
          .map(id => `{"equals":{"relations[XREF].otherId":"${id}"}}`)
          .join(',')}]}`;
    }
    value = value && value.trim();
    return `{"wildcard":{"${'attributes.' + key}":"*${value}*"}}`;
  }

  getExtraSearchExpression(searchParameters) {
    let includeInactiveQuery = '';
    if (searchParameters && !searchParameters.hasOwnProperty('includeInactive')) {
      includeInactiveQuery = '{"equals":{"parties.party.attributes.status":"1"}},';
    }
    return `${includeInactiveQuery}{"phrase":{"relations.otherId":"ICE"}}`;
  }
}

export class SearchCopyrightIpsForm implements FormConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  // Filter form
  getForm(typeOfEntity?: any, defaultXrefPrefix?: string, baseType: string = null, formButtons: any = null): FormlyFieldConfig[] {
    if (formButtons === null) {
      return this.getOldStructureForm(typeOfEntity, defaultXrefPrefix, baseType);
    }

    const typeOf = this.getTypeOfField(typeOfEntity, baseType);

    const needsBase = {
      className: 'input-wrapper',
      key: 'needsBase',
      type: 'input',
      defaultValue: 'Y',
      hideExpression: model => {
        return true;
      },
      templateOptions: {
        label: this.translate.instant('IPS.TYPE_OF'),
        required: false,
        disabled: true,
      },
    };

    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'input-wrapper',
            key: 'name',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('IPS.LAST_NAME'),
              required: false,
            },
          },
          {
            className: 'input-wrapper',
            key: 'firstName',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('IPS.FIRST_NAME'),
              required: false,
            },
          },
          {
            className: 'input-wrapper',
            key: 'xrefPrefix',
            type: 'select',
            defaultValue: defaultXrefPrefix || 'IPI',
            templateOptions: {
              label: this.translate.instant('IPS.XREF_PREFIX.NAME'),
              options: [
                { label: this.translate.instant('IPS.SELECT_ALL'), value: 'ALL' },
                { label: this.translate.instant('IPS.XREF_PREFIX.SOCIETY'), value: 'SO*' },
                { label: this.translate.instant('IPS.XREF_PREFIX.IPI_NAME'), value: 'IPI' },
                { label: this.translate.instant('IPS.XREF_PREFIX.IP_NAME_KEY'), value: 'ICE' },
              ],
              required: false,
              disableOptionCentering: true,
            },
          },
          {
            className: 'input-wrapper',
            key: 'xref',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('IPS.XREF_PREFIX.XREF'),
              required: false,
            },
            asyncValidators: {
              workRefValidation: FormlyValidatorUtils.getReferenceValidator(this.translate, 'COMMON.ERRORS.INVALID_REFERENCE'),
            },
          },
          {
            className: 'input-wrapper',
            key: 'baseIdPrefix',
            type: 'select',
            defaultValue: 'IPI',
            templateOptions: {
              label: this.translate.instant('IPS.IPI_BASE_NUMBER_OR_KEY.NAME'),
              options: [
                { label: this.translate.instant('IPS.IPI_BASE_NUMBER_OR_KEY.IPI_BASE_NUMBER'), value: 'IPI' },
                { label: this.translate.instant('IPS.IPI_BASE_NUMBER_OR_KEY.ICE_IP_BASE_KEY'), value: 'ICE' },
              ],
              required: false,
              disableOptionCentering: true,
            },
          },
          {
            className: 'input-wrapper',
            key: 'baseId',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('IPS.ID'),
              required: false,
            },
            asyncValidators: {
              workRefValidation: FormlyValidatorUtils.getReferenceValidator(this.translate, 'COMMON.ERRORS.INVALID_REFERENCE', true),
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex ice-justify-space-between ice-mt-12',
        fieldGroup: [
          {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
              {
                className: 'input-wrapper',
                key: 'typeOfName',
                type: 'select',
                defaultValue: '',
                templateOptions: {
                  label: this.translate.instant('IPS.TYPE_OF_NAME'),
                  options: [
                    { label: this.translate.instant('IPS.VALUES_ALL'), value: '' },
                    { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.DF'), value: 'DF' },
                    { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.HR'), value: 'HR' },
                    { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.MO'), value: 'MO' },
                    { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.OR'), value: 'OR' },
                    { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.PA'), value: 'PA' },
                    ...(![AGREEMENTS_CONSTANTS.TYPE_ASSIGNEE, AGREEMENTS_CONSTANTS.TYPE_ASSIGNOR].includes(typeOfEntity)
                      ? [{ label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.PG'), value: 'PG' }]
                      : []),
                    { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.PP'), value: 'PP' },
                    { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.ST'), value: 'ST' },
                  ],
                  required: false,
                  disableOptionCentering: true,
                },
              },
              typeOf,
              needsBase,
            ],
          },
          {
            fieldGroupClassName: 'display-flex flex-align-items-center ice-accent',
            fieldGroup: [
              {
                className: 'input-checkbox-wrapper',
                key: 'includeInactive',
                type: 'checkbox',
                defaultValue: false,
                templateOptions: {
                  label: this.translate.instant('IPS.INCLUDE_INACTIVE'),
                  required: false,
                },
              },
              ...formButtons,
            ],
          },
        ],
      },
    ];
  }

  getTypeOfField(typeOfEntity, baseType, flexed = false) {
    if (baseType) {
      return this.initTypeOfField(baseType, flexed);
    } else if (
      typeOfEntity &&
      (typeOfEntity === AGREEMENTS_CONSTANTS.TYPE_ASSIGNEE || typeOfEntity === IpNature.TYPE_PARENT || typeOfEntity === IpNature.TYPE_CHILDREN || typeOfEntity === TYPE_PUBLISHER)
    ) {
      return this.initTypeOfField(IpNature.LEGAL_ENTITY, flexed);
    } else if (typeOfEntity === LINKED_WRITERS_IP_SEARCH) {
      return this.initTypeOfField(IpNature.NATURAL_PERSON, flexed);
    } else {
      return {
        className: flexed ? 'flex-1' : 'input-wrapper',
        key: 'typeOf',
        type: 'select',
        defaultValue: '',
        templateOptions: {
          label: this.translate.instant('IPS.TYPE_OF'),
          options: [
            { label: this.translate.instant('IPS.SELECT_ALL'), value: '' },
            { label: this.translate.instant('IPS.LEGAL_ENTITY'), value: 'L' },
            { label: this.translate.instant('IPS.NATURAL_PERSON'), value: 'N' },
          ],
          required: false,
          disableOptionCentering: true,
        },
      };
    }
  }

  initTypeOfField(type: string, flexed: boolean) {
    return {
      className: flexed ? 'flex-1' : 'input-wrapper',
      key: 'typeOf',
      type: 'select',
      defaultValue: type,
      templateOptions: {
        label: this.translate.instant('IPS.TYPE_OF'),
        options: [{ label: this.translate.instant(`IPS.${type === IpNature.LEGAL_ENTITY ? 'LEGAL_ENTITY' : 'NATURAL_PERSON'}`), value: type }],
        required: false,
        disabled: true,
        disableOptionCentering: true,
      },
    };
  }

  getDefaultValues(typeOfEntity?: any, defaultXrefPrefix?: string, baseType: string = null) {
    let typeOf = '';
    if (baseType) {
      typeOf = baseType;
    } else if (
      typeOfEntity &&
      (typeOfEntity === AGREEMENTS_CONSTANTS.TYPE_ASSIGNEE || typeOfEntity === IpNature.TYPE_PARENT || typeOfEntity === IpNature.TYPE_CHILDREN || typeOfEntity === TYPE_PUBLISHER)
    ) {
      typeOf = IpNature.LEGAL_ENTITY;
    }

    return {
      baseIdPrefix: 'IPI',
      includeInactive: false,
      name: '',
      needsBase: 'Y',
      typeOf,
      typeOfName: '',
      xrefPrefix: 'IPI',
    };
  }

  // needs to be refactored with search ips refactor tickets
  getOldStructureForm(typeOfEntity, defaultXrefPrefix, baseType) {
    const typeOf = this.getTypeOfField(typeOfEntity, baseType, true);
    const needsBase = {
      className: 'flex-1',
      key: 'needsBase',
      type: 'input',
      defaultValue: 'Y',
      hideExpression: model => {
        return true;
      },
      templateOptions: {
        label: this.translate.instant('IPS.TYPE_OF'),
        required: false,
        disabled: true,
      },
    };

    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'name',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('IPS.LAST_NAME'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'firstName',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('IPS.FIRST_NAME'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'xrefPrefix',
            type: 'select',
            defaultValue: defaultXrefPrefix || 'IPI',
            templateOptions: {
              label: this.translate.instant('IPS.XREF_PREFIX.NAME'),
              options: [
                { label: this.translate.instant('IPS.SELECT_ALL'), value: 'ALL' },
                { label: this.translate.instant('IPS.XREF_PREFIX.SOCIETY'), value: 'SO*' },
                { label: this.translate.instant('IPS.XREF_PREFIX.IPI_NAME'), value: 'IPI' },
                { label: this.translate.instant('IPS.XREF_PREFIX.IP_NAME_KEY'), value: 'ICE' },
              ],
              required: false,
              disableOptionCentering: true,
            },
          },
          {
            className: 'flex-1',
            key: 'xref',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('IPS.XREF_PREFIX.XREF'),
              required: false,
            },
            asyncValidators: {
              workRefValidation: FormlyValidatorUtils.getReferenceValidator(this.translate, 'COMMON.ERRORS.INVALID_REFERENCE'),
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'baseIdPrefix',
            type: 'select',
            defaultValue: 'IPI',
            templateOptions: {
              label: this.translate.instant('IPS.IPI_BASE_NUMBER_OR_KEY.NAME'),
              options: [
                { label: this.translate.instant('IPS.IPI_BASE_NUMBER_OR_KEY.IPI_BASE_NUMBER'), value: 'IPI' },
                { label: this.translate.instant('IPS.IPI_BASE_NUMBER_OR_KEY.ICE_IP_BASE_KEY'), value: 'ICE' },
              ],
              required: false,
              disableOptionCentering: true,
            },
          },
          {
            className: 'flex-1',
            key: 'baseId',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('IPS.ID'),
              required: false,
            },
            asyncValidators: {
              workRefValidation: FormlyValidatorUtils.getReferenceValidator(this.translate, 'COMMON.ERRORS.INVALID_REFERENCE', true),
            },
          },
          {
            className: 'flex-1',
            key: 'typeOfName',
            type: 'select',
            defaultValue: '',
            templateOptions: {
              label: this.translate.instant('IPS.TYPE_OF_NAME'),
              options: [
                { label: this.translate.instant('IPS.VALUES_ALL'), value: '' },
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.DF'), value: 'DF' },
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.HR'), value: 'HR' },
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.MO'), value: 'MO' },
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.OR'), value: 'OR' },
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.PA'), value: 'PA' },
                ...(![AGREEMENTS_CONSTANTS.TYPE_ASSIGNEE, AGREEMENTS_CONSTANTS.TYPE_ASSIGNOR].includes(typeOfEntity)
                  ? [{ label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.PG'), value: 'PG' }]
                  : []),
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.PP'), value: 'PP' },
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.ST'), value: 'ST' },
              ],
              required: false,
              disableOptionCentering: true,
            },
          },
          typeOf,
          needsBase,
          {
            className: 'flex-1',
            key: 'includeInactive',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: {
              label: this.translate.instant('IPS.INCLUDE_INACTIVE'),
              required: false,
              indeterminate: false,
            },
          },
        ],
      },
    ];
  }
}
