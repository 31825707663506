import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AllClaimsFilter, ClaimantUtils, CounterClaimClaimantRelation, WorkClaimsUtils } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { CopyrightClaimsDataTable } from 'config/data-table-builders/copyright.claims';
import { findIndex, get, isEmpty, slice, sortBy } from 'lodash';
import { CounterClaimGroup } from 'models/copyright/send-update/counter-claim';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, take, tap, withLatestFrom } from 'rxjs/operators';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';

export class DialogCounterClaimResolvers {
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  private selectedResolvers$ = new BehaviorSubject([]);
  private claimant$ = new BehaviorSubject({});
  private hideDelete$ = new BehaviorSubject(true);
  private selectedResolvers;
  private otherClaimGroups: CounterClaimGroup[];
  private ownedResolvers: CounterClaimGroup[];

  constructor(
    private translate: TranslateService,
    private store: Store<any>,
    private dialog: MatDialog,
    private schema: CopyrightClaimsDataTable,
    private filterParams: AllClaimsFilter,
  ) {}

  openDialog(row) {
    this.initializeGroups();
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        layouts: [this.getSelectClaimantResolversLayout(row)],
      },
    });
  }

  initializeGroups() {
    this.claimant$.next({});
    this.selectedResolvers$.next([]);
    this.hideDelete$.next(true);
    this.selectedResolvers = [];
    this.otherClaimGroups = [];
    this.ownedResolvers = [];
  }

  getSelectClaimantResolversLayout(row) {
    return {
      title: of(this.translate.instant('COUNTERCLAIMS.RESOLVERS_DIALOG.TITLE')),
      className: 'dialog-wrapper-width-70vw',
      actions: [
        {
          tooltip: this.translate.instant('POPUP.DELETE'),
          color: 'accent',
          hidden: this.hideDelete$.asObservable(),
          icon: 'delete',
          onClick: () => {
            this.store.dispatch(new fromForm.SetClaimantGroups(this.otherClaimGroups));
            this.dialogRef.close();
          },
        },
        { tooltip: this.translate.instant('POPUP.CANCEL'), color: 'warn', icon: 'close', onClick: () => this.dialogRef.close() },
        {
          tooltip: this.translate.instant('POPUP.CONFIRM'),
          icon: 'done',
          color: 'success',
          disabled: combineLatest([
            this.selectedResolvers$.pipe(map(selectedResolvers => isEmpty(selectedResolvers))),
            this.claimant$.pipe(map(claimant => get(claimant, 'claimant'))),
          ]).pipe(map(([noResolvers, hasStatus]) => noResolvers || !hasStatus)),
          onClick: () => {
            this.claimant$.pipe(take(1)).subscribe((claimant: any) => {
              const claimGroups = [...this.otherClaimGroups, ...ClaimantUtils.getClaimGroup(this.selectedResolvers, claimant, this.ownedResolvers)];
              const sortedClaimGroups = sortBy(claimGroups, 'participantNameId');
              this.store.dispatch(new fromForm.SetClaimantGroups(sortedClaimGroups));
              this.dialogRef.close();
            });
          },
        },
      ],
      layout: [
        {
          group: [
            {
              type: 'formly',
              config: {
                formBuilder: of(this.getClaimantInfoForm(row)),
                model: this.claimant$,
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'dataTable',
              config: {
                data: combineLatest([
                  this.store.select(fromRoot.getAllClaims).pipe(map(claims => WorkClaimsUtils.claimsWithSocietyCodes({ claims, claimsFilter: this.filterParams }))),
                  this.store.select(fromForm.getClaimantGroups).pipe(
                    withLatestFrom(this.claimant$),
                    tap(([claimantGroups, claimant]: [CounterClaimGroup[], any]) => {
                      if (!claimant.claimant) {
                        const findGroup = claimantGroups.find(
                          claimGroup => !claimGroup.parentParticipantNameId && row.id === claimGroup.participantNameId && claimGroup.topLevelNameId === row.topLevelNameId,
                        );
                        this.hideDelete$.next(!findGroup);
                        this.claimant$.next({ ...row, ...(findGroup?.relation ? { claimant: findGroup.relation } : {}) });
                      }
                    }),
                    map(([claimantGroups]) => claimantGroups),
                  ),
                ]).pipe(
                  distinctUntilChanged(),
                  map(([claimants, claimGroups]: [any[], any[]]) => {
                    this.ownedResolvers = claimGroups.filter(claimGroup => row.id === claimGroup.parentParticipantNameId && claimGroup.topLevelNameId === row.topLevelNameId);
                    this.otherClaimGroups = claimGroups.filter(
                      claimGroup =>
                        !((row.id === claimGroup.participantNameId || row.id === claimGroup.parentParticipantNameId) && row.topLevelNameId === claimGroup.topLevelNameId),
                    );
                    const otherResolvers = this.otherClaimGroups
                      .filter(claimGroup => claimGroup.relation === CounterClaimClaimantRelation.RESOLVER)
                      .map(claimGroup => claimGroup.participantNameId + claimGroup.topLevelNameId);
                    const claimantStartIndex = findIndex(
                      claimants,
                      claimant => claimant.id === row.id && claimant.topLevelNameId === row.topLevelNameId && claimant.claimHash === row.claimHash,
                    );
                    const claimantEndIndex = findIndex(claimants, claimant => claimant.level <= row.level, Math.min(claimantStartIndex + 1, claimants.length));
                    const allowedClaimants = slice(
                      claimants,
                      claimantStartIndex !== -1 ? claimantStartIndex : claimants.length,
                      claimantEndIndex !== -1 ? claimantEndIndex : claimants.length,
                    ).filter(claim => !otherResolvers.includes(claim.id + claim.topLevelNameId));
                    const selected = allowedClaimants.filter(claimant =>
                      this.ownedResolvers.map(claimGroup => claimGroup.participantNameId + claimGroup.topLevelNameId).includes(claimant.id + claimant.topLevelNameId),
                    );
                    this.selectedResolvers$.next(selected);
                    this.selectedResolvers = selected;
                    return allowedClaimants;
                  }),
                ),
                dontStrip: true,
                customClass: 'claim-graph',
                columnMode: 'flex',
                onSelect: selected => ((this.selectedResolvers = selected), this.selectedResolvers$.next(selected)),
                selected: this.selectedResolvers$.asObservable(),
                schema: this.schema.getDataTable(null, null, true),
                selectionType: SelectionType.checkbox,
                visibleColumns: of(['resolverCheck', 'role', 'name', 'refLabel', 'societyCodePr', 'societyCodeMr']),
              },
            },
          ],
        },
      ],
    };
  }

  getClaimantInfoForm(row) {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'name',
            type: 'input',
            templateOptions: {
              attributes: { 'data-testid': 'claimant-name' },
              label: this.translate.instant('CLAIMS.NAME'),
              disabled: true,
            },
          },
          {
            className: 'flex-1',
            key: 'refLabel',
            type: 'input',
            templateOptions: {
              attributes: { 'data-testid': 'ipi-name-number' },
              label: this.translate.instant('CLAIMS.IPI_NAME_NUMBER'),
              disabled: true,
            },
          },
          {
            className: 'flex-1',
            key: 'claimant',
            type: 'select',
            defaultValue: '',
            modelOptions: {
              updateOn: 'blur',
            },
            templateOptions: {
              attributes: { 'data-testid': 'claimant-select' },
              label: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COUNTERCLAIM_RELATION'),
              options: [
                { value: CounterClaimClaimantRelation.NEW, label: 'New Claimant' },
                { value: CounterClaimClaimantRelation.FIRST, label: 'First Claimant' },
              ],
              required: true,
              change: (field, value) => this.claimant$.next({ ...row, claimant: value.value }),
            },
          },
        ],
      },
    ];
  }
}
