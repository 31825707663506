import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import the FormsModule
import { IcePercentageComponent } from './ice-percentage.component';

@NgModule({
  declarations: [IcePercentageComponent],
  imports: [CommonModule, FormsModule], // Add the FormsModule to the imports array
  exports: [IcePercentageComponent],
})
export class IcePercentageModule {}
