import { HAS_PRIORITY_KEY, OVERCLAIM_WITH_COUNTERCLAIM_FLAG_KEY } from 'config/constants/activity.constants';
import { EXPERT_SEARCH_SEPARATOR } from 'config/constants/search.constants';
import { maxBy, toPairs, uniq } from 'lodash';
import { SearchExpression, TerritoryCleaned } from 'models';
import { SEARCH_TYPE_DASHBOARD } from 'config/constants/counter-claims.constants';
import { IGNORE_FIELD_TAG, PREFIX_FIELD_TAG } from 'config/constants/search.constants';
import { RepertoiresSearchExpressions } from 'config/search-form-builders/search-copyright-repertoires';
import { SectionRouterViews, SectionsConfig } from 'config/sections-config';
import { endsWith, isBoolean, isString } from 'lodash';

export class SearchUtils {
  static customReOrder(list: any[], section, term) {
    switch (section) {
      case SectionsConfig.TERRITORIES.name:
        const territories: TerritoryCleaned[] = [];
        list.forEach((item: TerritoryCleaned) => {
          if (term && item.tisA === term.toUpperCase()) {
            territories.unshift(item);
          } else {
            territories.push(item);
          }
        });
        return territories;
      default:
        return list;
    }
  }

  static getSAYTDefaultExtraSearchFields(section) {
    switch (section) {
      case SectionsConfig.IPS.name:
        return { includeInactive: false };
      case SectionsConfig.AGREEMENTS.name:
        return { terminated: false };
      case SectionsConfig.USERS.name:
        return { showInactive: false };
      case SectionsConfig.CONFLICTS.name:
        const data = {};
        data[OVERCLAIM_WITH_COUNTERCLAIM_FLAG_KEY] = true;
        return data;
      default:
        return {};
    }
  }

  static getLastSearchedSection(historys, sections) {
    return (
      (historys &&
        maxBy(
          toPairs(historys)
            .filter(([key, history]) => sections.includes(key))
            .map(([key, history]: [string, any]) => ({ ...history, section: key })),
          (history: any) => history.timestamp,
        )) ||
      ({} as any)
    ).section;
  }

  static getRequiredSearchFields(section, ns?: string, type?: string) {
    switch (section) {
      case SectionsConfig.AGREEMENTCONFLICT.name:
        return { equals: { 'attributes.entityType': 'agreement' } };
      case SectionsConfig.COUNTERCLAIMS_ACTIONS.name:
        return { equals: { 'counterclaim.attributes.entityType': 'work' } };
      case SectionsConfig.IPS.name:
        if (type === 'assignee' || type === 'assignor') {
          return { not: { equals: { 'attributes.typeOfName': 'PG' } } };
        } else {
          return null;
        }
      case SectionsConfig.COUNTERCLAIMS.name:
        if (type === SEARCH_TYPE_DASHBOARD) {
          return {
            and: [{ equals: { 'counterclaim.attributes.entityType': 'work' } }],
          };
        } else {
          return { equals: { 'attributes.entityType': 'work' } };
        }
      case SectionsConfig.ORGANIZATIONS.name:
        return { equals: { 'attributes.ns': ns } };
      default:
        return null;
    }
  }

  static joinRequiredSearchFields(requiredQueryParams, searchObject) {
    return requiredQueryParams
      ? searchObject && searchObject.and
        ? { and: [requiredQueryParams, ...searchObject.and] }
        : searchObject
        ? { and: [requiredQueryParams, searchObject] }
        : { and: [requiredQueryParams] }
      : searchObject || { and: [] };
  }

  static toExpertQuery(object) {
    return toPairs(object)
      .map(([key, value]) => {
        return `${key} =* ${value}`;
      })
      .join(EXPERT_SEARCH_SEPARATOR);
  }

  static formatSAYTPrefixField(section: string, term: string): any {
    switch (section) {
      case 'ips':
        let xrefPrefix = 'ALL';
        if (term.indexOf('#') > -1) {
          if (term.indexOf(':') > -1) {
            const availablePrefixes = ['SO', 'PB', 'IPI', 'ICE'];
            const prefixFragment = term.substring(1).split(':')[0];
            const prefix = availablePrefixes.find(availablePrefix => prefixFragment.startsWith(availablePrefix));
            if (prefix) {
              xrefPrefix = prefix.padEnd(3, '*');
            }
          } else {
            xrefPrefix = 'ICE-IPI';
          }
        }
        return { xrefPrefix };
      case 'counter-claims':
        let idPrefix = null;
        if (term.indexOf('#') > -1) {
          if (term.indexOf(':') > -1) {
            const availablePrefixes = ['ICE', 'CUBE'];
            const prefixFragment = term.substring(1).split(':')[0];
            const prefix = availablePrefixes.find(availablePrefix => prefixFragment.startsWith(availablePrefix));
            if (prefix) {
              idPrefix = prefix.padEnd(3, '*');
            }
          } else {
            idPrefix = 'ICE';
          }
        }
        return { idPrefix };
      default:
        return {};
    }
  }

  static formatAdvancedSearchBooleans(params) {
    const booleanSearchFields = ['terminated', 'showInactive', 'includeInactive', OVERCLAIM_WITH_COUNTERCLAIM_FLAG_KEY, HAS_PRIORITY_KEY, 'active', 'unpublishedWriterShares'];
    const newParams = Object.entries(params).reduce((acc, [key, value]) => {
      acc[key] = booleanSearchFields.includes(key) ? value === 'true' || value === true : value;
      return acc;
    }, {});

    return newParams;
  }

  static formatXrefList(xrefList) {
    return (
      Array.isArray(xrefList) &&
      xrefList.reduce((acc, xref, index) => {
        acc['xref' + index] = xref;
        return acc;
      }, {})
    );
  }

  static formatExactIDSearch(idValue: string) {
    const valueArray = idValue.split(':');
    let saytPrefix = 'ICE:';
    let saytID = idValue;
    if (valueArray.length > 1) {
      saytPrefix = `${valueArray[0]}:`;
      saytID = valueArray[1];
    }
    return saytPrefix + saytID;
  }

  static removeStringFromString(query: string, stringToDelete: string): string {
    const regex = new RegExp(stringToDelete, 'g');
    return query.replace(regex, '');
  }

  static removeQuerySearchRepeatedElements(query: string): string {
    return uniq(query.replace(EXPERT_SEARCH_SEPARATOR.toLowerCase(), EXPERT_SEARCH_SEPARATOR).split(EXPERT_SEARCH_SEPARATOR)).join(EXPERT_SEARCH_SEPARATOR);
  }

  static getSearchTotalLength(data: any, section: string): string {
    let totalLength: string;
    if ([SectionsConfig.TERRITORIES.name, SectionsConfig.CONFLICTS.name].includes(section)) {
      if (SectionsConfig.TERRITORIES.name === section) {
        totalLength = data.territories.length;
      }
      if (SectionsConfig.CONFLICTS.name === section) {
        totalLength = data.items.length;
      }
    } else {
      totalLength = data.length;
    }
    return totalLength;
  }

  static searchQueryWithComparator(paramName: string, value: string) {
    const comparator = value?.includes('*') ? 'wildcard' : 'equals';
    return `{"${comparator}":{"${paramName}":"${value}"}}`;
  }

  static viewHasPermission(view, permissionsService, section?) {
    let hasPermission = true;
    switch (view) {
      case SectionRouterViews.detail:
        hasPermission = permissionsService.can(`${section}_detail_search`);
        break;
      case SectionRouterViews.dashboard:
        hasPermission = false;
        break;
    }

    return hasPermission;
  }

  static generateExpressionCubeData(data: any, searchExpressionsConfig, xrefList?: boolean, allUsers = null): SearchExpression {
    if (searchExpressionsConfig) {
      let fieldsSearchExpressions: string;
      const searchParameters = searchExpressionsConfig.combineFields(data);
      Object.entries(searchParameters).forEach(([key, value]) => {
        if ((value || isBoolean(value)) && !endsWith(key, PREFIX_FIELD_TAG) && !endsWith(key, IGNORE_FIELD_TAG)) {
          let clearValue;
          let fieldPrefix = searchParameters[key + PREFIX_FIELD_TAG] || '';
          if (searchExpressionsConfig instanceof RepertoiresSearchExpressions && key.startsWith('publisher')) {
            fieldPrefix = searchParameters.publisherRole;
          }
          // eslint-disable-next-line @typescript-eslint/quotes
          if (isString(value)) {
            clearValue = (value as string).replace(/"/g, '\\"');
          } else {
            clearValue = value;
          }
          const currentFieldSearchExpression =
            key === 'ns' ? `{"equals":{"attributes.ns":"${value}"}}` : searchExpressionsConfig.getSearchExpressionConfig(key, clearValue, fieldPrefix, allUsers);
          if (currentFieldSearchExpression) {
            // if null nothing to add
            fieldsSearchExpressions = fieldsSearchExpressions ? fieldsSearchExpressions + ',' + currentFieldSearchExpression : currentFieldSearchExpression;
          }
        }
      });
      const extraSearchExpression = searchExpressionsConfig.getExtraSearchExpression(searchParameters);
      fieldsSearchExpressions = fieldsSearchExpressions
        ? `${fieldsSearchExpressions}${extraSearchExpression ? ',' + extraSearchExpression : extraSearchExpression}`
        : extraSearchExpression;
      const expression = xrefList ? 'or' : 'and';
      const result = `{"${expression}":[<<searchs>>]}`.replace(/<<searchs>>/g, fieldsSearchExpressions);
      return JSON.parse(result);
    }
    return {};
  }
}
