import { AgreementDetail, AgreementUtils, IpDetailCleaned, IpUtils, RepertoireDetail, RepertoireUtils, TerritoryDetail, WorkDetail } from '@ice';
import { CloneUtils } from '@ice/utils/clone/clone.utils';
import { CopyrightUtils } from '@ice/utils/copyright/copyright.utils';
import { TabsUtils } from '@ice/utils/tabs/tab.utils';
import { createSelector } from '@ngrx/store';
import { OrganizationType } from 'config/constants/organizations.constants';
import { TerritoryType } from 'config/constants/territories.constants';
import { SectionsConfig } from 'config/sections-config';
import { concat, get, last } from 'lodash';
import { CounterClaimDetail } from 'models/copyright/detail/counterclaim';
import { OrganizationDetail } from 'models/copyright/detail/organizations';
import { SocietyDetail } from 'models/copyright/detail/society';
import { UserCleaned } from 'models/users/users.model';
import * as fromFeature from 'store/root/reducers';
import * as fromReducer from 'store/root/reducers/copyright/copyright.reducer';
import * as fromRouterSelectors from 'store/root/selectors/router/router.selectors';
import { CopyrightItemView, CopyrightState } from 'store/root/state';
import { getForcedNS } from '../../global/global.selectors';
import { getUserManagementOrganization } from '../organizations/organizations.selectors';
import { getUsers } from '../users/users.selectors';
import { getCopyrightWork } from '../works/works.selectors';

export const getCopyrightItemView = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCopyrightItemViewState);

export const getCopyrightItemEditView = createSelector(
  getCopyrightItemView,
  fromRouterSelectors.getRouterPaths,
  fromRouterSelectors.getRouterSection,
  fromFeature.getCopyrightFeatureState,
  // getCopyrightAgreement,
  // getCopyrightRepertoire,
  // getCopyrightAgreementConflict,
  getUserManagementOrganization,
  getUsers,
  fromRouterSelectors.getRouterQueryParams,
  getCopyrightWork,
  (
    itemView: CopyrightItemView,
    paths: string[],
    section: string,
    state: any,
    // agreement: AgreementDetail,
    // repertoire: RepertoireDetail,
    // agreementConflict: AgreementConflict,
    organization: OrganizationDetail,
    users: any,
    queryParams: any,
    work: WorkDetail,
  ) => {
    const { agreements, repertoires, reports } = state;

    const lastPathParam = last(paths);
    if (lastPathParam === 'clone') {
      return CloneUtils.getFormattedCloneItem(state[section], section);
    } else {
      switch (section) {
        case SectionsConfig.AGREEMENTS.name: {
          const cleanedAgreement = agreements?.attributes && AgreementUtils.cleanAgreementForUpdate(agreements);
          return cleanedAgreement;
        }
        case SectionsConfig.AGREEMENTCONFLICT.name: {
          if (paths.includes('new')) {
            return agreements;
          } else {
            return state['agreement-conflict'];
          }
        }
        case SectionsConfig.AGREEMENT_GROUP.name:
          const agreementGroup = state['agreement-group'];
          if (agreementGroup && agreementGroup.hasOwnProperty('attributes') && agreementGroup.hasOwnProperty('partyName')) {
            const { attributes, partyName } = agreementGroup;
            const { id, description } = attributes;
            const attributesPartyName = get(partyName, 'attributes');
            const { relations } = partyName;
            const name = get(attributesPartyName, 'name');
            const partyNameId = get(attributesPartyName, 'id');
            const ipNameKey = relations && IpUtils.selectIpsKey(relations, partyNameId).replace('ICE:', '');
            return { id, description, name, ipNameKey, partyNameId };
          }
          return null;
        case SectionsConfig.REPERTOIRES.name: {
          return (repertoires && repertoires.hasOwnProperty('attributes') && RepertoireUtils.cleanRepertoireForUpdate(repertoires)) || null;
        }
        case SectionsConfig.COUNTERCLAIMS.name: {
          // To get workId and workKey from Work
          return queryParams;
        }
        case SectionsConfig.ORGANIZATIONS.name: {
          if (organization) {
            const { id, attributes } = organization;
            const { name, type, owner, society } = attributes;
            let parentIpNameKey: string;
            let parentIpi: string;
            if (type === OrganizationType.publisher && owner && owner.namespaces && owner.namespaces.length > 0) {
              const namespaces = owner.namespaces;
              parentIpi = namespaces[0] && namespaces[0].replace('PB', '');
              parentIpNameKey = get(organization, 'attributes.access.partyNames[0]');
            }
            return { id, name, type, society, parentIpi, parentIpNameKey };
          }
          break;
        }
        case SectionsConfig.USERS.name: {
          return users;
        }
        case SectionsConfig.WORKS.name:
          {
            if (paths.length > 4 && paths[4] === 'claim') {
              // To get organization editing
              return queryParams;
            }
          }
          break;
        case SectionsConfig.REPORTS.name: {
          return reports;
        }
      }
    }
    return null;
  },
);

export const getCopyrightCurrentDetailTitle = createSelector(
  fromFeature.getCopyrightFeatureState,
  fromRouterSelectors.getRouterSection,
  fromRouterSelectors.getRouterParams,
  CopyrightUtils.setDetailTitle,
);

export const getCopyrightDialogSearchData = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCopyrightDialogSearchData);

export const getCopyrightNotes = createSelector(fromFeature.getCopyrightFeatureState, fromRouterSelectors.getRouterSection, (copyright, section) => {
  const cs = copyright[section];
  return cs && cs['notes'] ? cs['notes'] : [];
});

export const getSectionAllowedActions = createSelector(fromRouterSelectors.getRouterSection, section => {
  const sectionConfig = CopyrightUtils.getSectionData(section);
  return sectionConfig && sectionConfig.allowedActions;
});

export const getCopyrightDetailBySection = createSelector(
  fromFeature.getCopyrightFeatureState,
  fromRouterSelectors.getRouterSection,
  (copyrightState: CopyrightState, section: string) => {
    if (copyrightState && copyrightState[section]) {
      return copyrightState[section];
    }
    return null;
  },
);

export const getDummyIp = createSelector(fromFeature.getCopyrightFeatureState, copyright => copyright.dummyIp);

export const getCopyrightBySectionId = createSelector(getCopyrightDetailBySection, fromRouterSelectors.getRouterSection, (detailBySection: any, section: string) => {
  if (section && detailBySection) {
    switch (section) {
      case SectionsConfig.WORKS.name: {
        const work: WorkDetail = detailBySection;
        return get(work, 'attributes.key', null);
      }
      case SectionsConfig.IPS.name: {
        const ip: IpDetailCleaned = detailBySection;
        return get(ip, 'attributes.key', null);
      }
      case SectionsConfig.AGREEMENTS.name: {
        const agreement: AgreementDetail = detailBySection;
        return get(agreement, 'attributes.key', null);
      }
      case SectionsConfig.AGREEMENT_GROUP.name: {
        const agreementGroup: any = detailBySection;
        return get(agreementGroup, 'attributes.id', null);
      }
      case SectionsConfig.REPERTOIRES.name: {
        const repertoire: RepertoireDetail = detailBySection;
        return get(repertoire, 'attributes.key', null);
      }
      case SectionsConfig.SOCIETIES.name: {
        const society: SocietyDetail = detailBySection;
        return society.id || null;
      }
      case SectionsConfig.TERRITORIES.name: {
        const territory: TerritoryDetail = detailBySection;
        return get(territory, 'territories[0].tisn', null);
      }
      case SectionsConfig.ORGANIZATIONS.name: {
        const organization: OrganizationDetail = detailBySection;
        return organization.id || null;
      }
      case SectionsConfig.USERS.name: {
        const users: UserCleaned = detailBySection;
        return users.id || null;
      }
      case SectionsConfig.COUNTERCLAIMS.name: {
        const counterclaim: CounterClaimDetail = detailBySection;
        return counterclaim.id || null;
      }
    }
  }
  return null;
});

export const getCreationMode = createSelector(fromFeature.getCopyrightFeatureState, state => state && state.createMode && state.createMode.editing);

export const getEditMode = createSelector(fromFeature.getCopyrightFeatureState, state => state.editMode && state.editMode.editing);

export const getEditModeState = createSelector(fromFeature.getCopyrightFeatureState, state => state.editMode);

export const getExportMode = createSelector(fromFeature.getCopyrightFeatureState, state => state.exportMode);

export const getSelectMode = createSelector(fromFeature.getCopyrightFeatureState, state => state.selectionMode);

export const getUpdateMode = createSelector(fromFeature.getCopyrightFeatureState, state => state.updateMode);

export const isDownloading = createSelector(getExportMode, exportMode => exportMode && exportMode.downloading);

export const getEditingId = createSelector(fromFeature.getCopyrightFeatureState, state => state.editMode && state.editMode.editId);

export const getCreationId = createSelector(fromFeature.getCopyrightFeatureState, state => state.createMode && state.createMode.editId);

export const getCreationMultipleNamespaces = createSelector(fromFeature.getCopyrightFeatureState, state => state?.createMode?.multipleNamespaces);

export const getCreationName = createSelector(
  fromFeature.getCopyrightFeatureState,
  state =>
    (state.createMode && state.createMode.editName) ||
    (state.createMode && state.createMode.multipleNamespaces && state.createMode.multipleNamespaces.map(editor => editor.editName).join(', ')) ||
    null,
);

export const getEditingName = createSelector(
  fromFeature.getCopyrightFeatureState,
  state =>
    (state.editMode && state.editMode.editName) ||
    (state.editMode && state.editMode.multipleNamespaces && state.editMode.multipleNamespaces.map(editor => editor.editName).join(', ')) ||
    null,
);

export const getEditingMultipleNamespaces = createSelector(fromFeature.getCopyrightFeatureState, state => state.editMode && state.editMode.multipleNamespaces);

export const getCopyrightLoading = createSelector(fromFeature.getCopyrightFeatureState, state => state && state.loading);

export const getSectionItemFieldsWithSection = createSelector(fromFeature.getCopyrightFeatureState, fromRouterSelectors.getRouterSection, (fields, section) => ({
  fields,
  section,
}));

export const getCopyrightResponse = createSelector(fromFeature.getCopyrightFeatureState, state => state && state.response);

export const getCopyrightIsPopupOkButtonMarked = createSelector(fromFeature.getCopyrightFeatureState, state => state.isPopupOkButtonMarked);

export const getCopyrightPermissionsBySection = createSelector(
  fromFeature.getCopyrightFeatureState,
  fromRouterSelectors.getRouterSection,
  (copyrightState: CopyrightState, section: string) => {
    if (copyrightState && copyrightState[section]) {
      return copyrightState[section].permissions;
    }
    return null;
  },
);

export const getTabHeaderConfig = createSelector(getCopyrightDetailBySection, fromRouterSelectors.getRouterSection, getForcedNS, (detail, section, forcedNS) =>
  TabsUtils.getTabHeaderConfig({ section, detail, forcedNS }),
);

export const getIsDeletingItem = createSelector(fromFeature.getCopyrightFeatureState, state => (state.deleteItem && state.deleteItem.deleting) || false);

export const getDeleteItemError = createSelector(fromFeature.getCopyrightFeatureState, state => (state.deleteItem && state.deleteItem.error ? [state.deleteItem.error] : null));

export const getDeleteItemMsg = createSelector(fromFeature.getCopyrightFeatureState, state => (state.deleteItem && state.deleteItem.msg ? state.deleteItem.msg : null));

export const getAuditHistoryFilter = createSelector(
  fromFeature.getCopyrightFeatureState,
  fromRouterSelectors.getRouterSection,
  (copyrightState: CopyrightState, section: string) => (copyrightState && copyrightState[section]?.auditHistoryFilter) || {},
);

export const getWorkRelations = createSelector(getCopyrightWork, (workDetail: WorkDetail) => {
  const { workInternalRelations, workExternalRelations } = workDetail || {};
  return concat(
    (workExternalRelations?.items || []).filter(relation => !(relation.modified && relation.modified === 'deleted') && relation.id !== workDetail.id),
    workInternalRelations?.items || [],
  );
});

export const getWorkDetailId = createSelector(getCopyrightWork, (workDetail: WorkDetail) => workDetail?.id);
export const getWorkDetailKey = createSelector(getCopyrightWork, (workDetail: WorkDetail) => workDetail?.attributes?.key);

export const getScrollBySection = createSelector(fromFeature.getCopyrightFeatureState, fromRouterSelectors.getRouterSection, (copyright, section) => {
  return copyright && copyright.scroll && copyright.scroll[section];
});

export const getCopyrightDashboard = createSelector(fromFeature.getCopyrightFeatureState, fromRouterSelectors.getRouterSection, (copyright, section) => {
  const cs = copyright[section];
  return (cs && cs['dashboard']) || {};
});

export const getCopyrightAuditEventTypes = createSelector(fromFeature.getCopyrightFeatureState, fromRouterSelectors.getRouterSection, (copyright, section) => {
  const cs = copyright[section];
  return cs?.eventTypes || [];
});

export const getCopyrightAuditEventDescriptions = createSelector(fromFeature.getCopyrightFeatureState, fromRouterSelectors.getRouterSection, (copyright, section) => {
  const cs = copyright[section];
  return cs?.eventDescriptions || [];
});

export const getMergeIpMode = createSelector(fromFeature.getCopyrightFeatureState, state => state.mergeIpMode);

export const getMergeIpItems = createSelector(fromFeature.getCopyrightFeatureState, state => state.mergeIpItems);

export const getAllUsers = createSelector(fromFeature.getCopyrightFeatureState, state => state.allUsers);

export const getAllApiData = createSelector(fromFeature.getCopyrightFeatureState, state => state.allApiData);

export const getTransferIpWorksMode = createSelector(fromFeature.getCopyrightFeatureState, state => state.transferIpWorksMode);

export const getAllTerritories = createSelector(fromFeature.getCopyrightFeatureState, state => state.allTerritories);

export const getIceCountries = createSelector(getAllTerritories, allTerritories =>
  allTerritories?.filter(territory => territory?.iceTerritory && territory?.territoryType === TerritoryType.COUNTRY),
);

export const getOtherCountries = createSelector(getAllTerritories, allTerritories =>
  allTerritories?.filter(territory => !territory?.iceTerritory && territory?.territoryType === TerritoryType.COUNTRY),
);

export const getSelectionDatatableSelectableEnabled = createSelector(fromFeature.getCopyrightFeatureState, state => state.selectionDatatableSelectableEnabled);
