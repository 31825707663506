import { WorkDetail, WorkRelation } from '@ice';
import { AGREEMENT_XREF_LABEL, AGREEMENT_XREF_TYPE } from 'config/constants/agreements.constants';
import { ICE } from 'config/constants/global.constants';
import { RELATION_ORIGIN, XREFALLOWED } from 'config/constants/relation.constants';
import { ISWC } from 'config/constants/works.constants';
import { get, includes, isEmpty, last, padStart, remove, uniqBy } from 'lodash';
import { PageableData } from 'models/response/cube-register.response';
import { CopyrightUtils, WorkUtils } from '..';
import { SocietiesUtils } from '../societies/societies.utils';

export class RelationsUtils {
  static selectRelationByTypeAndPrefix(relations = [], relationTypes: string[], prefixes: string[]): string {
    // Example: selectRelationByTypeAndPrefix(relations, ['DISP', 'XREF'], ['ICE', IPI']);
    let key = '';
    relationTypes.some(type => {
      const typeRelations = type !== '*' ? relations.filter(relation => relation.relation === type) : relations;
      prefixes.some(prefix => {
        const prefixRelation = typeRelations.find(relation => get(relation, 'otherId').split(':')[0] === prefix);
        if (prefixRelation) {
          key = prefixRelation.otherId;
          return true;
        }
      });
      if (key !== '') {
        return true;
      }
    });
    return key;
  }

  static getKeySuffixFromRelation(relation): string {
    return CopyrightUtils.getKeySuffix(relation && relation.otherId);
  }

  static sortXrefISWC(xrefA, xrefB): number {
    if (includes(xrefA.value, ISWC)) {
      return -1;
    } else if (includes(xrefB.value, ISWC)) {
      return 1;
    }
    return 0;
  }

  static sortXrefICE(xrefA, xrefB): number {
    if (includes(xrefA.value, ICE)) {
      return -1;
    } else if (includes(xrefB.value, ICE)) {
      return 1;
    }
    return 0;
  }

  static getLabeledValueFromXref(xref) {
    const splited = xref.value.split(':');
    return { ...xref, label: splited.length > 1 ? splited[0] : '', labelTooltip: RelationsUtils.getXrefTypeFromValue(splited[0]) };
  }

  static getSortedLabeledXrefs(xrefs) {
    if (xrefs && xrefs.length > 0) {
      const xrefSortedICE = xrefs.sort(RelationsUtils.sortXrefICE);
      const xrefSortedISCW = xrefSortedICE.sort(RelationsUtils.sortXrefISWC);
      return xrefSortedISCW.map(RelationsUtils.getLabeledValueFromXref);
    }
    return xrefs;
  }

  static getWorkIdFromRelations(relations): string {
    if (relations && relations.length && relations.length > 0) {
      const relation = relations.find(rel => rel.otherId.indexOf('ICE') === 0 && rel.relation === 'DISP');
      return (relation && relation.otherId) || '';
    }
    return '';
  }

  static getIPIFromRelations(relations: WorkRelation[]): string {
    if (relations && relations.length && relations.length > 0) {
      const ipiNameNumber = relations.find(relation => relation?.otherId?.indexOf('IPI') === 0 && relation.relation === 'XREF');
      return ipiNameNumber ? CopyrightUtils.getKeySuffix(ipiNameNumber.otherId) : '';
    }
    return '';
  }

  static getICEFromRelations(relations: WorkRelation[]): string {
    if (relations && relations.length && relations.length > 0) {
      const iceKey = relations.find(relation => relation?.otherId?.indexOf('ICE') === 0 && relation.relation === 'XREF');
      return iceKey ? CopyrightUtils.getKeySuffix(iceKey.otherId) : '';
    }
    return '';
  }

  static getAgreementXREF(relations) {
    return relations.map(agreementRelation => {
      let label: string;
      let value: string;
      const { groupId, otherId, relation, type } = agreementRelation;

      if (type === AGREEMENT_XREF_TYPE.GROUP) {
        label = AGREEMENT_XREF_LABEL.GROUP;
        value = groupId;
      } else {
        if (otherId.includes('SAREF:')) {
          label = AGREEMENT_XREF_LABEL.SOCIETY;
          value = otherId.replace('SAREF:', '');
          const codeTemp = padStart(value.split(':')[0].replace('SO', ''), 3, '0');
          const idTemp = value.split(':')[1];
          const society = SocietiesUtils.getRelationSocietiesName(codeTemp);
          value = `${society.code}:${idTemp}`;
        } else if (otherId.includes('PAREF:')) {
          label = AGREEMENT_XREF_LABEL.PUBLISHER;
          value = otherId.replace('PAREF:', '');
        } else {
          const countSplit = otherId.split(':');
          label = otherId.split(':')[0];
          let idTemp;
          let xrefTemp;
          if (countSplit.length >= 3) {
            idTemp = otherId.split(':')[1];
            xrefTemp = last(otherId.split(':'));
            value = `${idTemp}:${xrefTemp}`;
          } else {
            label = otherId.split(':')[0];
            value = last(otherId.split(':'));
          }
        }
      }

      return { label, value, groupId, otherId, relation, type };
    });
  }

  static getKeyWithICE(key) {
    return !key || key.includes('ICE:') ? key : `ICE:${key}`;
  }

  static getAllRelationsByTypeAndPrefix(relations = [], relationTypes: string[], prefixes: string[]): WorkRelation[] {
    // Example: getAllRelationsByTypeAndPrefix(relations, ['DISP', 'XREF'], ['ICE', IPI']);
    let workRelations = [];
    relationTypes.forEach(type => {
      const typeRelations = type !== '*' ? relations.filter(relation => relation.relation === type) : relations;
      prefixes.forEach(prefix => {
        workRelations = typeRelations.filter(relation => get(relation, 'otherId').split(':')[0] === prefix);
      });
    });
    return workRelations;
  }

  static addWorkRelation(workDetail: WorkDetail, workRelationData: any, relationType: string, intExt: string, relationTypeDisplay: string) {
    const isExternal = intExt === RELATION_ORIGIN.EXTERNAL;
    const relationsObjectName = isExternal ? 'workExternalRelations' : 'workInternalRelations';
    let newRelation = WorkUtils.workSearchCleaner([workRelationData], relationType, isExternal, relationTypeDisplay, null)[0];
    newRelation = { ...newRelation, intExt };
    if (isExternal) {
      newRelation.modified = 'added';
    }
    const relationsObject: PageableData<any> = get(workDetail, `${relationsObjectName}`);
    const relations = relationsObject?.items || [];
    if (!isEmpty(relations)) {
      remove(relations, (work: any) => work.id === newRelation.id && work.relationType === newRelation.relationType);
    }
    const uniqRelations = uniqBy([...relations, newRelation], work => `${work.id}${work.relationType}`);
    const workState = { ...workDetail };
    workState[`${relationsObjectName}`] = { ...relationsObject, items: uniqRelations };
    return workState;
  }

  static getXrefTypeFromValue(valueToSearch) {
    if (XREFALLOWED.includes(valueToSearch)) {
      return 'WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.' + valueToSearch;
    } else {
      return '';
    }
  }

  static getXrefTypeFromValueCounterClaim(valueToSearch, translate) {
    if (XREFALLOWED.includes(valueToSearch)) {
      return translate.instant(`AGREEMENTS.CROSS_REFERENCES.XREF_TYPES.${valueToSearch}`);
    } else {
      return '';
    }
  }
}
