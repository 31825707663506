import { USER_STATE_INACTIVE } from 'config/constants/users.constants';
import { environment } from 'config/env';
import { SectionsConfig } from 'config/sections-config';
import { RequestType } from 'config/sections-config/api-call';
import {
  createApiCall,
  createApiCallFromBase,
  fetchAllData,
  isNOTPageable,
  isPageable,
  isResponseHandler,
  setBody,
  setCheckStatus,
  setInclude,
  setInitialBody,
  setInitialBodyArrayParameter,
  setLabelResolver,
  setPageable,
  setQueryParams,
  setResultCleaner,
  validateLabels,
} from '../api-call.factory';
import * as fromLabelResolvers from './common.label-resolvers';
import * as fromResultCleaners from './common.result-cleaners';

export const getClaimGraphBase = createApiCall(
  `${environment.apiUrlCubeData}/all-claims/${environment.apiNamespace}/<<id>>`,
  RequestType.GET,
  setInclude(
    `claims.attributes{share, parentId, role, territories, usageStartDate, usageEndDate, distributionStartDate, distributionEndDate, inDispute, id, rightTypes, status, tags,
      claimantPartyNameId},
    claims.claimant.party.societies.society.attributes, attributes,
    claims.claimant.partyName.attributes{firstName, name}, claims.claimant.partyName.relations[XREF], <<filter>>, claims.claimant.party.societies, claims.claimant.party.relations,
    claims.claimant.party.attributes`,
  ),
  validateLabels,
);

export const getActionReduceClaimsApiCall = createApiCall(`${environment.apiUrlCubeData}/actions/claimgraphs/<<actionId>>`, RequestType.GET, isResponseHandler);

export const transferAgreementWorksApiCall = createApiCall(
  `${environment.apiUrlCubeData}/cascade/agreements/<<agreementId>>/parties/<<userId>>/workslist/<<requestType>>?type=<<workType>>`,
  RequestType.POST,
  setBody(`{"sourceAgreementId": "<<sourceAgreementId>>"}`),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.transferAgreementWorksApiCall),
  isResponseHandler,
);

export const getIpRelationshipData = createApiCall(
  `${environment.apiUrlCubeData}/party-names/ICE/<<id>>`,
  RequestType.GET,
  setInclude(
    `parties.relations, attributes{id,name,firstName, typeOfName}, relations{relation,otherId},
    parties.party.relations[XREF]{relation,otherId}, parties.party{relations, societies}.societies{societyId,society,memberships}, parties.party.attributes{typeOf}`,
  ),
  setQueryParams({ followRedirects: false }),
  isPageable,
  setResultCleaner([fromResultCleaners.setRelationshipPropertyCleaner, fromResultCleaners.setIpRelationshipResultCleaner]),
);

export const fetchAgreementWorksUploadURL = createApiCall(
  `${environment.apiUrlCubeData}/agreements/<<ns>>/<<agreementId>>/worklist/presignurl`,
  RequestType.GET,
  setQueryParams({
    type: '<<type>>',
  }),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.fetchAgreementWorksUploadURLLabelResolver),
  isResponseHandler,
);

export const updateCounterclaim = createApiCall(
  `${environment.apiUrlCubeData}/counterclaims/actions/<<counterclaimId>>`,
  RequestType.POST,
  setLabelResolver(SectionsConfig.COUNTERCLAIMS.name, fromLabelResolvers.updateCounterclaimLabelResolver),
  isResponseHandler,
);

export const getClaimGraphCounterClaim = createApiCallFromBase(
  getClaimGraphBase,
  setLabelResolver(SectionsConfig.COUNTERCLAIMS.name, fromLabelResolvers.getClaimGraphCounterclaimLabelResolver),
  setResultCleaner(['workClaims', fromResultCleaners.getClaimGraphResultCleaner]),
);

export const getWorksToAdd = createApiCall(
  `${environment.apiUrlCubeData}/${SectionsConfig.WORKS.name}/<<ns>>/search`,
  RequestType.POST,
  setInclude(
    `attributes{ id, genre, publicationDate, source, owner, status, duration, language, titles },
    contributions{contributor, role}.contributor{partyName}.partyName{attributes}.attributes{name, firstName}, relations`,
  ),
  setBody(`{"and":[{"wildcard":{"attributes.titles.*.titleValue":"*****"}}]}`),
  setResultCleaner(['searchWorks', fromResultCleaners.getWorksToAddResultCleaner]),
  isPageable,
);

export const deleteAgreementWorks = createApiCall(
  `${environment.apiUrlCubeData}/agreementapplications/work/ICE/<<applicationId>>/<<agreementId>>/<<workId>>`,
  RequestType.DELETE,
  isResponseHandler,
);

export const editAgreementWorks = createApiCall(
  `${environment.apiUrlCubeData}/agreementapplications/ICE/agreement/register/<<agreementId>>/<<workId>>`,
  RequestType.PUT,
  setQueryParams({
    force: true,
  }),
  setCheckStatus('agreementapplications', 'register'),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.editAgreementWorksLabelResolver),
  isResponseHandler,
);

export const getWriterPublisherDetails = createApiCall(
  `${environment.apiUrlCubeData}/postgres/writer_publisher_details/search`,
  RequestType.POST,
  setBody(`{ "and": [ { "equals": { "writerId": "<<writerId>>" } }, { "equals": { "publisherId": "<<publisherId>>" }}]}`),
  isResponseHandler,
);

export const getPublisherWriterDetails = createApiCall(
  `${environment.apiUrlCubeData}/postgres/publisher_writer_details/search`,
  RequestType.POST,
  setBody(`{ "and": [ { "equals": { "writerId": "<<writerId>>" } }, { "equals": { "publisherId": "<<publisherId>>" }}]}`),
  isResponseHandler,
);

export const getEventTypes = createApiCall(
  `${environment.apiUrlCubeData}/cube-audits/event-types`,
  RequestType.GET,
  setResultCleaner(['eventTypes', fromResultCleaners.getEventTypesResultCleaner]),
);
const auditHistoryBody = `
{
  "or": [
    { "and":
      [
        {"wildcard": { "attributes.typeId": "*_<<id>>_*" }},
        <<auditTypes>>
      ]
    }
    <<pattern>>
  ]
}`;

export const getEventDescriptions = createApiCall(
  `${environment.apiUrlCubeData}/distinct/search?index=cube-audit&field=events.nameId`,
  RequestType.POST,
  setInitialBody(auditHistoryBody),
  setInitialBodyArrayParameter('auditTypes', `{"equals": {"attributes.type": "<<model>>"}}`),
  setBody(`<<lastFilter>>`),
  setLabelResolver(null, fromLabelResolvers.getAuditHistoryLabelResolver, fromLabelResolvers.getAuditHistoryWorkCounterClaimsLabelResolver),
  setResultCleaner(['eventDescriptions', fromResultCleaners.getEventDescriptionsResultCleaner]),
);

export const getAuditHistory = createApiCall(
  `${environment.apiUrlCubeData}/cube-audits/<<ns>>/search`,
  RequestType.POST,
  setInclude('admin,admin.attributes,attributes,events.attributes'),
  setQueryParams({
    sort: 'attributes.createdDate:desc',
  }),
  isPageable,
  setInitialBody(auditHistoryBody),
  setInitialBodyArrayParameter('auditTypes', `{"equals": {"attributes.type": "<<model>>"}}`),
  setBody(`<<lastFilter>>`),
  setLabelResolver(
    null,
    fromLabelResolvers.getAuditHistoryLabelResolver,
    fromLabelResolvers.getAuditHistoryWorkCounterClaimsLabelResolver,
    fromLabelResolvers.getAgreementsGroupLabelResolver,
  ),
  setResultCleaner(['auditHistory', fromResultCleaners.getAuditHistoryResultCleaner]),
);

const auditFullHistoryBody = `
{
  "or": [
    { "and":
      [
        {"wildcard": { "attributes.typeId": "*<<id>>*" }},
        {
          "equals": {
              "attributes.type": "party"
          }
        }
      ]
    },
    { "and": 
      [
        {
          "or": [
            <<partyNames>>
          ]
        },
        {
            "equals": {
                "attributes.type": "party-name"
            }
        }
      ]
    }
    <<pattern>>
  ]
}`;

export const getAllPartiesAuditHistory = createApiCall(
  `${environment.apiUrlCubeData}/cube-audits/*/search`,
  RequestType.POST,
  setInclude('admin,admin.attributes,attributes,events.attributes'),
  setQueryParams({
    sort: 'attributes.createdDate:desc',
  }),
  isPageable,
  setInitialBody(auditFullHistoryBody),
  setInitialBodyArrayParameter('auditTypes', `{"equals": {"attributes.type": "<<model>>"}}`),
  setBody(`<<lastFilter>>`),
  setLabelResolver(
    null,
    fromLabelResolvers.getAuditHistoryIPILabelResolver,
    fromLabelResolvers.getPartyNamesLabelResolver,
    fromLabelResolvers.getFullAuditHistoryLabelResolver,
    fromLabelResolvers.getAuditHistoryWorkCounterClaimsLabelResolver,
    fromLabelResolvers.getAgreementsGroupLabelResolver,
  ),
  setResultCleaner(['auditHistory', fromResultCleaners.getAuditHistoryResultCleaner]),
);

export const getIpsEventDescriptions = createApiCall(
  `${environment.apiUrlCubeData}/distinct/search?index=cube-audit&field=events.nameId`,
  RequestType.POST,
  setInitialBody(auditFullHistoryBody),
  setInitialBodyArrayParameter('auditTypes', `{"equals": {"attributes.type": "<<model>>"}}`),
  setBody(`<<lastFilter>>`),
  setLabelResolver(
    null,
    fromLabelResolvers.getAuditHistoryIPILabelResolver,
    fromLabelResolvers.getPartyNamesLabelResolver,
    fromLabelResolvers.getFullAuditHistoryLabelResolver,
    fromLabelResolvers.getAuditHistoryWorkCounterClaimsLabelResolver,
    fromLabelResolvers.getAgreementsGroupLabelResolver,
  ),
  setResultCleaner(['eventDescriptions', fromResultCleaners.getEventDescriptionsResultCleaner]),
);

export const getAuditHistoryFilterStartDate = createApiCall(
  `${environment.apiUrlCubeData}/cube-audits/*/search`,
  RequestType.POST,
  setQueryParams({
    sort: 'attributes.createdDate:asc',
  }),
  setQueryParams({ size: 1 }),
  isNOTPageable,
  setResultCleaner(['auditHistoryFilter', fromResultCleaners.getAuditHistoryFilterStartDateResultCleaner]),
);

export const getAuditHistoryFilterEndDate = createApiCall(
  `${environment.apiUrlCubeData}/cube-audits/*/search`,
  RequestType.POST,
  setQueryParams({
    sort: 'attributes.createdDate:desc',
  }),
  setQueryParams({ size: 1 }),
  isNOTPageable,
  setResultCleaner(['auditHistoryFilter', fromResultCleaners.getAuditHistoryFilterEndDateResultCleaner]),
);

export const searchConflictsById = createApiCall(
  `${environment.apiUrlCubeData}/${SectionsConfig.CONFLICTS.apiSegment}/search`,
  RequestType.POST,
  isPageable,
  setBody(`{"and":[{"equals":{"conflictId":"<<id>>"}}]}`),
);

export const getConflictsById = createApiCall(`${environment.apiUrlCubeData}/${SectionsConfig.WORKS.name}/<<ns>>/<<id>>`, RequestType.GET, setInclude(`conflicts`), validateLabels);

export const searchUser = createApiCall(
  `${environment.apiUrlCubeData}/${SectionsConfig.USERS.apiSegment}/<<ns>>/search`,
  RequestType.POST,
  fetchAllData,
  setPageable(0, 100),
  setInclude('attributes{firstName,lastName}'),
  setBody(`{"and":[{"or":[{"wildcard":{"attributes.firstName": "*<<searchText>>*"}}, {"wildcard":{"attributes.lastName": "*<<searchText>>*"}}]},
                   {"not":{"equals":{"attributes.status": "${USER_STATE_INACTIVE}"}}}]}`),
);

export const getAuditEventNoteApiCall = createApiCall(`${environment.apiUrlCubeData}/admin/CUBE/cube-audits/<<id>>?include=attributes`, RequestType.GET);
export const getAuditEventsUsersApiCall = createApiCall(
  `${environment.apiUrlCubeData}/distinct/search?index=cube-audit&field=attributes.userid`,
  RequestType.POST,
  setBody(`{"and":[{"wildcard":{"attributes.typeId": "*<<id>>*"}}]}`),
);

export const getAuditHistoryCounterClaims = createApiCall(
  `${environment.apiUrlCubeData}/cube-audits/<<ns>>/search`,
  RequestType.POST,
  setBody('{"or":[<<pattern>>]}'),
  setInclude('admin,attributes,events.attributes'),
  setLabelResolver(null, fromLabelResolvers.getAuditHistoryCounterClaimsLabelResolver),
  setResultCleaner(['auditHistory', fromResultCleaners.getAuditHistoryResultCleaner]),
  validateLabels,
);
