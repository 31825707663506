import { DataTableRow } from '@ice/components/data-table/data-table';
import { GENERIC_IP_NAME_KEY_WITHOUT_ICE } from 'config/constants/agreements.constants';
import { IpNature } from 'config/constants/ips.constants';
import { REPERTOIRE_TYPES } from 'config/constants/repertoires.constants';
import { TABS_WITH_TOOLTIPS } from 'config/constants/works.constants';
import { sections } from 'config/sections-config/sections';
import { concat, flatten, get, has, isArray, sum } from 'lodash';
import { AgreementDetail, IpDetailCleaned, RepertoireDetail, WorkDetail } from 'models';
import { AgreementGroup } from 'models/copyright/detail/agreement-group';
import { CounterClaimDetail } from 'models/copyright/detail/counterclaim';
import { TabHeaderConfigMap } from 'models/copyright/detail/tab';
import { FeatureFlagService } from 'services/feature-flags/feature-flags-flagsmith.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { WorkUtils } from '..';
import { hasItems } from '../hasItems';
import { NotesUtils } from '../notes/notes.utils';

export class TabsUtils {
  static getTabHeaderConfig({ section, detail, forcedNS }: { section: string; detail: any; forcedNS: boolean }): TabHeaderConfigMap {
    let sectionTabHeaderConfig: TabHeaderConfigMap;
    if (detail) {
      switch (section) {
        case 'works': {
          const item: WorkDetail = detail;
          const {
            attributes,
            partyNames,
            sourceWorks,
            clauses,
            allClaims,
            claimsCount,
            conflictsCount,
            unresolvedCounterclaimsCount,
            agreementsCount,
            societies,
            auditHistory,
            activity,
            userHasInterestInWork,
          } = item;
          const getExtendedToP = WorkUtils.getExtendedToP(item);
          sectionTabHeaderConfig = {
            details: { bold: true },
            'audit-history': { bold: auditHistory?.total > 0, isVisible: !forcedNS && !!userHasInterestInWork },
            agreements: { bold: agreementsCount > 0, isVisible: !forcedNS },
            'other-parties': { bold: !!partyNames?.filter(partyName => !!partyName.role).length },
            instruments: { bold: !!attributes?.instruments?.length || !!attributes?.standardInstrumentation?.length },
            'society-attributes': { bold: !!societies?.length },
            'source-works': { bold: !!sourceWorks?.items?.length, isVisible: !forcedNS },
            'work-clauses': { bold: !!clauses?.length },
            'extended-top': { bold: !!getExtendedToP?.length },
            'all-claims': { bold: !!allClaims?.length, isVisible: !forcedNS },
            'conflicts-cc': { bold: conflictsCount > 0 || unresolvedCounterclaimsCount > 0, isVisible: !forcedNS },
            'edit-claim': {
              defaultHideOnEditMode: true,
              isVisible: detail && get(detail, 'editingClaim', false) && (get(detail, 'editConflictId', false) || get(detail, 'editClaim', false)),
            },
            'work-claims': { bold: claimsCount > 0 },
            repertoires: { bold: true, isVisible: !forcedNS },
            'work-activity': { bold: activity?.length > 0, isVisible: !forcedNS },
          };
          break;
        }
        case 'ips': {
          const item: IpDetailCleaned = detail;
          const {
            partyRelationsPublishers,
            partyRelationsSocieties,
            attributes,
            parties,
            childrenRelationshipsData,
            parentRelationshipsData,
            linkedWritersCount,
            linkedPublishersCount,
            auditHistory,
            workClausesCount,
            agreementChains,
          } = item;
          const { clauses } = attributes || { clauses: null };
          const countClauses = sum([clauses?.length || 0, workClausesCount || 0]);
          const hasXrefs = (partyRelationsSocieties && partyRelationsSocieties.length > 0) || (partyRelationsPublishers && partyRelationsPublishers.length > 0);
          const hasAlternativeNames = has(attributes, 'names[0]');
          const parentAndChildrenRelationsVisible = concat(parentRelationshipsData?.items || [], childrenRelationshipsData?.items || []).filter(
            relationItem => !relationItem.deleted,
          );
          const ipBaseType = get(item, 'parties[0].party.attributes.typeOf');

          sectionTabHeaderConfig = {
            details: { bold: true },
            'audit-history': { bold: auditHistory && auditHistory.total > 0 },
            relations: {
              forceHidden: !parties || !parties.length || ipBaseType !== IpNature.LEGAL_ENTITY,
              bold: (parentAndChildrenRelationsVisible?.length || 0) > 0,
            },
            xref: {
              bold: hasXrefs,
            },
            works: { count: item?.works?.total || 0 },
            'alternative-names': { bold: hasAlternativeNames },
            agreements: { count: item?.agreements?.total || 0 },
            clauses: {
              bold: (countClauses || 0) > 0,
            },
            'linked-publishers': {
              openNewTab: false,
              forceHidden: ipBaseType !== IpNature.NATURAL_PERSON,
              bold: (linkedPublishersCount || 0) > 0,
            },
            'linked-writers': {
              openNewTab: false,
              forceHidden: ipBaseType !== IpNature.LEGAL_ENTITY,
              bold: (linkedWritersCount || 0) > 0,
            },
            'agreement-chains': {
              bold: agreementChains?.ids?.length > 0,
            },
          };
          break;
        }
        case 'agreements': {
          const agreementDetail: AgreementDetail = detail;
          const assignorIpNameKey = get(agreementDetail, 'assignor.ipNameKey');
          const { attributes, excludedWorksCount, includedWorks, linkedWritersCount, auditHistory, writers, xrefs } = agreementDetail;
          sectionTabHeaderConfig = {
            details: { bold: true },
            'audit-history': { bold: auditHistory && auditHistory.total > 0 },
            'linked-writers': { isVisible: assignorIpNameKey === GENERIC_IP_NAME_KEY_WITHOUT_ICE, count: linkedWritersCount },
            'included-works': { count: includedWorks?.total },
            'excluded-works': { isVisible: attributes?.agreementType === 'G', count: excludedWorksCount },
            'cross-references': { count: xrefs?.total || 0 },
            writers: { count: writers?.total || 0 },
          };
          break;
        }
        case 'repertoires': {
          const item: RepertoireDetail = detail;
          const { additionalCollectors, attributes, contractingCompanies, societies, works } = item;
          const exclusions = (attributes && attributes.exclude) || {};
          const hasExcludedAgreements = isArray(exclusions?.agreementIds) && exclusions.agreementIds.length > 0;
          const hasExcludedWorks = isArray(exclusions?.workIds) && exclusions.workIds.length > 0;
          sectionTabHeaderConfig = {
            details: { bold: true },
            'included-works': { count: works ? hasItems(works) && works.length : 0 },
            'excluded-agreements': { count: hasExcludedAgreements && exclusions.agreementIds.length },
            'excluded-works': { count: hasExcludedWorks && exclusions.workIds.length },
            'additional-collectors': {
              isVisible: attributes?.type === REPERTOIRE_TYPES.PUBLISHER,
              bold: additionalCollectors?.length > 0,
              count: additionalCollectors?.length,
            },
            'contracting-companies': {
              isVisible: attributes?.type === REPERTOIRE_TYPES.PUBLISHER,
              bold: contractingCompanies && contractingCompanies.length > 0,
              count: contractingCompanies && contractingCompanies.length,
            },
            'creator-affiliations': { count: societies && societies.length },
            'right-types': { bold: get(attributes, 'rights', []).length > 0 },
          };
          break;
        }
        case 'activity': {
          sectionTabHeaderConfig = {
            analysis: { isVisible: false, bold: true },
            'analysis-merge': { isVisible: false, bold: true },
          };
          break;
        }
        case 'agreement-group': {
          const item: AgreementGroup = detail;
          const { auditHistory } = item;
          sectionTabHeaderConfig = {
            details: { bold: true },
            'audit-history': { bold: auditHistory && auditHistory.total > 0 },
          };
          break;
        }
        case 'societies':
        case 'territories':
        case 'agreement-conflict':
        case 'users':
          sectionTabHeaderConfig = {
            details: { bold: true },
          };
          break;
        case 'counter-claims': {
          const counterClaim: CounterClaimDetail = detail;
          const documentsCount = flatten(get(counterClaim, 'actions', []).map(action => action.documents || [])).length;
          sectionTabHeaderConfig = {
            details: { bold: true },
            'audit-history': { bold: true },
            'other-counter-claims': { count: counterClaim.otherCounterClaimsCount || 0 },
            documents: { count: documentsCount },
            actions: { count: get(detail, 'actionsList.length', 0) },
            xref: { count: get(counterClaim, 'workTitles.length') },
          };
          break;
        }
        case 'organizations':
          sectionTabHeaderConfig = {
            details: { bold: true },
            users: { count: get(detail, 'usersCount') },
          };
          break;
      }
      if (sectionTabHeaderConfig) {
        sectionTabHeaderConfig.notes = {
          bold: detail.notes && NotesUtils.notesCount(detail.notes) > 0,
        };
      }
    }
    return sectionTabHeaderConfig;
  }

  static getActivityTab(item: any): any {
    const conflictType = get(item, 'conflictType');
    return conflictType === 'MERGE' ? 'analysis-merge' : 'analysis';
  }

  static getDefaultTabName(section: string) {
    return sections[section]?.defaultTab || (Object.keys(sections[section]?.tabs || {}) || [])[0] || '';
  }

  static getTabTitle(section: string, tabName: string): string {
    const localeTabname = tabName.replace(/-/g, '_').toUpperCase();
    const localeSection = section.replace(/-/g, '_').toUpperCase();
    const sharedTabs = ['AUDIT_HISTORY', 'NOTES'];
    if (sharedTabs.includes(localeTabname)) {
      return `SHARED.${localeTabname}.TAB_TITLE`;
    } else {
      return `${localeSection}.${localeTabname}.TAB_TITLE`;
    }
  }

  static getTabTitleTooltip(section: string, tabName: string): string {
    const localeTabname = tabName.replace(/-/g, '_').toUpperCase();
    const localeSection = section.replace(/-/g, '_').toUpperCase();
    if (TABS_WITH_TOOLTIPS.includes(localeTabname)) {
      return `${localeSection}.${localeTabname}.TAB_TITLE_TOOLTIP`;
    } else {
      return null;
    }
  }

  static hasPermission({
    tabName,
    permissionsService,
    section = '',
    featureFlagService,
  }: {
    tabName: string;
    permissionsService: PermissionsService;
    section?: string;
    featureFlagService?: FeatureFlagService;
  }) {
    let hasPermission = true;
    switch (tabName) {
      case 'actions':
        hasPermission = permissionsService.can('counter-claims_tab_actions');
        break;
      case 'clauses':
        hasPermission = permissionsService.can('ips_clauses_read');
        break;
      case 'work-activity':
        hasPermission = permissionsService.can('works_activity');
        break;
      case 'work-clauses':
        hasPermission = permissionsService.can('works_ip_clauses_read');
        break;
      case 'repertoires':
        hasPermission = permissionsService.can('works_details_repertoires_tab');
        break;
      case 'notes':
        hasPermission = permissionsService.can('works_details_notes_tab');
        break;
      case 'audit-history':
        hasPermission = permissionsService.can(`${section}_audit-history`);
        break;
      case 'agreements':
        hasPermission = permissionsService.can(`${section}_agreements`);
        break;
      case 'agreement-chains':
        hasPermission = featureFlagService?.isAgreementChainsEnabled$.getValue() && permissionsService.can(`${section}_agreement-chains`);
        break;
      case 'xref':
        hasPermission = permissionsService.can(`${section}_xref`);
        break;
      case 'works':
        hasPermission = permissionsService.can(`${section}_works`);
        break;
      case 'alternative-names':
        hasPermission = permissionsService.can(`${section}_alternative-names`);
        break;
      case 'relations':
        hasPermission = permissionsService.can(`${section}_relations_read`);
        break;
      case 'linked-publishers':
        hasPermission = permissionsService.can(`${section}_linked-publishers`);
        break;
      case 'linked-writers':
        hasPermission = permissionsService.can(`${section}_linked-writers`);
        break;
      case 'work-claims':
        hasPermission = permissionsService.can(`works_detail_submitted_claims_tab`);
        break;
      case 'all-claims':
        hasPermission = permissionsService.can(`works_detail_all_claims_tab`);
        break;
      case 'conflicts-cc':
        hasPermission = permissionsService.can(`works_detail_conflicts-cc_tab`);
        break;
    }
    return hasPermission;
  }

  static getSChemaPropsToArray(schema: DataTableRow[]) {
    if (schema) {
      const propArray = [];
      schema.forEach(schemaRow => {
        propArray.push(schemaRow.prop);
      });
      return propArray;
    } else {
      return [];
    }
  }

  static getSchemaFiltered(schema, filterValue) {
    if (schema && filterValue) {
      return schema.filter(item => !filterValue.includes(item));
    }
  }
}
