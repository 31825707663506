import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'ice-percentage',
  template: `
    <input
      type="text"
      class="ice-data-table-row ice-percentage-text-input {{ column.cellClass }}"
      [style.width]="column.width + 'px !important'"
      [class.editable]="!(column.disableInput?.(row) | async)"
      [tabindex]=" !(column.disableInput?.(row) | async)? (index || row.hierarchy || row.index) + 100: -1"
      [disabled]="column.disableInput?.(row) | async"
      [value]="_value"
      (input)="onChange($event, column.prop)"
      (keyup)="onKeyup($event)"
      (keydown)="onKeydown($event)"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)"
    />
  `,
  styleUrls: ['./ice-percentage.component.scss'],
})
export class IcePercentageComponent implements OnChanges {
  @Input() row: any;
  @Input() value: any;
  @Input() column: any;
  @Input() index: number | null = null;

  public _row: any;
  public _value: string;
  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this._value = changes['value'].currentValue;
    }
  }
  getValidValue(originalValue: string): string {
    const dotNumber = parseFloat(originalValue.replace(/,/g, '.'));
    const isValidPercentage = isFinite(dotNumber) && dotNumber <= 100 && dotNumber >= 0;
    if (!isValidPercentage) {
      return '';
    }
    return String(dotNumber);
  }

  onChange(event: Event, prop: string) {
    const inputValue = (event.target as HTMLInputElement).value;
    const newValue = this.getValidValue(inputValue);
    this._row = { ...this.row, [prop]: newValue };
    this._value = inputValue;
  }

  onKeyup(event: KeyboardEvent): void {
    this.column.onKeyup?.(this._row, event);
  }

  onKeydown(event: KeyboardEvent): void {
    this.column.onKeydown?.(this._row, event);
  }

  onFocus(event: FocusEvent): void {
    (event.target as HTMLInputElement).select(); // Select all text on focus
    this.column.onFocus?.(this._row, event);
  }

  onBlur(event: FocusEvent): void {
    if (!this._row) {
      return;
    }
    // correct the value on blur (no letters, negative numbers, etc.)
    const validValue = this.getValidValue((event.target as HTMLInputElement).value);
    if (!validValue) {
      this._value = this.row[this.column.prop];
    }

    this.column.onBlur?.(this._row, event);
  }
}
