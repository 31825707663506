import { createApiCall, createApiCallFromBase, isResponseHandler } from 'config/api-calls/api-call.factory';
import { environment } from 'config/env';
import { RequestType } from 'config/sections-config/api-call';

import { getAuditHistory, getAuditHistoryFilterEndDate, getAuditHistoryFilterStartDate } from '../common/common.api-calls';

export const getAgreementGroupHistoryFilterStartDate = createApiCallFromBase(getAuditHistory, getAuditHistoryFilterStartDate);

export const getAgreementGroupAuditHistoryFilterEndDate = createApiCallFromBase(getAuditHistory, getAuditHistoryFilterEndDate);

export const updateAgreementGroup = createApiCall(`${environment.apiUrlCubeRegister}/agreement-groups/<<ns>>/<<id>>/agreements`, RequestType.PUT, isResponseHandler);

export const addToAgreementGroup = createApiCall(`${environment.apiUrlCubeRegister}/agreement-groups/<<ns>>/<<id>>/agreements`, RequestType.POST, isResponseHandler);

export const deleteFromAgreementGroup = createApiCall(
  `${environment.apiUrlCubeRegister}/agreement-groups/<<ns>>/<<id>>/agreements/<<agreementId>>`,
  RequestType.DELETE,
  isResponseHandler,
);
