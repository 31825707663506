import { UserPermissionType } from '@ice/components/expert-search-form/model/enums';
import { GROUP_ICE_ALL, GROUP_PB_ALL, GROUP_SO_ALL, ICE_PREFIX, PREFIXES, ROLES_PERMISSIONS } from 'config/constants/global.constants';
import { OrganizationType } from 'config/constants/organizations.constants';
import { capitalize, difference, drop, dropRight, first, get, last, upperCase } from 'lodash';
import { CurrentOrganization, User } from 'models';
import { OrganizationSearchCleaned } from 'models/copyright/search/user-management-organization-search';
import { OrganizationOwner, UserCleaned, UserOrganization } from 'models/users/users.model';
import moment from 'moment';
import { DateTimeUtils } from '@ice';
import { Organization } from 'models/copyright/detail/organizations';
import { hasItems } from '../hasItems';
export class UsersUtils {
  private static DIVIDER_STR = ' ';

  static getUserPutRequestFromUser(user: UserCleaned) {
    const { id, firstName, status, lastName, organizationId, roles, email, version, rolesList } = user;
    const userName = this.getUserNameFromUserId(id);
    const ns = this.getNSFromUserIdWithoutOrganizationNS(id);
    return {
      id,
      attributes: {
        id,
        userName,
        ns,
        firstName,
        status: status === 'NOT ACTIVATED' ? 'NOT_ACTIVATED' : status,
        lastName,
        email,
        version,
      },
      roles: rolesList.map(role => ({
        name: role,
        organizationId,
        owner: null,
        role: null,
        userId: null,
        version,
      })),
      version,
    };
  }

  static getUsersFromItems(items: any[]): UserCleaned[] {
    return items && items.length && items.length > 0 ? items.map(item => this.cleanUserResponse(item)) : [];
  }

  static cleanUserResponse(item: any): UserCleaned {
    if (item && item.attributes && item.roles) {
      let creationDate: string;
      const user = { ...item.attributes };
      const { status } = user;
      const { roles, metrics, tags } = item;

      if (get(roles, '[0]')) {
        user['organizationId'] = roles[0].organizationId;
        user['rolesList'] = [];
        user['roles'] = roles
          .map(role => {
            user['rolesList'].push(role.name);
            return role.name;
          })
          .join(', ');
      }

      if (tags) {
        creationDate = hasItems(tags.created) && DateTimeUtils.formatDate(tags.created[0]);
      }
      const userName = UsersUtils.getUserNameFromUserId(user.id);
      const cognitoUserName = UsersUtils.convertUserIDToUserName(user.id);
      const firstName = get(user, 'firstName', '');
      const lastName = get(user, 'lastName', '');
      const email = get(user, 'email', '');
      const lastAttempt = this.getLastLogin(metrics);
      return {
        ...user,
        firstName,
        lastName,
        email,
        userName,
        cognitoUserName,
        lastAttempt,
        creationDate,
        status: status === 'NOT_ACTIVATED' || (status === 'ACTIVE' && lastAttempt === '') ? 'NOT ACTIVATED' : status,
      };
    }
    return item;
  }

  static userSearchCleaner(users: any[]) {
    return users.map(user => {
      const { id, attributes, metrics, tags } = user;
      let firstName;
      let lastName;
      let email;
      let organizationId = '';
      let cognitoUserName;
      let creationDate;
      let status;

      const lastLogin = this.getLastLogin(metrics);
      if (tags) {
        creationDate = hasItems(tags.created) && DateTimeUtils.formatDate(tags.created[0]);
      }

      if (attributes) {
        firstName = attributes.firstName;
        lastName = attributes.lastName;
        email = attributes.email;
        organizationId = UsersUtils.getNSFromUserIdWithoutOrganizationNS(id);
        cognitoUserName = UsersUtils.convertUserIDToUserName(id);
        status = attributes.status;
      }

      return {
        id,
        organizationId,
        firstName,
        lastName,
        email,
        cognitoUserName,
        lastLogin,
        status: status === 'NOT_ACTIVATED' || (status === 'ACTIVE' && lastLogin === '') ? 'NOT ACTIVATED' : status,
        creationDate,
      };
    });
  }

  static getNSFromUserIdWithoutOrganizationNS(userId): string {
    return hasItems(userId) ? userId.split(':')[0] : ICE_PREFIX;
  }

  static getNSFromUserId(userId): string {
    return hasItems(userId) ? userId.split(':')[0] : ICE_PREFIX;
  }

  static getOrganizationWithoutNS(item: OrganizationSearchCleaned): string {
    return get(item, 'id') && hasItems(item.id) ? UsersUtils.getOrganizationFromOrganizationID(item.id) : '';
  }

  static getOrganizationFromOrganizationID(orgId): string {
    if (hasItems(orgId)) {
      const orgIdSplitted = orgId.split(':');
      return orgIdSplitted[orgIdSplitted.length - 1];
    }
    return '';
  }

  static getNamespaceFromOrganizationID(orgId): string {
    if (hasItems(orgId)) {
      const orgIdSplitted = orgId.split(':');
      return orgIdSplitted[0];
    }
    return '';
  }

  static getUserNameFromUserId(userId) {
    if (hasItems(userId)) {
      const userIdSplitted = userId.split(':');
      return userIdSplitted[userIdSplitted.length - 1];
    }
    return '';
  }

  static generateUserIdFromUserName(userName: string): string {
    const spUser = userName ? userName.split('-') : [];
    return (spUser.length > 1 && `${last(spUser).toUpperCase()}:${dropRight(spUser).join('-')}`) || `${ICE_PREFIX}:${userName}`;
  }

  static getNSFromUserName(userName: string): string {
    const spUser = userName ? userName.split('-') : [];
    return (spUser.length && last(spUser).toUpperCase()) || ICE_PREFIX;
  }

  static convertUsernameToUserID(userName: string): string {
    let userID: string;
    const spUser = userName ? userName.split('-') : [];
    if (spUser.length > 0) {
      userID = `${last(spUser).toUpperCase()}:${dropRight(spUser).join('-')}`;
    } else {
      userID = userName;
    }
    return userID;
  }

  static convertUserIDToUserName(userID: string): string {
    let username: string;
    const spUser = userID ? userID.split(':') : [];
    if (spUser.length > 0) {
      username = `${drop(spUser).join(':')}-${first(spUser).toLowerCase()}`;
    } else {
      username = userID;
    }
    return username;
  }

  static cleanUserOrganizations(items: any[]): UserOrganization[] {
    return hasItems(items) ? items.map(item => this.cleanUserOrganization(item)) : [];
  }

  static cleanUserOrganization(item: any): UserOrganization {
    if (item && item.attributes && item.roles) {
      const { id, roles } = item;
      const { access, ns, name, owner, type, version } = item.attributes;

      return { id, roles, attributes: { id, ns, name, owner, type, version, access } };
    }
    return item;
  }

  static getUserPermissions(permissionType: UserPermissionType, userRoles: string[], section: string, organizationId?: string): boolean {
    if (ROLES_PERMISSIONS[permissionType][section]) {
      const { roles, organizations } = ROLES_PERMISSIONS[permissionType][section];
      const roleGranted = roles.some(item => userRoles.includes(item) || item === 'all');
      const organizationGranted = !organizations || (organizations && organizationId && organizationId.length > 0 && organizations.some(org => org === organizationId));
      return roleGranted && organizationGranted;
    }
    return ROLES_PERMISSIONS[permissionType]['default'].roles.some(item => userRoles.includes(item) || item === 'all');
  }

  static getLastLogin(metrics: { lastLogin: any }): string {
    return get(metrics, 'lastLogin') && metrics.lastLogin ? DateTimeUtils.formatDateTime(moment(metrics.lastLogin)) : '';
  }

  // format from ice-admin to admin:ice
  static formatUserIdforSearch(term: string) {
    const separatorIndex = term.lastIndexOf('-');
    return separatorIndex ? `${term.substring(separatorIndex + 1)}:${term.substring(0, separatorIndex)}` : term;
  }

  static getCurrentOrganizationFromUser(userOrganization: any, user: User): CurrentOrganization {
    const selectedOrganizationAttributes = userOrganization && userOrganization.attributes;

    if (selectedOrganizationAttributes) {
      const { id, name, owner, type } = selectedOrganizationAttributes;
      const publisherNS = (owner && this.selectPublisherNS(owner)) || '';
      const accessPartyNames = type === OrganizationType.publisher && get(selectedOrganizationAttributes, 'access.partyNames');
      const societyCode = type === OrganizationType.society && (id || '').replace(/\D/g, '');
      const isIce = (id || '').split(':')[1] === 'ICE';
      return { id, name, publisherNS, type, ...(accessPartyNames && { accessPartyNames }), ...(societyCode && { societyCode }), isIce };
    }
    return null;
  }

  static selectPublisherNS(owner: OrganizationOwner) {
    const { namespaces } = owner;
    return (namespaces && first(namespaces.filter(ns => !ns.includes('CUBE')))) || '';
  }

  static getTypedRoles(user: User, currentOrganization: CurrentOrganization): string[] {
    const typedRolesList = [];
    const rolesList = user.detail.rolesList;
    const prefix = PREFIXES[currentOrganization.type];
    if (rolesList && rolesList.length && rolesList.length > 0) {
      rolesList.map(role => {
        typedRolesList.push(`${prefix}${role}`);
      });
    }
    return typedRolesList;
  }

  static formatUsernameForReports(user) {
    return `${user.ns}:${user.userName}`;
  }

  static isValidRole(roles: string[]): boolean {
    const allRoles = [...GROUP_ICE_ALL, ...GROUP_SO_ALL, ...GROUP_PB_ALL];
    const allRolesNoPrefix = allRoles.map(role => role.substring(role.indexOf('-') + 1));
    return difference(roles, allRolesNoPrefix).length === 0;
  }

  static formatOrganizationDropdownLabel(organization: Organization) {
    const organizationIdStr = organization.id;
    const organizationNameStr = organization.name ? `(${organization.name})` : '';
    return `${organizationIdStr}${this.DIVIDER_STR}${organizationNameStr}`.trim();
  }

  static organizationInputToId(input: string) {
    return input?.split(this.DIVIDER_STR)[0];
  }

  /**
   * This static method returns the full name of a user.
   * The first name is capitalized and the last name is converted to uppercase.
   *
   * @param user - The user object from which the full name is to be extracted.
   * @returns The full name of the user.
   */
  static composeFullName(user: { attributes: any }): string {
    return `${capitalize(get(user, 'attributes.firstName'))} ${upperCase(get(user, 'attributes.lastName'))}`;
  }
}
