import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import * as fromApiCalls from 'config/api-calls';
import { CopyrightAgreementsXrefDataTable } from 'config/data-table-builders/copyright.agreement-xrefs';
import { DialogEditAgreementCrossReferences } from 'config/dialog-builders/dialog-edit-agreement-cross-references';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IceFacade } from 'facades/ice.facade';
import { CommonApiService } from 'services/common-api.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { DetailService } from 'services/detail/detail.service';
import { SearchService } from 'services/search/search.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';

export class TabAgreementXrefs implements SectionTabConfig {
  private canEditAgreement: boolean;
  private canDeleteXref: boolean;
  private selectionDatatable: SelectionDatatableBuilder;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: CommonApiService,
    private detailService: DetailService,
    private nsService: NamespaceService,
    private iceFacade: IceFacade,
    private fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
    private searchService: SearchService,
    private storeNewItem: Store<fromForm.NewSectionItemState>,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.canEditAgreement = this.permissionsService.can('agreements_edit');
    this.canDeleteXref = this.permissionsService.can('agreements_delete_xrefs');
  }

  getConf(): IceGroupComponent[] {
    const copyrightAgreementXrefsDataTable = new CopyrightAgreementsXrefDataTable(this.translate, this.fuseTranslationLoader, this.store);
    this.selectionDatatable = new SelectionDatatableBuilder(
      this.store,
      [
        ...copyrightAgreementXrefsDataTable.getDataTable(),
        {
          name: '',
          prop: '',
          actionButtonIcon: 'delete',
          flexGrow: 0.001,
          maxWidth: 50,
          minWidth: 50,
          resizeable: false,
          hideActionButton: row => of(row.label === 'ICE' || !this.canDeleteXref),
          action: row => DialogEditAgreementCrossReferences.openDeleteDialog(this.dialog, this.store, this.translate, this.fuseTranslationLoader, row),
          actionButtonTooltip: `${this.translate.instant('AGREEMENTS.CROSS_REFERENCES.POPUP.DELETE_TITLE')}`,
        },
      ],
      null,
      null,
      null,
      null,
      this.store.select(fromRoot.getAgreementXrefs),
    );

    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.TITLE'),
              model: this.store.pipe(
                select(fromRoot.getAgreementXrefs),
                map(rows => copyrightAgreementXrefsDataTable.transformRows(rows)),
              ),
              ...this.selectionDatatable.getSelectionDatatableConfig(),
              actionButtons: of(
                this.canEditAgreement
                  ? [
                      {
                        icon: 'add',
                        tooltip: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.ADD_CROSS_REFERENCES'),
                        class: 'mat-white-icon add-xref-button',
                        onClick: item =>
                          DialogEditAgreementCrossReferences.openDialog(this.dialog, this.store, this.translate, this.fuseTranslationLoader, this.fieldValidatorService),
                      },
                    ]
                  : [],
              ),
              loadingIndicator: true,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              apiCall: fromApiCalls.getAgreementXrefs,
            },
          },
        ],
      },
    ];
  }
}
