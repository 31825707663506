import type { ApiCallConfig, ApiCallSortConfig } from 'config/sections-config/api-call';
import { Observable } from 'rxjs';
import { IconInterface } from 'models/copyright/detail/icon';
import { DataTableTotals } from '../data-table-totals/data-table-totals';

export enum ActionType {
  edit = 'edit',
  link = 'link',
  delete = 'delete',
}

export enum IceTemplate {
  PERCENTAGE = 'icePercentage',
}

export interface DataTableRow {
  name?: string;
  prop?: string;
  maxWidth?: number;
  minWidth?: number;
  cellClass?: string | ((row) => string);
  hideTextProperty?: boolean; // Used to hide values in Icons to Export the correct value
  colWidth?: number;
  flexGrow?: number;
  selectOptions?: any[];
  hideIfEmpty?: (row) => boolean;
  defaultValue?: any;
  comparator?: any;
  resizeable?: boolean;
  icons?: any;
  tooltip?: string;
  canHideTooltip?: boolean;
  actionButton?: string;
  filter?: boolean;
  actionButtonIcon?: string;
  canAutoResize?: boolean;
  draggable?: boolean;
  sortable?: boolean;
  hideActionButton?: (row) => Observable<boolean>;
  actionButtonTooltip?: string;
  actionType?: ActionType;
  headerActionButtonIcon?: string;
  hideHeaderActionButton?: string;
  headerActionButtonTooltip?: string;
  headerActionType?: string;
  headerClass?: string;
  headerCheckboxable?: boolean;
  checkboxable?: boolean;
  radiable?: boolean;
  width?: number;
  iconCustomTooltipHtmlText?: string;
  iconColor?: string;
  checkbox?: CheckboxConfig;
  errorMessage?: string;
  errorMessageField?: string;
  hiddenValue?: string; // To use for e2e if needed
  hideEditIcon?: boolean;
  tooltipDuration?: number;
  redirectPath?(row): void;
  editable?(row): Observable<boolean>;
  onClickAction?(row): void;
  onWheelClickAction?(row): void;
  action?(row): void;
  headerAction?(field): void;
  onEdit?(row): void;
  onBlur?(row, event: FocusEvent): void;
  onFocus?(row, event: FocusEvent): void;
  onKeyup?(row, event: KeyboardEvent): void;
  onKeydown?(row, event: KeyboardEvent): void;
  onEnter?(row): void;
  headerTooltip?: string;
  isNoTooltipCell?: boolean;
  customButton?: TableColumnCustomButton;
  iconRightOfText?: Omit<IconInterface, 'text'>;
  totals?: DataTableTotals;
  spacesToHtmlCode?: boolean; // Related to this type of bugs: https://ice-cube.atlassian.net/browse/CUBE-13012
  badge?: TableColumnBadge;
  highlightsEmptyResultsMessage?: boolean;
  selectable?: boolean;
  callbackSelectOptions?: (row, event) => void;
  disableInput?(row): Observable<boolean>;
  /**
   * Change the prop for export in selection datatable, normally for HTML Icons like WSM
   */
  exportProp?: string;
  /**
   * Can be used to add extra columns for exporting in selection datatable
   */
  excludeForVisibility?: boolean;
  /**
   * Can be used to exclude any column from exporting data in selection datatable
   */
  excludeForExport?: boolean; //
  iceTemplate?: IceTemplate;
}

export interface CheckboxConfig {
  label: string;
  class: string;
  isVisible(row): boolean;
  onClick(row, event): void;
}

export interface SortInfo {
  prop: string;
  dir: string;
}

export interface DataTableMessage {
  emptyMessage: string;
}

export interface DataTableMessages {
  loadingMessage: DataTableMessage;
  noResultsMessage: DataTableMessage;
}

export interface DataTable {
  data: any;
  sliceLimit?: number;
  sorts: SortInfo[];
  apiCall?: ApiCallConfig | (() => ApiCallConfig);
  hidden?: boolean | Observable<boolean>;
  selected?: any;
  schema: DataTableRow[] | Observable<DataTableRow[]>;
  showLoader?: boolean;
  loadingIndicator?: boolean;
  isLoadingData?: boolean | Observable<boolean>;
  reorderable?: boolean;
  fixedHeight?: string;
  displayCheck?: (row: any, column?: any, value?: any) => boolean;
  displayRadio?: (row?: any, column?: any, value?: any) => boolean;
  shadowed?: boolean;
  height?: Observable<number>;
  maxHeight?: Observable<number>;
  footerHeight?: number;
  headerHeight?: number;
  rowHeight?: number | string;
  scrollbarV?: boolean;
  scrollbarH?: boolean;
  selectionType?: string | Observable<string>;
  selectableClass?: boolean;
  columnMode?: string;
  isSelectable?: boolean;
  customClass?: string;
  messages?: DataTableMessages;
  visibleColumns?: Observable<string[]>;
  selectedKey?: string;
  dontStrip?: boolean;
  expandRowsEnabled?: boolean;
  onSelect?(data: any): void;
  onMouseSelect?(data: any): void;
  getRowClass?(row: any): any;
  onAllSelected?(event: any): void;
  onReorderColumn?(event: any): void;
  sortConfig?: ApiCallSortConfig;
  detailVisibleColumns?: Observable<string[]>;
  expandableSchema?: Record<string, DataTableRow[]>;
  detailSorts?: any;
  onSort?(event: any): void;
  expandableProperty?: Observable<string>;
  expandableNoHeader?: boolean;
  headerTitle?: Observable<string>;
  headerClass?: string;
  isSelectableMode?: Observable<boolean>;
  totals?: DataTableTotals;
  doPagination?: () => void;
  expandAllRows$?: Observable<boolean>;
  rowIdentity?(row): number | string;
}
export interface TableColumnBadge {
  text: string;
  tooltip: string;
  backgroundColor: string;
  textColor: string;
}
export interface TableColumnCustomButton {
  text: string;
  hoverText?: string;
  hasTooltip: boolean;
  tooltip?: string;
  buttonClass?: string;
  hideWhenNoValue: boolean;
}

export interface FooterOptions {
  footerTemplate?: string;
  footerClassName?: string;
  footerButtons: FooterButtonOptions[];
}

export interface FooterButtonOptions {
  enabledClassName?: string;
  disabledClassName?: string;
  enabledCondition?: () => boolean;
  className?: string;
  type: string;
  action: () => void;
  title: string;
  icon?: string;
}
