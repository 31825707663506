import {
  AgreementDetailCleaned,
  CC_STATUS_POSS_OVERLAP,
  CC_STATUS_POTENTIAL_CONFLICT,
  CC_STATUS_RESOLVED,
  CopyrightUtils,
  CounterClaimConflictStatus,
  CounterClaimUtils,
  EditNewShare,
  IpUtils,
  SocietiesUtils,
  TerritoryUtils,
  hasItems,
  ClaimsUtils,
} from '@ice';
import { TranslateService } from '@ngx-translate/core';
import { AGREEMENT_COUNTERCLAIM_STATUS, AGREEMENT_XREF_TYPE, MAX_CONFCLITS_IN_TOOLTIP, RELATED_AGREEMENT_TYPES } from 'config/constants/agreements.constants';
import { ALL, DATE_FORMAT, END_DATE_NO_DISPLAY } from 'config/constants/global.constants';
import { IpNature, mrRights, prRights, rpRights, syRights } from 'config/constants/ips.constants';
import { RELATIONTYPES } from 'config/constants/relation.constants';
import { ALL_RIGHTS } from 'config/constants/shares.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { workCategoriesCodes } from 'config/constants/works.constants';
import { clone, cloneDeep, concat, difference, find, flatMap, get, has, intersection, isEmpty, isString, last, map, omit, startsWith, toNumber, uniq, uniqBy } from 'lodash';
import { AgreementDetail, AgreementShares, PostTermCollectionValue, IconInterface } from 'models';
import moment from 'moment';

export class AgreementUtils {
  static selectPartyName(partyName, societies, addPRMR = false) {
    const fullName = IpUtils.getIpFullName(partyName);
    const prSocietyItem = societies && SocietiesUtils.getPRorMRSociety(societies, 'PR');
    const prSociety = get(prSocietyItem, 'societyCode');
    const mrSocietyItem = societies && SocietiesUtils.getPRorMRSociety(societies, 'MR');
    const mrSociety = get(mrSocietyItem, 'societyCode');
    const strPRMR = societies && addPRMR ? `<br>${prSociety ? `PR: ${prSociety}` : ''} ${mrSociety ? `MR: ${mrSociety}` : ''}` : '';
    return partyName ? `${fullName}${strPRMR}` : '';
  }

  static rightsGenericToSpecific(generic) {
    if (Array.isArray(generic)) {
      generic = generic.join(',');
    }
    if (generic) {
      switch (generic.trim().toUpperCase()) {
        case 'ALL':
          return concat(prRights, mrRights);
        case 'PR-ALL':
          return prRights;
        case 'MR-ALL':
          return mrRights;
      }
      return generic.split(',');
    }
    return [];
  }

  static getPostTermCollectionDate(terminationData) {
    switch ((terminationData && terminationData.postTermCollection) || PostTermCollectionValue.INDEFINITE) {
      case PostTermCollectionValue.INDEFINITE:
        return END_DATE_NO_DISPLAY;
      case PostTermCollectionValue.DATE:
        return terminationData.postTermCollectionDate && terminationData.postTermCollectionDate.format(DATE_FORMAT);
      case PostTermCollectionValue.NONE:
        return terminationData.terminatedDate && terminationData.terminatedDate.format(DATE_FORMAT);
      default:
        return END_DATE_NO_DISPLAY;
    }
  }

  static formatShares(item): AgreementShares[] {
    const noDate = '1900-01-01';
    const editNewShares: EditNewShare[] = item.shares;
    let sharesToUpdate: AgreementShares[];
    if (item && editNewShares) {
      let shareIn = 0;
      let shareOut = 0;
      const postTermCollectionDate = item.post_term_collection_date; // No implemented in stepper yet, only in Terminate Button
      const priorRoyaltiesDate = item.prior_royalties === 'Y' ? noDate : item.start_date;
      const startDate = item.start_date;
      sharesToUpdate = editNewShares.map((share: any) => {
        const endDate = share.endDate || END_DATE_NO_DISPLAY;
        let rights: string[];
        if (share.inclusion === ALL) {
          const shareType = share.type.toLowerCase();
          switch (shareType) {
            case 'mechanical':
              rights = mrRights;
              break;
            case 'performing':
              rights = prRights;
              break;
            case 'synchronisation':
              rights = syRights;
              break;
            case 'print':
              rights = rpRights;
              break;
          }
        } else {
          rights = (share.inclusion && share.inclusion.split(',').map(right => right.trim())) || [];
        }

        shareOut = (100 - toNumber(share.creator)) * 100;
        shareIn = share.assignee > 0 ? Math.round((toNumber(share.assignee) / (toNumber(share.assignee) + toNumber(share.publisher))) * 10000) : 0;

        if (share?.noShareout) {
          shareOut = 0;
          shareIn = toNumber(share.assignee) * 100;
        }

        const inputTerritoriesValues = (isString(share.territory) && share.territory) || (isString(get(share, `territory.value`)) && get(share, `territory.value`)) || '';
        const territoriesList = TerritoryUtils.convertTerritoryStringElements(inputTerritoriesValues, TerritoryDataType.TISN);

        const territories = TerritoryUtils.formatShareTerritoriesForRegistration(territoriesList);

        return {
          endDate,
          priorRoyaltiesDate: priorRoyaltiesDate || share.priorRoyaltiesDate || END_DATE_NO_DISPLAY,
          // when creating retention agreements, those are new and have no post term collection date because the end date is indefinite
          postTermCollectionDate: item.retention ? undefined : share.postTermCollectionDate || postTermCollectionDate || END_DATE_NO_DISPLAY,
          rights,
          shareIn,
          shareOut,
          startDate: startDate || share.startDate,
          territories,
          codes: (item.termination && share.codes) || undefined,
        };
      });
    }
    return sharesToUpdate;
  }

  static getRevisedShares(terminationData, shares: AgreementShares[] | EditNewShare[]): AgreementShares[] {
    const allTerminatedRights = this.rightsGenericToSpecific(terminationData.rightTypes || '');
    return uniqBy(
      (shares || [])
        .map(share => {
          const { codes, ...shareToSave } = share;
          const shareTerritories = codes || [];
          const territoriesExcludingTerminated = difference(shareTerritories, terminationData.territoryCodes);
          const rightsExcludingTerminated = difference(share.rights, allTerminatedRights);
          const shareNotEffectedByTermination = territoriesExcludingTerminated.length === shareTerritories.length || rightsExcludingTerminated.length === share.rights.length;
          const results = [];
          if (shareNotEffectedByTermination) {
            results.push({ ...shareToSave, territories: shareTerritories.map(territoryCode => `+${territoryCode}`) });
            return results;
          }

          const territoriesTerminatedInShare = difference(shareTerritories, territoriesExcludingTerminated);
          const rightsTerminatedInShare = difference(share.rights, rightsExcludingTerminated);

          if (territoriesTerminatedInShare.length > 0 && rightsTerminatedInShare.length > 0) {
            results.push({
              ...shareToSave,
              territories: territoriesTerminatedInShare.map(territoryCode => `+${territoryCode}`),
              rights: rightsTerminatedInShare,
              postTermCollectionDate: this.getPostTermCollectionDate(terminationData),
              endDate: (terminationData.terminatedDate && terminationData.terminatedDate.format('YYYY-MM-DD')) || '9999-12-31',
            });

            if (rightsExcludingTerminated.length > 0) {
              results.push({
                ...shareToSave,
                territories: territoriesTerminatedInShare.map(territoryCode => `+${territoryCode}`),
                rights: rightsExcludingTerminated,
              });
            }
          }

          if (territoriesExcludingTerminated.length > 0) {
            results.push({
              ...shareToSave,
              territories: territoriesExcludingTerminated.map(territoryCode => `+${territoryCode}`),
            });
          }

          return results;
        })
        .reduce((acc, it) => [...acc, ...it], []),
      share => `${share.territories.join('+')}-${share.rights.join('+')}`,
    );
  }

  static getRetentionIcon(retention, translate): IconInterface[] {
    return retention ? [{ icon: 'assignment_late', text: translate.instant('AGREEMENTS.HAS_RETENTION'), class: 'ice-yellow' }] : [];
  }

  static cleanAndLabelAgreementDetail(detail: AgreementDetailCleaned) {
    const { startDate, priorRoyaltiesFlag, expectedTerminationDate } = get(detail, 'attributes');
    const { postTermCollectionDate } = get(detail, 'attributes');
    const agreementEndDate = this.findEndDate(detail);
    const { endDateUpdated, postTermCollectionDateUpdated } = this.comparePTCTerminationDates(agreementEndDate, postTermCollectionDate);

    return [
      { label: 'AGREEMENT_START_DATE', value: startDate },
      { label: 'EXPECTED_TERMINATION_DATE', value: expectedTerminationDate },
      {
        label: 'PRIOR_ROYALTIES_START_DATE',
        value: priorRoyaltiesFlag,
      },
      { label: 'CONFIRMED_TERMINATION_DATE', value: endDateUpdated },
      { label: 'POST_TERM_COLLECTION_END_DATE', value: postTermCollectionDateUpdated },
    ];
  }

  static cleanAgreementShares(shares) {
    return flatMap(
      shares.map(share => {
        const intersectionMR = intersection(share.rights, mrRights);
        const intersectionPR = intersection(share.rights, prRights);
        const intersectionRP = intersection(share.rights, rpRights);
        return [
          ...(share.rights[0] === syRights[0] ? [{ ...share, rights: syRights }] : []),
          ...(intersectionMR.length > 0 ? [{ ...share, rights: intersectionMR }] : []),
          ...(intersectionPR.length > 0 ? [{ ...share, rights: intersectionPR }] : []),
          ...(intersectionRP.length > 0 ? [{ ...share, rights: intersectionRP }] : []),
        ];
      }),
    );
  }

  static formatAgreementShares(shares: AgreementShares[], translate: TranslateService) {
    return (shares || []).map(share => this.genRowShareOfAgreementDetail(translate, share));
  }

  private static genRowShareOfAgreementDetail(translate, share) {
    const intersectionMR = intersection(share.rights, mrRights);
    const intersectionPR = intersection(share.rights, prRights);
    const intersectionRP = intersection(share.rights, rpRights);
    const type = (share.rights[0] === syRights[0] && 'SY') || (intersectionMR.length > 0 && 'MR') || (intersectionPR.length > 0 && 'PR') || (intersectionRP.length > 0 && 'RP');
    const clonedShare = cloneDeep(share);
    if (clonedShare.rights) {
      if (difference(prRights, clonedShare.rights).length === 0 || difference(mrRights, clonedShare.rights).length === 0) {
        clonedShare.rights = [ALL_RIGHTS];
      }
    }
    const rightsCodes = clonedShare.rights && clonedShare.rights.join(',');
    const rightsCodesTooltip = SocietiesUtils.getRightsCodesTooltip(rightsCodes, translate);
    const { territoriesIcon, territoriesText, territoriesTooltip } =
      has(share, 'territories') &&
      TerritoryUtils.getTerritoriesDisplayListData(
        share.territories.filter(territory => territory !== null),
        TerritoryDataType.NAME,
      );
    const shareIn = AgreementUtils.formatShare(share, 'shareIn');
    const shareOut = AgreementUtils.formatShare(share, 'shareOut');
    const endDate = AgreementUtils.removeIndefiniteDate(share.endDate);
    const postTermCollectionDate = AgreementUtils.removeIndefiniteDate(share.postTermCollectionDate);
    return {
      shareType: type,
      shareTypeTooltip: translate.instant('GLOBAL_RIGHTS.' + type),
      rightsCodes,
      rightsCodesTooltip,
      territoriesIcon,
      territoriesText,
      territoriesTooltip,
      shareIn,
      shareOut,
      endDate,
      postTermCollectionDate,
    };
  }

  private static formatShare(value: any, prop: string): string {
    return get(value, `${prop}`) ? `${value[prop] / 100}%` : '-';
  }

  private static removeIndefiniteDate(date: string) {
    return date === '9999-12-31' ? '' : date;
  }

  static generateAssignorIcons(partyAttributes, translate): IconInterface[] {
    const icons = [];
    if (partyAttributes) {
      const icon =
        partyAttributes.typeOf === 'L'
          ? { icon: 'business', text: translate.instant('IPS.IP_TYPE_LEGAL_ENTITY') }
          : { icon: 'person', text: translate.instant('IPS.IP_TYPE_NATURAL_PERSON') };

      icons.push(icon);
    }
    return icons;
  }

  static generateAdministratorIcon(value, translate?): IconInterface[] {
    return value
      ? [{ icon: 'check_box', text: translate ? translate.instant('AGREEMENTS.IS_ADMIN') : 'Is Admin', class: 'ice-checkbox-icon' }]
      : [{ icon: 'check_box_outline_blank', text: translate ? translate.instant('AGREEMENTS.NO_ADMIN') : 'No Admin', class: 'ice-checkbox-icon' }];
  }

  static generateDisputeIcon(agreementCounterClaimStatus, translate?): IconInterface[] {
    const icons = [];
    if (agreementCounterClaimStatus && agreementCounterClaimStatus.length > 0) {
      let countAgreementPotentialConflict;
      let countAgreementConfirmed;
      let countAgreementResolved;
      let countAgreementOpen;

      if (agreementCounterClaimStatus) {
        countAgreementPotentialConflict = agreementCounterClaimStatus.includes(AGREEMENT_COUNTERCLAIM_STATUS.POTENTIAL_CONFLICT);
        countAgreementConfirmed = agreementCounterClaimStatus.includes(AGREEMENT_COUNTERCLAIM_STATUS.CONFIRMED);
        countAgreementResolved = agreementCounterClaimStatus.includes(AGREEMENT_COUNTERCLAIM_STATUS.RESOLVED);
        countAgreementOpen = agreementCounterClaimStatus.includes(AGREEMENT_COUNTERCLAIM_STATUS.OPEN);
      }

      if (countAgreementConfirmed) {
        icons.push({ icon: 'warning', text: translate ? translate.instant('AGREEMENTS.CONFIRMED_CONFLICT') : 'Confirmed', class: 'ice-red' });
      }
      if (countAgreementPotentialConflict) {
        icons.push({ icon: 'warning', text: translate ? translate.instant('AGREEMENTS.POTENTIAL_CONFLICT') : 'Potential conflict', class: 'ice-yellow ' });
      }
      if (countAgreementOpen) {
        icons.push({ icon: 'warning', text: translate ? translate.instant('AGREEMENTS.IN_CONFLICT') : 'In conflict' });
      }
    }
    return icons;
  }

  static cleanAgreement(agreement, translate) {
    const { id, attributes, relations, assignor, assignee, counterclaims } = agreement;
    const workCount = get(agreement, 'agg.attributes.workCount', 0);
    const {
      territoriesIcon = [],
      territoriesText = '',
      territoriesTooltip = '',
      startDate = '',
      endDate = '',
      postTermCollectionDate = '',
      priorRoyaltiesStartDate = '',
      sharesIcons = [],
      pr = '',
      prIcons = [],
      mr = '',
      mrIcons = [],
      prToExport,
      mrToExport,
    } = (get(attributes, 'shares') && ClaimsUtils.getSharesFormatted(attributes.shares, translate)) || {};

    const agreementCounterClaimStatus = CounterClaimUtils.getAgreementCounterClaimStatus(counterclaims);

    if (attributes?.exclude?.agreementIds) {
      const cleanAgreementIds = uniq(get(attributes, `exclude.agreementIds`, []).filter(agreementId => !!agreementId));
      attributes.exclude.agreementIds = cleanAgreementIds;
    }

    return {
      ...attributes,
      key: IpUtils.selectIpsKey(relations, id),
      territoriesIcon,
      territoriesText,
      territoriesTooltip,
      startDate,
      endDate,
      postTermCollectionDate,
      priorRoyaltiesStartDate,
      administratorIcon: AgreementUtils.generateAdministratorIcon(attributes.administrator, translate),
      disputeIcon: AgreementUtils.generateDisputeIcon(agreementCounterClaimStatus, translate),
      sharesIcons,
      pr,
      prIcons,
      mr,
      mrIcons,
      assignor: AgreementUtils.cleanAssignorOrAssignee(assignor, translate, true),
      assignee: AgreementUtils.cleanAssignorOrAssignee(assignee, translate, true),
      workCount,
      prToExport,
      mrToExport,
    };
  }

  static cleanAgreements(agreements, translate): any[] {
    return (hasItems(agreements) && agreements.map(agreement => this.cleanAgreement(agreement, translate))) || [];
  }

  static getCategoryByCode(code: string): string {
    const description = workCategoriesCodes.find(item => item.code === code);
    return (description && description.name) || '';
  }

  static cleanAssignorOrAssignee(assignorOrAssignee, translate, addExtraInfoToName = false) {
    if (assignorOrAssignee) {
      const { party, partyName, partyNameId } = assignorOrAssignee;
      let cleanedPartyNameId: string;
      let name: string;
      let detailTitle: string;
      let ipiNameNumber: string;
      let ipNameKey: string;
      let prSociety: string;
      let prSocieties: string;
      let prSocietyName: string;
      let prSocietyIcons: IconInterface[];
      let mrSociety: string;
      let mrSocieties: string;
      let mrSocietyName: string;
      let mrSocietyIcons: IconInterface[];
      let icons: IconInterface[];
      let nameType: string;
      let ipiBaseNumber: string;
      let baseKey: string;

      if (partyName) {
        const partyNameAttributes = partyName.attributes;
        detailTitle = IpUtils.getIpFullName(partyNameAttributes);
        if (partyNameAttributes) {
          name = party && party.societies && AgreementUtils.selectPartyName(partyNameAttributes, party.societies, addExtraInfoToName);
          cleanedPartyNameId = partyNameId || partyNameAttributes.id;
        }
        if (partyName.relations) {
          ipiNameNumber = IpUtils.selectIPINumber(partyName.relations);
          ipNameKey = IpUtils.selectIpsKey(partyName.relations, partyName.id).replace('ICE:', '') || '';
        }
      }
      if (party) {
        icons = AgreementUtils.generateAssignorIcons(party.attributes, translate);
        const societies = party.societies;
        if (societies) {
          const prSocietyObject = SocietiesUtils.getPRorMRSociety(societies, 'PR');
          prSociety = get(prSocietyObject, 'societyCode', '');
          prSocietyName = prSociety && !prSocietyObject.multipleSocieties ? `${SocietiesUtils.searchSocietyNameById(prSociety)}/${get(prSocietyObject, 'societyCode', '')}` : '';
          prSocieties = (prSocietyObject.multipleSocieties && prSocietyObject.societyCodes) || '';
          prSocietyIcons = prSocietyObject.multipleSocieties ? SocietiesUtils.generateIsICESocietyIcons({ isICE: prSocietyObject.hasICESociety, societies: prSocieties }) : [];
          const mrSocietyObject = SocietiesUtils.getPRorMRSociety(societies, 'MR');
          mrSociety = get(mrSocietyObject, 'societyCode', '');
          mrSocietyName = mrSociety && !mrSocietyObject.multipleSocieties ? `${SocietiesUtils.searchSocietyNameById(mrSociety)}/${get(mrSocietyObject, 'societyCode', '')}` : '';
          mrSocieties = (mrSocietyObject.multipleSocieties && mrSocietyObject.societyCodes) || '';
          mrSocietyIcons = mrSocietyObject.multipleSocieties ? SocietiesUtils.generateIsICESocietyIcons({ isICE: mrSocietyObject.hasICESociety, societies: mrSocieties }) : [];
        }
        nameType = get(party, 'attributes.typeOf', '');
        ipiBaseNumber = party.relations && IpUtils.selectIPINumber(party.relations);
        baseKey = party.relations && IpUtils.selectICENumber(party.relations);
      }

      return {
        id: cleanedPartyNameId,
        name,
        nameType,
        ipiBaseNumber,
        ipiNameNumber,
        ipNameKey,
        prSociety,
        prSocieties,
        prSocietyName,
        prSocietyIcons,
        mrSociety,
        mrSocieties,
        mrSocietyName,
        mrSocietyIcons,
        icons,
        detailTitle,
        baseKey,
      };
    }
    return {};
  }

  static cleanAgreementForUpdate(agreement: AgreementDetail): any {
    let agreementName: string;
    let agreementType: string;
    let priorRoyaltiesStartDate: string;
    let confirmedTerminationDate: string;
    let postTermCollectionDate: string;
    let salesOrManufacture: string;
    let transferWorks: string;
    let shareInOpAssignorOnly: string;
    let startDate: string;
    let endDate: string;
    let assignor: any;
    let assignorIpiNameNumber;
    let assignorName: string;
    let assignoripiBaseNumber;
    let assignorType: string;
    let assignorSocietyCode: string;
    let assignee: any;
    let assigneeType; // check if it's still used in API
    let assigneeipiBaseNumber: string;
    let assigneeIpiNameNumber: string;
    let assigneeName: string;
    let assigneeSocietyCode: string;
    let formattedShares: any[];
    let agreementRelations;
    let identifiers;
    let agreementDispute: boolean;
    let libraryMusic: boolean;
    let recordingPrefix: string;
    let gemaRemuneration: boolean;
    let relatedAgreementsRecipients = [];
    let workCount = 0;
    let relatedAgreementsCoPub = [];
    let writerMechanicalReason: string;
    let usaLicenseIndicator: string;
    let key: string;
    let submitterPartyNameId: string;
    let submitterPartyNameIds: string[];
    let assignorIpNameKey: string;
    let assignorPartyId: string;
    let assignorPartyNameId: string;
    let assigneeIpNameKey: string;
    let assigneePartyId: string;
    let assigneePartyNameId: string;
    let administrator: boolean;
    let additional: string;
    let retention: boolean;
    let noRecipientGroups = [];
    let agreementId: string;
    let expectedTerminationDate: string;
    let assigneeIcePartyId: string;
    let assignorIcePartyId: string;
    let interCompany: boolean;

    if (agreement) {
      const { attributes, groups, relations } = agreement;
      agreementId = attributes?.id;
      agreementRelations = relations && clone(relations);
      ({ assignor, assignee } = agreement);
      additional = get(attributes, 'tags.additional[0]', '');

      if (attributes) {
        let shares: AgreementShares[];
        ({ submitterPartyNameIds } = attributes);
        administrator = get(attributes, 'administrator', false);
        agreementName = attributes.searchTerm;
        expectedTerminationDate = attributes.expectedTerminationDate;
        ({
          salesOrManufacture,
          transferWorks,
          shareInOpAssignorOnly,
          startDate,
          endDate,
          agreementType,
          agreementDispute,
          libraryMusic,
          recordingPrefix,
          usaLicenseIndicator,
          writerMechanicalReason,
          confirmedTerminationDate,
          postTermCollectionDate,
          gemaRemuneration,
          workCount,
          key,
          shares,
          retention,
          interCompany,
        } = attributes);
        submitterPartyNameId = submitterPartyNameIds && submitterPartyNameIds.length && submitterPartyNameIds[0].replace('IPI:', '');
        if (submitterPartyNameId === '0') {
          submitterPartyNameId = '';
        }
        const priorRoyaltiesDate = get(shares, '[0].priorRoyaltiesDate');
        priorRoyaltiesStartDate = ClaimsUtils.selectPriorRoyaltiesStartDate(startDate, priorRoyaltiesDate);
        formattedShares = this.formatSharesForUpdate(attributes.shares);
      }

      if (assignor) {
        assignorIpiNameNumber = (assignor['ipiNameNumber'] || '').replace('IPI:', '');
        assignorName = assignor['name'] || '';
        assignorType = assignor['nameType'] || '';
        assignoripiBaseNumber = (assignor['ipiBaseNumber'] || '').replace('IPI:', '');
        assignorIpNameKey = assignor['ipNameKey'] || '';
        assignorSocietyCode = assignor['prSociety'];
        assignorPartyId = attributes['assignorPartyId'];
        assignorPartyNameId = attributes['assignorPartyNameId'];
        assignorIcePartyId = (assignor?.baseKey || '').replace('ICE:', '');
      }

      if (assignee) {
        assigneeIpiNameNumber = (assignee['ipiNameNumber'] || '').replace('IPI:', '');
        assigneeName = assignee['name'] || '';
        assigneeType = assignee['nameType'] || '';
        assigneeipiBaseNumber = (assignee['ipiBaseNumber'] || '').replace('IPI:', '');
        assigneeIpNameKey = assignee['ipNameKey'] || '';
        assigneeSocietyCode = assignee['prSociety'];
        assigneePartyId = attributes['assigneePartyId'];
        assigneePartyNameId = attributes['assigneePartyNameId'];
        assigneeIcePartyId = (assignee?.baseKey || '').replace('ICE:', '');
      }

      noRecipientGroups = groups?.filter(group => group.relation !== RELATIONTYPES.RECIPIENT) || [];
      const recipientGroups = (groups && groups.filter(group => group.relation === RELATIONTYPES.RECIPIENT)) || [];
      relatedAgreementsRecipients = recipientGroups.map(group => ({ related_agreement_type: RELATED_AGREEMENT_TYPES.RECIPIENT, related_agreement_number: group.groupId })) || [];
      if (relations) {
        relatedAgreementsCoPub =
          relations
            .filter(relation => relation.relation === RELATIONTYPES.COPUB)
            .map(relation => ({ related_agreement_type: RELATED_AGREEMENT_TYPES.COPUBLISHER, related_agreement_number: last(relation.otherId.split(':')) })) || [];
        identifiers =
          relations
            .filter(relation => relation.relation === RELATIONTYPES.XREF && startsWith(relation.otherId, 'PAREF:'))
            .map(relation => ({ submitter_agreement_number: last(relation.otherId.split(':')) })) || [];
      }
    }
    return {
      agreement_id: agreementId,
      key,
      type_of_agreement: agreementType,
      prior_royalties: priorRoyaltiesStartDate,
      confirmed_termination_date: confirmedTerminationDate,
      post_term_collection_date: postTermCollectionDate,
      sales_manufacture: salesOrManufacture,
      transfer_works: transferWorks,
      share_in_op_assignor_only: shareInOpAssignorOnly,
      assignor: {
        assignor_type: assignorType,
        assignor_name: assignorName,
        assignor_society_code: assignorSocietyCode,
        assignor_hidden_type: assignorType,
        assignor_ipi_name_number: assignorIpiNameNumber,
        assignor_base_ipi_number: assignoripiBaseNumber,
        assignor_ip_name_key: assignorIpNameKey,
        assignor_base_key: assignorIcePartyId,
      },
      gema_remuneration_claim_indicator: gemaRemuneration,
      identifiers,
      related_agreements: [...relatedAgreementsRecipients, ...relatedAgreementsCoPub].slice(0, 10),
      no_recipient_groups: noRecipientGroups,
      shares: formattedShares,
      start_date: startDate,
      expected_termination: expectedTerminationDate,
      assignee_name: assigneeName,
      assignee_society_code: assigneeSocietyCode,
      msgValidation: '', // ?
      ipi_name_number: submitterPartyNameId,
      submitterPartyNameIds: submitterPartyNameIds || [],
      agreement_relations: (agreementRelations && agreementRelations.filter(relation => relation.relation !== 'COPUB' && !relation.otherId.includes('PAREF:'))) || [],
      assignee_ip_name_key: assigneeIpNameKey,
      assignor_dummy_party_id: assignorPartyId,
      assignee_dummy_party_id: assigneePartyId,
      assignor_dummy_party_name_id: assignorPartyNameId,
      assignee_dummy_party_name_id: assigneePartyNameId,
      assignee_ipi_name_number: assigneeIpiNameNumber,
      assignee_base_ipi_number: assigneeipiBaseNumber,
      assignor_base_ipi_number: assignoripiBaseNumber,
      assignor_hidden_type: assignorType,
      agreement_name: agreementName,
      agreement_dispute: agreementDispute,
      writer_mechanical_reason: writerMechanicalReason,
      usa_license_indicators: usaLicenseIndicator,
      library_music: libraryMusic ? 'y' : 'n',
      recording_prefix: recordingPrefix || '',
      administrator,
      // implied_on_behalf_of: 'on_behalf_of',
      assignor_ipi_name_number: assignorIpiNameNumber,
      assignor_name: assignorName,
      additional_information: additional,
      workCount,
      retention,
      assignor_base_key: assignorIcePartyId,
      assignee_base_key: assigneeIcePartyId,
      inter_company: interCompany,
    };
  }

  static formatSharesForUpdate(shares: AgreementShares[]) {
    return shares.map(share => {
      const shareIn = share['shareIn'] && share['shareIn'] > 0 ? share['shareIn'] : 0;
      const shareOut = share['shareOut'] && share['shareOut'] > 0 ? share['shareOut'] : 0;

      let creator = share['shareOut'] > 0 ? 100 - share['shareOut'] / 100 : 100;
      let assigneeShare = (shareIn * shareOut) / 1000000;
      let publisher = 100 - assigneeShare - creator;
      let noShareout = false;

      if (shareOut === 0) {
        creator = 0;
        publisher = 0;
        assigneeShare = shareIn / 100;
        noShareout = true;
      }

      const type = share['rights'] && this.getRightType(share['rights']);
      const inclusion = this.getUsageTypes(share['rights'], type);

      return {
        type,
        startDate: share.startDate,
        endDate: share.endDate,
        priorRoyaltiesDate: share.priorRoyaltiesDate,
        postTermCollectionDate: share.postTermCollectionDate,
        creator: creator.toFixed(2),
        assignee: assigneeShare.toFixed(2),
        publisher: publisher.toFixed(2),
        territory: share['territories'].join(' '),
        inclusion,
        noShareout,
      };
    });
  }

  static getAgreementSubmitterIpiNameNumber(relations: any[]): string {
    const xrefItemPB = find(relations, item => item['otherId'] && item['otherId'].split(':')[0].substring(0, 2) === 'PB');
    return xrefItemPB && xrefItemPB.otherId.split(':')[0].slice(2);
  }

  static getRightType(usageTypes: string[]): string {
    if (intersection(usageTypes, mrRights).length > 0) {
      return 'Mechanical';
    } else if (intersection(usageTypes, prRights).length > 0) {
      return 'Performing';
    } else if (intersection(usageTypes, syRights).length > 0) {
      return 'Synchronization';
    } else if (intersection(usageTypes, rpRights).length > 0) {
      return 'Print';
    }
  }

  static getUsageTypes(usageTypes: string[], rightType: string): string {
    switch (rightType) {
      case 'mechanical':
        return difference(rightType, usageTypes).length === 0 ? 'ALL' : null;
      case 'performing':
        return difference(prRights, usageTypes).length === 0 ? 'ALL' : null;
      case 'synchronisation':
        return difference(syRights, usageTypes).length === 0 ? 'ALL' : null;
      case 'print':
        return difference(rpRights, usageTypes).length === 0 ? 'ALL' : null;
    }
    return usageTypes.join(',');
  }

  static getAgreementKey(relations: any[]): { key: string }[] {
    const keyRelation = relations.find(rel => rel.relation === 'DISP' && rel.otherId.startsWith('ICE'));
    return keyRelation.otherId;
  }

  static getRetentionsHtmlTooltip(retentions: string[], translate): string {
    const html = `<div style="display: flex; flex-direction: column">
                    <table class="tooltip-table">
              ${map(
                retentions.filter(retention => !!retention),
                retention => {
                  return `<tr>
                          <td class="text-align-left">${translate.instant('IS_RETAINED_BY')}</td>
                          <td class="text-align-left cell-padding"><a href="/copyright/agreements/${retention}/details" target="_blank">${retention}</a></td>
                        </tr>`;
                },
              ).join('')}
                    </table>
                  </div>`;
    return html;
  }

  static getConflictsTooltip(agreement: AgreementDetailCleaned, translate, addAccessLink: boolean): string {
    const counterclaims = get(agreement, 'counterclaims', []);
    const maxConflicts = counterclaims.length > MAX_CONFCLITS_IN_TOOLTIP ? MAX_CONFCLITS_IN_TOOLTIP : counterclaims.length;

    const conflictRows =
      counterclaims.length > 0
        ? counterclaims
            .slice(0, maxConflicts)
            .map(cc => this.getConflictRow(cc, translate, addAccessLink))
            .join('')
        : '';
    const accessLink = addAccessLink
      ? `<a href="/conflicts/agreement-conflict/search?entityType=agreement&agreementId=${agreement?.attributes?.id}" target="_blank">
        ${translate.instant('SHOW_CONFLICT_RESULTS')} (${counterclaims.length})
      </a>`
      : `${translate.instant('CONFLICT_RESULTS')} (${counterclaims.length})`;
    return `
      <div style="display: flex; flex-direction: column; padding-bottom: 2px;">
        <table class="tooltip-table">
          ${conflictRows}
        </table>
        ${accessLink}
      </div>`;
  }

  static getConflictsTooltipOffsetY(agreement: AgreementDetailCleaned) {
    return get(agreement, 'counterclaims', []).length > 1 ? 90 : -5;
  }

  static getRetainedAgreementHtmlTooltip(retainedAgreements: string[], translate): string {
    const retainedAgreementsFiltered = retainedAgreements?.filter(retained => !!retained) || [];
    const html = `<div style="display: flex; flex-direction: column">
                    <table class="tooltip-table">
                        <tr>
                          <td>${translate.instant('RETENTION_AGREEMENT')}${retainedAgreementsFiltered.length > 0 ? ':' : ''}</td>
                        </tr>
              ${map(retainedAgreementsFiltered, retained => {
                return `<tr>
                          <td class="text-align-left">${translate.instant('RETAINS')}</td>
                          <td class="text-align-left cell-padding"><a href="/copyright/agreements/${retained}/details" target="_blank">${retained}</a></td>
                        </tr>`;
              }).join('')}
                    </table>
                  </div>`;
    return html;
  }

  static joinRelationsAndGroups(detail: AgreementDetailCleaned) {
    const { groups, relations } = detail;
    return [
      ...(relations || []).map(relation => ({ ...relation, type: AGREEMENT_XREF_TYPE.RELATION })),
      ...(groups || []).map(group => ({ ...group, type: AGREEMENT_XREF_TYPE.GROUP })),
    ];
  }

  static formatAgreementSubmitter(submitterPartyNameIds: string[], submitterPartyRelations: any[]) {
    if (!submitterPartyNameIds.length) {
      return;
    }
    let name = '';
    return submitterPartyNameIds.map(submitter => {
      for (const submitterPartyRelation of submitterPartyRelations) {
        const matchingRelations = submitterPartyRelation.relations.filter(relation => relation.otherId === submitter);
        if (matchingRelations.length > 0) {
          name = IpUtils.getIpFullName(submitterPartyRelation.attributes);
          break;
        }
      }
      const submitterSplitted = (submitter && submitter.split(':')) || null;
      return (submitterSplitted && submitterSplitted.length > 1 && { label: submitterSplitted[0], name, value: submitterSplitted[1] }) || { label: '', name, value: submitter };
    });
  }

  static getAgreementGroupQueryByRelationType(type: string, value: string, comparator?: string) {
    const groupCondition = { 'groups[GROUP].group.relations[XREF].otherId': value };
    const recipientCondition = { 'groups[RECIPIENT].group.relations[XREF].otherId': value };
    return {
      and: [{ [comparator ? comparator : 'equals']: type === 'GROUP' ? groupCondition : recipientCondition }],
    };
  }

  static getBadgeFromSearchAgreementConflicts(search, userName): number {
    const length = get(search, 'items.length', 0);
    const today = moment();
    let badge = 0;
    if (length) {
      search.items.map(cc => {
        const { actions, attributes } = cc;
        if (!isEmpty(actions) && actions[0].attributes.assignor === userName) {
          const deadline = CounterClaimUtils.getCurrentDeadlineFromActions(actions);
          if (moment(deadline).isBefore(today) && [CC_STATUS_POTENTIAL_CONFLICT, CC_STATUS_POSS_OVERLAP].includes(attributes.status)) {
            badge++;
          } else if (this.isCCResolvedBySystem(cc)) {
            badge++;
          }
        }
      });
    }
    return badge;
  }

  static isCCResolvedBySystem(cc) {
    return get(cc, 'attributes.status', null) === CC_STATUS_RESOLVED;
  }

  static getAgreementApplicationWork(agreementApplication) {
    const { work, ...rawItem } = agreementApplication;
    return work && { ...work, rawItem };
  }

  /*
  Checks if the agreement new was merged, which is by using the targetIdVersion and comparing the 2 values.  See CUBE-12465
   */
  static getAgreementMergedId(response): string {
    const targetIdVersion = get(response, 'events[0].targetIdVersion', '');
    const idVersion = get(response, 'events[0].idVersion', '');
    const cleanedTargetIdVersion = CopyrightUtils.clearNamespaceAndVersion(targetIdVersion);
    const cleanedIdVersion = CopyrightUtils.clearNamespaceAndVersion(idVersion);
    //Numeric comparation 00000100 === 100
    return +cleanedTargetIdVersion !== +cleanedIdVersion ? cleanedTargetIdVersion : null;
  }

  static getAgreementConflictIcon(agreement: AgreementDetailCleaned, translate: TranslateService): IconInterface {
    const agreementCounterClaimStatus = CounterClaimUtils.getAgreementCounterClaimStatus(agreement?.counterclaims || []);
    const conflictIcons = AgreementUtils.generateDisputeIcon(agreementCounterClaimStatus, translate);
    const conflictIcon = conflictIcons.length > 0 ? conflictIcons[0] : { icon: 'crop_square', text: translate.instant('AGREEMENTS.NO_CONFLICT'), class: '' };

    return conflictIcon;
  }

  static comparePTCTerminationDates(endDate: string, postTermCollectionDate: string) {
    if (endDate === END_DATE_NO_DISPLAY && postTermCollectionDate === END_DATE_NO_DISPLAY) {
      return {
        endDateUpdated: '',
        postTermCollectionDateUpdated: '',
      };
    }
    return {
      endDateUpdated: endDate,
      postTermCollectionDateUpdated: postTermCollectionDate,
    };
  }

  static findEndDate(detail: AgreementDetailCleaned): string | null {
    const shares = get(detail, 'attributes.shares', []);
    if (shares.length === 0) {
      return null;
    }
    const comparator = (dateStart: string, dateEnd: string) => dateStart < dateEnd;
    const extremeDate = shares.reduce((currentExtremeDate, item) => (comparator(item.endDate, currentExtremeDate) ? item.endDate : currentExtremeDate), shares[0].endDate);
    return extremeDate;
  }

  static orderUIByLabel(data: any) {
    const labelOrder = ['Agreement Start Date', 'Expected Termination Date', 'Prior Royalties', 'Confirmed Agreement Termination Date', 'Post-term Collection'];
    const sortByLabel = (a, b) => {
      const indexA = labelOrder.indexOf(a?.label);
      const indexB = labelOrder.indexOf(b?.label);

      return indexA !== -1 && indexB !== -1 ? indexA - indexB : indexA !== -1 ? -1 : indexB !== -1 ? 1 : 0;
    };

    const sortedData = (data ?? []).sort(sortByLabel);
    return sortedData;
  }

  static formatPrefilledModel(model) {
    const formattedModel = omit(cloneDeep(model), [
      'agreement_id',
      'agreement_name',
      'agreement_dispute',
      'administrator',
      'agreement_relations',
      'assignor',
      'assignor_base_ipi_number',
      'assignor_base_ipi_number',
      'assignor_base_key',
      'assignor_dummy_party_id',
      'assignor_dummy_party_name_id',
      'assignor_hidden_type',
      'assignor_ipi_name_number',
      'assignor_name',
      'key',
      'no_recipient_groups',
      'related_agreements',
      'submitterPartyNameIds',
      'workCount',
    ]);
    formattedModel.assignor = {
      assignor_type: IpNature.LEGAL_ENTITY,
    };
    formattedModel.identifiers = [{}];
    formattedModel.related_agreements = [{ related_agreement_type: '' }];
    return formattedModel;
  }

  private static getConflictRow(counterclaim, translate, addAccessLink: boolean): string {
    const { agreementId, counterclaimId } = counterclaim;
    const type = get(counterclaim, 'counterclaim.attributes.status', '');
    const cleanedAgreementId = agreementId.replace('CUBE:', '');
    let typeString;
    switch (type) {
      case CounterClaimConflictStatus.OPEN:
        typeString = translate.instant('AGREEMENT-CONFLICT.STATUS_OVERLAP');
        break;
      case CounterClaimConflictStatus.POTENTIAL_CONFLICT:
        typeString = translate.instant('AGREEMENT-CONFLICT.STATUS_CONFLICT');
        break;
      case CounterClaimConflictStatus.CONFIRMED:
        typeString = translate.instant('AGREEMENT-CONFLICT.STATUS_DISPUTE');
        break;
      case CounterClaimConflictStatus.RESOLVED:
        typeString = translate.instant('AGREEMENT-CONFLICT.STATUS_RESOLVED');
        break;
      case CounterClaimConflictStatus.CLOSED:
        typeString = translate.instant('AGREEMENT-CONFLICT.STATUS_CLOSED');
    }
    return `
    <tr>
      <td class="text-align-left">${typeString}</td>
      <td class="text-align-left cell-padding">
        ${addAccessLink ? `<a href="/conflicts/agreement-conflict/${counterclaimId}/details" target="_blank">${cleanedAgreementId}</a>` : cleanedAgreementId}
      </td>
    </tr>`;
  }
}
