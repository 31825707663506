import { checkMatchRow, DateTimeUtils } from '@ice';
import { ALL, ICEADMIN_ORIGIN, PUBLISHER_PREFIX, SOCIETY_PREFIX } from 'config/constants/global.constants';
import { EVENT_ID, ROW_MATCH, WORK_SECTION } from 'config/constants/works.constants';
import { SectionsConfig } from 'config/sections-config';
import { concat, filter, find, first, get, includes, isArray, isEqual, isNaN, isObject, keys, padStart, startsWith, toPairs, values } from 'lodash';
import moment from 'moment';
import { SocietiesUtils } from '../societies/societies.utils';

export class AuditHistoryUtils {
  static creationAction = 'C';
  static COUNTERCLAIMTYPE = 'Counterclaim';
  static COUNTERCLAIMEVENTID = '60101';
  static cleanEventTypes(eventTypes) {
    return (
      (eventTypes &&
        concat(
          [{ value: null, label: '' }],
          keys(eventTypes).map(key => ({ value: eventTypes[key], label: key })),
        ).sort((a, b) => (a.label > b.label ? 1 : -1))) ||
      []
    );
  }

  static formatAuditHistoryJoinXrefs(value, translate, section: string, eventDescriptionFilter?) {
    const events = [...value.items];
    const auditHistory = value && value.items;
    if (auditHistory?.length > 0) {
      return auditHistory?.map(audit => this.formatAuditHistoryItem(audit, translate, section, eventDescriptionFilter)).filter(audit => !!audit.eventDescription);
    }

    return events;
  }

  static formatAuditHistoryItem(auditHistoryItem, translate, section: string, eventDescriptionFilter?) {
    let filteredEvents =
      eventDescriptionFilter && eventDescriptionFilter !== ALL ? auditHistoryItem.events.filter(event => event.nameId === `${eventDescriptionFilter}`) : auditHistoryItem.events;
    if (section === WORK_SECTION && auditHistoryItem.attributes.type === this.COUNTERCLAIMTYPE) {
      filteredEvents = filteredEvents.filter(event => event.nameId === this.COUNTERCLAIMEVENTID);
    }
    const itemSection = get(auditHistoryItem, 'attributes.type');
    const version = this.getItemVersion(get(auditHistoryItem, 'events', []), itemSection) || get(auditHistoryItem, 'attributes.version', '');
    const detailEvents = filteredEvents.map(event => {
      const newValue = this.getAuditEventValue(event.name, event.newAttributes);
      const oldValue = this.getAuditEventValue(event.name, event.previousAttributes);
      const newValueLines = newValue?.split('<br>').length;
      const oldValueLines = oldValue?.split('<br>').length;

      return {
        userId: '',
        version,
        eventDescription: event.name,
        action: event.action,
        newValue: newValueLines < 3 ? newValue : newValue?.split('<br>').join('; '),
        oldValue: oldValueLines < 3 ? oldValue : oldValue?.split('<br>').join('; '),
        hideCell: true,
      };
    });

    const eventInfo =
      detailEvents.length > 1
        ? {
            eventDescription: `<div class="has-changes-indicator"> ${detailEvents.length} ${translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.TITLE')} </div>`,
            action: null,
            newValue: `<div class="has-changes-indicator"> ${translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.MULTIPLE_CHANGES')} </div>`,
            oldValue: null,
            addNote: false,
          }
        : { ...detailEvents[0] };
    const createdDate = get(auditHistoryItem, 'attributes.createdDate');
    const hasNote = !!get(auditHistoryItem, 'admin.typeId');
    const note = auditHistoryItem.admin?.attributes?.notes;

    const noteIcon = [
      {
        icon: hasNote ? 'description' : 'note_add',
        class: `audit-note-icon ${hasNote ? 'audit-has-not-note-icon' : 'audit-has-note-icon'}`,
        onClickAction: event => event.stopPropagation(),
      },
    ];
    const user = get(auditHistoryItem, 'attributes.userid', '');
    const userOnBehalfOf = this.formatuserOnBehalfOf(auditHistoryItem, translate);
    const publisherOnBehalfOf = this.publisherOnBehalfOf(auditHistoryItem);
    const userToShow =
      userOnBehalfOf || publisherOnBehalfOf
        ? `${user} ${translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.CUBE_AUDIT_ID.BEHALF_OF')} ${userOnBehalfOf || publisherOnBehalfOf}`
        : user;
    const formattedAuditHistoryItem = {
      date: createdDate && DateTimeUtils.formatDateTime(moment(createdDate)),
      ...eventInfo,
      userId: userToShow,
      version,
      cubeAuditId: get(auditHistoryItem, 'events[0].cubeAuditId'),
      hasNote,
      noteIcon,
      note,
      detail: detailEvents.length > 1 ? detailEvents : [],
    };

    return this.formatCustomAuditEvent(auditHistoryItem, formattedAuditHistoryItem);
  }

  static formatuserOnBehalfOf(auditHistoryItem, translate) {
    const userOnBehalfOf = (get(auditHistoryItem, 'attributes.parentTypeid', '') || '_').split('_')[1];
    if (userOnBehalfOf.includes(ICEADMIN_ORIGIN)) {
      return ICEADMIN_ORIGIN;
    }
    if (startsWith(userOnBehalfOf, SOCIETY_PREFIX)) {
      return SocietiesUtils.getRelationSocietiesName(padStart(userOnBehalfOf.split(':')[0].replace(SOCIETY_PREFIX, ''), 3, '0')).name;
    }
    if (startsWith(userOnBehalfOf, PUBLISHER_PREFIX)) {
      return `${translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.PUBLISHER_ON_BEHALF_OF')} ${userOnBehalfOf.split(':')[0].replace(PUBLISHER_PREFIX, '')}`;
    }
    return '';
  }

  static publisherOnBehalfOf(auditHistoryItem = {}) {
    const userOnBehalfOf = get(auditHistoryItem, 'attributes.publisherName');
    return userOnBehalfOf ? `${userOnBehalfOf}` : '';
  }

  static formatCustomAuditEvent(originalAuditHistoryItem, formattedAuditHistoryItem) {
    const firstEventTypeId = get(originalAuditHistoryItem, 'events[0].nameId');
    switch (firstEventTypeId) {
      case '11002': // Conflict Assigned
        const firstEventNewAttributes = get(originalAuditHistoryItem, 'events[0].newAttributes');
        const { conflictSubType, conflictId, userId } = firstEventNewAttributes;
        const workIceId = first((conflictId || '').split('_'));
        const secondRow =
          conflictSubType || workIceId ? `<br><div class="ice-pt-5">${conflictSubType || ''}${workIceId ? `${conflictSubType ? `, ` : ''}${workIceId}` : ''}</div>` : '';
        return {
          ...formattedAuditHistoryItem,
          eventDescription: `${formattedAuditHistoryItem.eventDescription}${secondRow}`,
          newValue: userId,
          oldValue: get(originalAuditHistoryItem, 'events[0].previousAttributes.userId'),
        };
    }
    return formattedAuditHistoryItem;
  }

  static getAuditEventValue(description, valueObject) {
    if (valueObject) {
      switch (description) {
        case 'Work Title Changed':
          const { titles } = valueObject;
          return keys(titles)
            .map(key => `${key}=${titles[key]?.map(value => value?.titleValue).join(', ')}`)
            .join('<br>');
        case 'Work Society Marker Changed':
          return keys(valueObject)
            ?.map(key => `${key}=${valueObject[key]?.join(', ')}`)
            .join('<br>');

        case 'Agreement Shares Changed':
          const { shares } = valueObject;
          return shares
            ?.map(share =>
              keys(share)
                ?.map(key => `${key}=${isArray(share[key]) ? share[key].join(', ') : isObject(share[key]) ? '{}' : share[key]}`)
                .join('; '),
            )
            .join('<br>');
        case 'Work Share Picture Changed':
          return JSON.stringify({ ...valueObject?.sharePicture, ...valueObject });
        case 'Work Source Changed':
        case 'Work Duration Changed':
        case 'Work Owner Change':
        case 'Work Status Changed':
        case 'Agreement Intercompany Status Changed':
        case 'Work Purpose Changed':
        case 'Work Language Changed':
          return keys(valueObject)
            ?.map(key => `${key}=${valueObject[key]}`)
            .join('<br>');
      }
      return JSON.stringify(valueObject);
    }
    return null;
  }

  static getAuditHistoryEvents(patchObject, path = '') {
    const arrayObject = isArray(patchObject);
    return (
      (isObject(patchObject) &&
        toPairs(patchObject)
          .filter(([key, value]) => !!value)
          .reduce((acc, [key, value]: [string, any]) => {
            const newPath = arrayObject ? path : path ? `${path}.${key}` : key;
            if (value.type) {
              return [
                ...acc,
                {
                  jsonData: value,
                  operation: value.type,
                  path: newPath,
                },
              ];
            } else if (value.op) {
              return [
                ...acc,
                {
                  jsonData: value,
                  operation: value.op,
                  path: `${newPath}.${value.path.substring(1)}`,
                },
              ];
            } else {
              return [...acc, ...this.getAuditHistoryEvents(value, newPath)];
            }
          }, [])) ||
      []
    );
  }

  static getItemVersion(itemDetail: any[], section: string = '') {
    switch (section.toLowerCase()) {
      case first(SectionsConfig.WORKS.auditTypes):
        return this.getItemVersionWork(itemDetail);

      case first(SectionsConfig.AGREEMENTS.auditTypes):
      case first(SectionsConfig.AGREEMENT_GROUP.auditTypes):
        return this.getItemVersionCommon(itemDetail);

      default:
        return undefined;
    }
  }

  static getItemVersionWork(itemDetail: any[]) {
    const mainItem = this.getAuditHistoryMainItem(itemDetail);
    const mainItemAction = get(mainItem, 'action');
    if (isEqual(mainItemAction, this.creationAction)) {
      return get(mainItem, 'newAttributes.sharePicture.version', '');
    } else {
      return first(values(get(mainItem, 'newAttributes', {})));
    }
  }

  static getItemVersionCommon(itemDetail: any[]) {
    const mainItem = this.getAuditHistoryMainItem(itemDetail);
    return get(mainItem, 'newAttributes.version.version', get(mainItem, 'newAttributes.version', get(mainItem, '[0].newAttributes.version', get(mainItem, 'version', 0))));
  }

  static getAuditHistoryMainItem(itemDetail) {
    const reMasteredLabel = 're-mastered';
    const mainItem =
      itemDetail.length > 1
        ? find(
            itemDetail,
            item => isEqual(get(item, 'action', ''), this.creationAction) || includes(get(item, 'name', ''), reMasteredLabel) || get(item, 'newAttributes.version.version', null),
          )
        : first(itemDetail);
    return mainItem;
  }

  static filterAuditHistoryVersion(auditHistory: any[], version: number) {
    return isNaN(version) ? auditHistory : filter(auditHistory, ['version', version]);
  }

  static applyAuditIdFilter(items: any[], filterValues: any): any[] {
    const eventId = get(filterValues, EVENT_ID);
    if (eventId) {
      const item = find(items, { cubeAuditId: eventId });
      if (item) {
        item['rowClass'] = ROW_MATCH;
        setTimeout(() => {
          checkMatchRow();
        }, 250);
      }
    }
    return items;
  }

  static searchSubString(item: any, highlightValue = false, changes = []): string {
    let yellowClass = '';
    const changesAreModifications = () => {
      return changes.some(change => change.kind !== 'N');
    };
    const itemHasChange = () => {
      return changes.some(change => {
        if (Array.isArray(change.lhs)) {
          return change.lhs.some(newChange => newChange === item);
        } else {
          return item === change.lhs;
        }
      });
    };
    if (!highlightValue || !changesAreModifications() || typeof item === 'object') {
      return yellowClass;
    }
    yellowClass = itemHasChange() ? 'yaml-changes' : '';
    return yellowClass;
  }

  static formatToYAML(jsonObject, highlightValue = false, changes = []): string {
    const entries = Object.entries(jsonObject);
    let valueToReturn = '';
    const formatObjectToYAML = (item: any) => {
      const yellowClass = this.searchSubString(item, highlightValue, changes);
      return typeof item === 'object' ? this.formatToYAML(item, highlightValue, changes) : `<span class="format-yaml-font ${yellowClass}">${item};</span><br>`;
    };
    entries.forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const newLine = value[0] === undefined || typeof value[0] === 'object' ? `= <br>` : `=`;
        valueToReturn += `<strong class="format-yaml-font">${key}${newLine} </strong>`;
        value.forEach(item => {
          valueToReturn += `${formatObjectToYAML(item)}`;
        });
      } else if (entries.length === 1 && typeof value !== 'object') {
        const yellowClass = this.searchSubString(value, highlightValue, changes);
        valueToReturn += `<span class="format-yaml-font ${yellowClass}">${value}</span><br>`;
      } else {
        const keyValue = typeof value !== 'object' && value !== null ? `<strong class="format-yaml-font">${key}= </strong>` : '';
        valueToReturn += `${keyValue}${formatObjectToYAML(value)}`;
      }
    });
    return valueToReturn;
  }

  static formatStringToYAML(inputValue: string, oldValue: string, highlightValue = false): string {
    const cssChanges = highlightValue ? 'yaml-changes' : '';
    const difference = inputValue
      .split(' ')
      .filter(x => !oldValue.split(' ').includes(x))
      .join(' ');
    const replaceStringValues = (stringToReplace, part, replacement) => stringToReplace.split(part).join(replacement);
    const yamlFormatted = replaceStringValues(inputValue, difference, `<span class="format-yaml-font ${cssChanges}">${difference}</span>`);
    return yamlFormatted;
  }
}
