import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ActivityDetail,
  AgreementDetail,
  CopyrightDetail,
  CopyrightDetailRequest,
  CopyrightTerritoryDetail,
  CopyrightUtils,
  CounterClaimDetail,
  IpDetail,
  RepertoireDetail,
  SearchResult,
  WorkDetail,
  selectControlValue,
} from '@ice';
import { UsersUtils } from '@ice/utils/users/users.utils';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApiParams } from 'config/constants/global.constants';
import { OrganizationType } from 'config/constants/organizations.constants';
import { DEFAULT_SEARCH_FROM, MAX_SEARCH_SIZE } from 'config/constants/search.constants';
import { environment } from 'config/env';
import { SectionsConfig } from 'config/sections-config';
import { ApiCall, RequestType } from 'config/sections-config/api-call';
import { get, isObject } from 'lodash';
import { OrganizationDetail } from 'models/copyright/detail/organizations';
import { SocietyDetail } from 'models/copyright/detail/society';
import { Observable, of } from 'rxjs';
import { RootState } from 'store/root';
import { AuthService } from '../auth/auth.service';
import { CommonApiService } from '../common-api.service';
import { MaskedIdsService } from './../masked-ids/masked-ids.service';

@Injectable()
export class DetailService extends CommonApiService {
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected store: Store<RootState>,
    protected route: ActivatedRoute,
    protected maskedIdsService: MaskedIdsService,
    protected actions$: Actions,
  ) {
    super(http, authService, store, route, maskedIdsService, actions$);
  }

  static getIdFromResponse(response): string {
    return get(response, 'identifier') || get(response, 'id') || get(response, 'events[0].data.requestId', null) || get(response, 'masterEvent.targetIdVersion', '').split('_')[0];
  }

  getHttp() {
    return this.http;
  }

  getDetail(request: CopyrightDetailRequest): Observable<CopyrightDetail | CopyrightTerritoryDetail | ActivityDetail | OrganizationDetail> {
    const { key, id, ns, section, customInclude, currentOrganization } = request;
    const sectionConfig = CopyrightUtils.getSectionData(section);
    const { baseUrlCubeData, detailSegment, apiIncludes } = sectionConfig;
    const includesDetail = customInclude || apiIncludes.detail;
    const include = includesDetail ? (isObject(includesDetail) ? (includesDetail[ns] ? includesDetail[ns] : includesDetail['default']) : includesDetail) : '';
    const urlCubeData = `${baseUrlCubeData}/${(detailSegment || '').replace(/<<ns>>/, ns).replace(/<<id>>/, key || id)}`;
    const params = { include, ns };
    const isPublisher = currentOrganization?.type === OrganizationType.publisher;
    switch (section) {
      case SectionsConfig.COUNTERCLAIMS.name:
        if (currentOrganization?.accessPartyNames) {
          params[ApiParams.ORG_PARTY_NAMES] = currentOrganization.accessPartyNames;
        }
        return this.getRequestCubeData<CounterClaimDetail>(urlCubeData, params);
      case SectionsConfig.WORKS.name:
        if (isPublisher && currentOrganization?.accessPartyNames) {
          params[ApiParams.ORG_PARTY_NAMES] = currentOrganization.accessPartyNames;
        }
        return this.getRequestCubeData<WorkDetail>(urlCubeData, params);
      case SectionsConfig.IPS.name:
        params[ApiParams.FOLLOW_REDIRECTS] = false;
        return this.getRequestCubeData<IpDetail>(urlCubeData, params);
      case SectionsConfig.AGREEMENTS.name:
        return this.getRequestCubeData<AgreementDetail>(urlCubeData, params);
      case SectionsConfig.REPERTOIRES.name:
        return this.getRequestCubeData<RepertoireDetail>(urlCubeData, params);
      case SectionsConfig.SOCIETIES.name:
        const urlSocieties = `${baseUrlCubeData}/${detailSegment.replace(/<<ns>>/, 'CISAC').replace(/<<id>>/, key || id)}`;
        return this.getRequestCubeData<SocietyDetail>(urlSocieties, params);
      case SectionsConfig.TERRITORIES.name:
        const body = sectionConfig.body?.replace(/<<territoriesTisa>>/, key);
        return this.postRequestCubeData<any>(environment.territoriesTisnApiUrl, sectionConfig.detailParams, body);
      case SectionsConfig.AGREEMENTCONFLICT.name:
      case SectionsConfig.AGREEMENT_GROUP.name:
      case SectionsConfig.CONFLICTS.name:
        return this.getRequestCubeData<ActivityDetail>(urlCubeData, params);
      case SectionsConfig.ORGANIZATIONS.name:
      case SectionsConfig.USERS.name:
        const idSplitted = selectControlValue({ value: key }).split(':');
        if (idSplitted?.length > 1) {
          const nsUser = idSplitted[0];
          const urlOrganizationsOrUsers = `${baseUrlCubeData}/${detailSegment.replace(/<<ns>>/, nsUser).replace(/<<id>>/, key || id)}`;
          return this.getRequestCubeData<IpDetail | OrganizationDetail>(urlOrganizationsOrUsers, { ...params, ns: nsUser });
        }
        return of();
    }
  }

  workResolution(ns1: string, id1: string, ns2: string, id2: string, outcome: 'CONFIRMED' | 'NO_MATCH', cause: string, forcedMerge: boolean, list: string[]) {
    const apiSufix = cause === 'not specified' || forcedMerge ? '?timeout=0' : '/withRelation';
    return this.postRequestCubeData(`${environment.apiUrl}/${SectionsConfig.WORKS.name}/CUBE/${id1}/match/${ns2}/${id2}${apiSufix}`, {}, { cause, forcedMerge, outcome });
  }

  deleteWork(workId: string, ns: string) {
    return this.deleteRequestCubeData(`${environment.apiUrl}/works/${ns}/${workId}?sync=true`, {});
  }

  unmatchSourceWork(workId1: string, ns1: string, workId2: string, ns2: string) {
    return this.deleteRequestCubeData(`${environment.apiUrl}/${ns1}/${workId1}/works/${ns2}/${workId2}?sync=true`, {});
  }

  getOrganizationFromId(organizationId: string): Observable<any> {
    const organizations = SectionsConfig.ORGANIZATIONS.name;
    const sectionConfig = CopyrightUtils.getSectionData(organizations);
    const data = {
      isGet: true,
      url: `${sectionConfig.baseUrlCubeData}/${organizations}/${UsersUtils.getNamespaceFromOrganizationID(organizationId)}/${organizationId}`,
      queryParams: { include: 'attributes.name, attributes.access,roles' },
    };
    return this.getDetailExtraData(data);
  }

  getOrganizationsFromNS(ns: string): Observable<SearchResult> {
    const organizations = SectionsConfig.ORGANIZATIONS.name;
    const sectionConfig = CopyrightUtils.getSectionData(organizations);
    const data = {
      isGet: false,
      url: `${sectionConfig.baseUrlCubeData}/${organizations}/${ns}/search`,
      queryParams: { include: 'attributes.name,roles', size: MAX_SEARCH_SIZE, from: DEFAULT_SEARCH_FROM },
      query: `{"and": [{"wildcard": {"attributes.ns": "${ns}"}}]}`,
    };
    return this.getDetailExtraData(data);
  }

  getDetailExtraData(data: any): Observable<SearchResult> {
    const { url, queryParams, query, requestType, maskedIdsList, responseType } = data;
    if (requestType && requestType === 'put') {
      return this.putRequestCubeData(url, queryParams, JSON.parse(query), maskedIdsList);
    }
    if (!data.isGet) {
      return this.postRequestCubeData(url, queryParams, JSON.parse(query), maskedIdsList);
    }
    return this.getRequestCubeData(url, queryParams, maskedIdsList, responseType);
  }

  makeRequest(apiCall: ApiCall) {
    const { url, maskedIdsList, requestType, responseType, queryParams, body } = apiCall;
    switch (requestType) {
      case RequestType.PUT:
        return this.putRequestCubeData(url, queryParams, (body && JSON.parse(body)) || null, maskedIdsList);
      case RequestType.POST:
        return this.postRequestCubeData(url, queryParams, (body && JSON.parse(body)) || null, maskedIdsList);
      case RequestType.GET:
        return this.getRequestCubeData(url, queryParams, maskedIdsList, responseType);
      case RequestType.DELETE:
        // Check if body exists before parsing
        return this.deleteRequestCubeData(url, queryParams, maskedIdsList, body ? JSON.parse(body) : null);
      default:
        return of(null) as never;
    }
  }
}
