import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ClaimantUtils, ClaimGraphUtils, fieldConfig, IpUtils, SharePictureUtils, TerritoryUtils, WorkClaimsUtils, WorkDetail } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { DatepickerUtils, TabsUtils, LocalStorageUtils } from '@ice';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { EditType } from 'config/constants/global.constants';
import { WORK_CLAIM_SHARE_TYPE } from 'config/constants/shares.constants';
import { CopyrightWorkClaimsDataTable } from 'config/data-table-builders/copyright.work-claims';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { DialogSharesUsesTypes } from 'config/dialog-builders/dialog-shares-uses-types';
import { DialogClaimSteps } from 'config/stepper-builders/claim/dialog-claim-steps';
import type { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { IceFacade } from 'facades/ice.facade';
import { cloneDeep, get, isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';
import { DEFAULT_FILTER_MODEL } from 'config/constants/works.constants';
import { CLAIM_TYPES } from 'config/constants/repertoires.constants';

export class TabWorkClaims implements SectionTabConfig {
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: CommonApiService,
    private detailService: DetailService,
    private nsService: NamespaceService,
    private iceFacade: IceFacade,
    private fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
    private searchService: SearchService,
    private storeNewItem: Store<fromForm.NewSectionItemState>,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.schema = new CopyrightWorkClaimsDataTable(this.translate, this.fuseTranslationLoader, this.store, this.permissionsService);
    combineLatest([this.store.select(fromRoot.getEditMode), this.filteredWorkClaims$])
      .pipe(
        takeUntil(this.unsubscribeAll),
        filter(([editMode, filteredWorkClaims]) => editMode),
        map(([editMode, filteredWorkClaims]) => filteredWorkClaims),
        map(claims => claims.map(claim => claim.claimId)),
        map(claimIds => {
          const getDuplicateItems = (array: String[]) => array.filter((id, index) => claimIds.indexOf(id) !== index);
          return getDuplicateItems(claimIds);
        }),
        map(claimIdsScopedAcrossMultipleRows => new Set(claimIdsScopedAcrossMultipleRows)),
      )
      .subscribe(ids => (this.claimIdsRequiringDeleteConfirmation = ids));
    // save instantaneous filters to the store
    this.instantaneousFilters$
      .pipe(
        withLatestFrom(this.store.select(fromRoot.getAllClaimsFilter)),
        map(([instantaneousFilters, filters]) => ({ ...filters, ...instantaneousFilters })),
      )
      .subscribe(filters => this.store.dispatch(new fromRoot.SetAllClaimsFilter(filters)));
  }
  private claimIdsRequiringDeleteConfirmation: Set<String>;
  private unsubscribeAll: Subject<void> = new Subject<void>();
  private resetFilter$ = new BehaviorSubject(false);
  private hierarchySort$ = new BehaviorSubject(true);
  private schema: CopyrightWorkClaimsDataTable;
  private workClaimsMode$ = new BehaviorSubject(WORK_CLAIM_SHARE_TYPE.SHARES);
  private rightsIncludeView$ = new Subject();
  private updateUsageTypes: any;
  private workClaimsFilter$ = new BehaviorSubject({});
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  private updateDataTable = false;
  private updateDataTable$ = new BehaviorSubject(this.updateDataTable);
  private instantaneousFilters$ = new BehaviorSubject<Partial<WorkDetail['allClaimsFilter']>>({});
  private filteredWorkClaims$ = combineLatest([this.store.select(fromRoot.getWorkClaims), this.workClaimsFilter$, this.workClaimsMode$, this.instantaneousFilters$]).pipe(
    filter(([workClaims]) => !!workClaims.length),
    distinctUntilChanged(isEqual),
    map(([workClaims, claimsFilter, mode, instantaneousFilters]) => {
      const cleanedClaims = WorkClaimsUtils.workClaimsCleaner(cloneDeep(workClaims), this.translate, claimsFilter);
      const filteredClaims = WorkClaimsUtils.filterWorkClaims(cleanedClaims, { ...claimsFilter, ...instantaneousFilters }, mode, this.translate);
      const claimRows = WorkClaimsUtils.groupClaimsRows(filteredClaims);
      return claimRows;
    }),
  );
  onSubmitFiltersForm(model: typeof DEFAULT_FILTER_MODEL) {
    this.store
      .pipe(select(fromRoot.getUserDetail), withLatestFrom(this.resetFilter$, this.store.select(fromRoot.getAllClaimsFilter)), take(1))
      .subscribe(([userDetail, reset, previousAllClaimsFilter]) => {
        if (!reset) {
          LocalStorageUtils.setFilterWorkClaims(userDetail.cognitoUserName, model);
          // this model has less values than the one in all-claims tab, so we only update the existing values
          this.store.dispatch(new fromRoot.SetAllClaimsFilter({ ...previousAllClaimsFilter, ...model }));
        } else {
          this.resetFilter$.next(false);
        }
      });
    this.workClaimsFilter$.next({ ...model });
  }
  onDestroy = () => {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.instantaneousFilters$.complete();
  };

  getConf(): IceGroupComponent[] {
    const defaultFilterValues = {
      ...DEFAULT_FILTER_MODEL,
      status: [CLAIM_TYPES.VALIDATED, CLAIM_TYPES.SUBMITTED],
    };
    const getModel = () => {
      return this.resetFilter$.pipe(
        withLatestFrom(this.store.select(fromRoot.getAllClaimsFilter)),
        map(([reset, allClaimsFilter]) => {
          // The filters' values are determined in the following order:
          // 1. Redux state
          // 2. Default values
          const filters = {
            ...defaultFilterValues,
            ...allClaimsFilter,
          };
          this.workClaimsFilter$.next(filters);
          return filters;
        }),
      );
    };
    getModel()
      .pipe(takeUntil(this.unsubscribeAll), withLatestFrom(this.resetFilter$))
      .subscribe(([allClaimsFilter, resetFilter$]) => {
        this.store.dispatch(new fromRoot.SetAllClaimsFilter(resetFilter$ ? defaultFilterValues : allClaimsFilter));
      });

    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('WORKS.WORK_CLAIMS.TAB_TITLE'),
              class: 'ice-limit-form',
              customClass: 'work-submit-claims-table',
              model: this.filteredWorkClaims$,
              sorts: [{ prop: 'hierarchy', dir: 'asc' }],
              sortReset: true,
              disabledSort: this.hierarchySort$,
              onSort: sort => (get(sort, '[0].prop') === 'hierarchy' ? this.hierarchySort$.next(true) : this.hierarchySort$.next(false)),
              schema: combineLatest([this.updateDataTable$]).pipe(
                map(() =>
                  this.schema.getDataTable(
                    null,
                    claimId => this.openEditDialog(claimId),
                    claimId => {
                      const deleteClaim = () => this.updateClaim(claimId, EditType.DELETE);
                      if (this.claimIdsRequiringDeleteConfirmation.has(claimId)) {
                        this.openDeleteDialog(deleteClaim);
                        return;
                      }
                      deleteClaim();
                    },
                  ),
                ),
              ),
              visibleColumns: this.getVisibleColumns(this.schema.getDataTable(null, claimId => this.openEditDialog(claimId))),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              columnMode: 'flex',
              tableWidth: '100',
              optionsGroup: of({
                options: [
                  { value: WORK_CLAIM_SHARE_TYPE.SHARES, label: this.translate.instant('WORKS.ALL_CLAIMS.OPTIONS.COLLECTION'), checked: true },
                  { value: WORK_CLAIM_SHARE_TYPE.OWNERSHIP, label: this.translate.instant('WORKS.ALL_CLAIMS.OPTIONS.OWNERSHIP') },
                ],
                change: event => {
                  this.workClaimsMode$.next(event.value);
                },
              }),
              filter: {
                formClass: 'bg-filter-form ice-p-16',
                formBuilder: this.getWorkClaimsFilter(getModel),
                model: getModel(),
                onSubmit: model => this.onSubmitFiltersForm(model),
              },
              submitShortcutEnable: true,
              totals: {
                totalsTiles: ClaimGraphUtils.getTotalTitles(
                  this.filteredWorkClaims$,
                  (rows: any) => SharePictureUtils.getSharesTotals(rows),
                  this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_CREATOR_SUB_TOTAL'),
                  this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_PUBLISHER_SUB_TOTAL'),
                  this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_SPECIAL_SUB_TOTAL'),
                  this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_TOTAL'),
                ),
                class: of('work-claims-totals'),
              },
            },
          },
        ],
      },
    ];
  }
  private applyInstantaneousFilterOnChange(field: FormlyFieldConfig, key: keyof WorkDetail['allClaimsFilter']) {
    field.formControl.valueChanges.subscribe(value => {
      this.instantaneousFilters$.next({ ...this.instantaneousFilters$.value, [key]: value });
    });
  }

  getWorkClaimsFilter(getModel: () => Observable<any>) {
    const { translate } = this;
    let formData: FormGroup;

    const firstLine: FormlyFieldConfig = fieldConfig([
      {
        className: 'flex-4-no-mw',
        key: 'territory',
        type: 'ice-select',
        modelOptions: {
          updateOn: 'blur',
        },
        templateOptions: {
          placeholder: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_TERRITORY'),
          label: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_TERRITORY'),
          multiple: true,
          selectAllLabel: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.ALL_TERRITORIES'),
          selectAllOption: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.SELECT_ALL'),
          options: this.store.select(fromRoot.getAllTerritories).pipe(map(TerritoryUtils.getTerritoriesAutocompleteOptions)),
          useCountryShortcuts: true,
          filterActive: true,
          filterPlaceHolder: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FILTER_PLACEHOLDER'),
          change: (field, $event) => TerritoryUtils.cleanTerritorySelectorValue(field, $event.value, $event.lastValue),
          componentVersion: 1,
          clearAllLabel: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.CLEAR_ALL'),
          required: true,
        },
        hooks: {
          onInit: field => {
            formData = field.form;
            this.store
              .select(fromRoot.getAllTerritories)
              .pipe(
                filter(territories => !!territories),
                withLatestFrom(getModel()),
                take(1),
              )
              .subscribe(([territories, model]) => {
                // we need to set the value once the territories are loaded
                field.formControl.setValue(model.territory);
              });
          },
        },
      },
      {
        className: 'flex-1 ice-min-width-170',
        key: 'prRight',
        type: 'ice-select',
        templateOptions: {
          label: `${translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_PR_RIGHTS')} *`,
          options: IpUtils.getPrOptions([{ value: '', label: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.ALL_PR') }]),
          selectedLabelsAsValue: true,
        },
      },
      {
        className: 'flex-1 ice-min-width-170',
        key: 'mrRight',
        type: 'ice-select',
        defaultValue: null,
        templateOptions: {
          label: `${translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_MR_RIGHTS')} *`,
          options: IpUtils.getMrOptions([{ value: '', label: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.ALL_MR') }]),
          selectedLabelsAsValue: true,
        },
      },
      DatepickerUtils.getDatepickerField({
        key: 'usageDate',
        label: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_USAGE_DATE'),
        translate,
        infoText: translate.instant('WORKS.SHARE_PICTURE.FILTER.FIELD_USAGE_DATE_INFO'),
        extraClass: 'ice-min-width-140',
      }),
    ]);

    const checkboxButtons: FormlyFieldConfig[] = [
      {
        className: 'flex-1',
        key: 'removeE',
        type: 'checkbox',
        defaultValue: false,
        templateOptions: {
          indeterminate: false,
          label: translate.instant('WORKS.SHARE_PICTURE.FILTER.NO_E'),
          required: false,
        },
        hooks: {
          onInit: field => {
            this.applyInstantaneousFilterOnChange(field, 'removeE');
          },
        },
      },
      {
        className: 'flex-1',
        key: 'removeSE',
        type: 'checkbox',
        defaultValue: false,
        templateOptions: {
          indeterminate: false,
          label: translate.instant('WORKS.SHARE_PICTURE.FILTER.NO_SE'),
          required: false,
        },
        hooks: {
          onInit: field => {
            this.applyInstantaneousFilterOnChange(field, 'removeSE');
          },
        },
      },
    ];

    const formButtons = [
      {
        className: 'flex-1 mat-form-field-infix ice-accent',
        type: 'button',
        templateOptions: {
          text: translate.instant('FORM_RESET'),
          btnType: ' ice-accent',
          materialType: 'mat-button',
          onClick: () => {
            this.resetFilter$.next(true);
          },
        },
        hooks: {
          onInit: field => {
            formData = field.form;
          },
        },
      },
      {
        className: 'flex-1 mat-form-field-infix ice-accent',
        type: 'button',
        templateOptions: {
          text: translate.instant('WORKS.ALL_CLAIMS.SUBMIT'),
          color: 'accent',
          type: 'submit',
          onClick: (event, field) => {
            this.onSubmitFiltersForm(field.model);
          },
          materialType: 'mat-flat-button',
          isDisabled: () => formData?.invalid,
        },
      },
    ];

    const secondLine: FormlyFieldConfig = {
      fieldGroupClassName: 'display-flex ice-justify-right group-inputs ice-accent',
      fieldGroup: [...checkboxButtons, ...formButtons],
    };

    return [firstLine, secondLine];
  }

  openDeleteDialog(deleteClaim: () => void) {
    const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
    const msg = `<div>
         <p>${this.translate.instant('WORKS.WORK_CLAIMS.DELETE_DIALOG.MESSAGE.PARAGRAPH_1')}</p>
         <p>${this.translate.instant('WORKS.WORK_CLAIMS.DELETE_DIALOG.MESSAGE.PARAGRAPH_2')}</p>
       </div>`;

    const dialogRefInfo = dialogInfo.openDialog(
      this.translate.instant('WORKS.WORK_CLAIMS.DELETE_DIALOG.TITLE'),
      msg,
      () => {
        deleteClaim();
        dialogRefInfo.close();
      },
      () => dialogRefInfo.close(),
    );
  }

  openEditDialog(claimId) {
    this.store.dispatch(new fromForm.ResetNewSectionItem());
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      panelClass: 'work-claims-dialog-container',
      data: {
        className: 'dialog-wrapper-85vw-90vh dialog-edit-claim',
        layouts: [
          {
            title: of(this.translate.instant('WORKS.WORK_CLAIMS.UPLOAD_DIALOG.TITLE')),
            actions: [
              {
                tooltip: this.translate.instant('POPUP.CANCEL'),
                color: 'warn',
                icon: 'close',
                onClick: () => {
                  this.dialogRef.close();
                },
              },
              {
                tooltip: this.translate.instant('POPUP.CONFIRM'),
                icon: 'done',
                disabled: this.storeNewItem.select(fromForm.getNewSectionValidOrChangedForm).pipe(map(valid => !valid)),
                onClick: () => this.updateClaim(claimId, EditType.EDIT),
              },
            ],
            layout: [this.editDialogLayout(claimId)],
          },
          {
            title: of(this.translate.instant('WORKS.WORK_CLAIMS.UPLOAD_DIALOG.TITLE')),
            actions: [],
            layout: [this.includeRightsLayout()],
          },
        ],
      },
    });
  }

  includeRightsLayout(): IceGroupComponent {
    const popup = DialogSharesUsesTypes.getPopupLabels(this.translate);
    const usesTypes = DialogSharesUsesTypes.getUsesTypes(this.translate);
    return {
      group: [
        {
          type: 'formly',
          config: {
            formBuilder: this.rightsIncludeView$.pipe(
              tap(({ rightType, currentIncluded, updateUsageTypes }) => (this.updateUsageTypes = updateUsageTypes)),
              map(({ rightType, currentIncluded }) => [
                { fieldGroupClassName: 'display-flex-col', fieldGroup: DialogSharesUsesTypes.generateModelAndFormConfig(rightType, currentIncluded, usesTypes).formConfig },
              ]),
            ),
            model: this.rightsIncludeView$.pipe(
              map(({ rightType, currentIncluded }) => DialogSharesUsesTypes.generateModelAndFormConfig(rightType, currentIncluded, usesTypes).model),
            ),
            submitAvailable: true,
            submitLabel: popup.submitLabel,
            button1Enabled: true,
            button1Available: true,
            button1Label: popup.button1Label,
            submit: event => {
              if (this.updateUsageTypes) {
                this.updateUsageTypes(event, () => this.dialogRef.componentInstance.setLayout(0));
              }
            },

            onButton1: () => {
              this.dialogRef.componentInstance.setLayout(0);
            },
          },
        },
      ],
    };
  }

  editDialogLayout(claimId): IceGroupComponent {
    return {
      group: [
        {
          type: 'stepper',
          config: {
            stepperConfig: {
              model: this.store.pipe(
                select(fromRoot.getCopyrightWork),
                filter(work => !!work?.claims),
                take(1),
                map(detail => {
                  const { claims } = detail;
                  if (claims.length && claims.length > 0) {
                    const claim = ClaimantUtils.getClaimFromClaims(claimId, claims);
                    return cloneDeep(ClaimantUtils.getFormattedClaimantInfoFromClaim(claim, true, claims));
                  }
                  return null;
                }),
              ),
              steps: DialogClaimSteps.getSteps(
                this.translate,
                this.fuseTranslationLoader,
                this.store,
                this.storeNewItem,
                this.fieldValidatorService,
                this.searchService,
                this.dialog,
                this.permissionsService,
                (layout, close?) => {
                  if (this.dialogRef) {
                    if (close) {
                      this.dialogRef.close();
                    } else {
                      this.dialogRef.componentInstance.setLayout(layout);
                    }
                  }
                },
                this.rightsIncludeView$,
              ),
            },
            change: model => {
              this.store.dispatch(new fromForm.SaveField(model));
            },
            setValidForm: isValid => {
              this.store.dispatch(new fromForm.SetIsValidAndChangedForm(isValid));
            },
          },
        },
      ],
    };
  }

  private getVisibleColumns(schema: any): Observable<string[]> {
    const schemaDatatable = schema ? TabsUtils.getSChemaPropsToArray(schema) : [];

    return this.store.pipe(
      select(fromRoot.getEditMode),
      map(editMode => {
        if (editMode) {
          return schemaDatatable;
        } else {
          return TabsUtils.getSchemaFiltered(schemaDatatable, ['editBtn', 'removeBtn']);
        }
      }),
    );
  }

  private updateClaim(claimId: any, type: EditType) {
    this.storeNewItem
      .select(fromForm.getNewSectionItemFields)
      .pipe(take(1))
      .subscribe((item: any) => {
        const {
          ownership,
          shares,
          ClaimantPartyId,
          ClaimantPartyNameId,
          ClaimantIPBaseNameNumber,
          ClaimantIPI,
          ClaimantName,
          agreementId,
          linkCreator,
          PublisherParentId,
          isPublisherFromWork,
          PublisherIPNameNumber,
          PublisherIPI,
          PublisherPartyId,
          PublisherPartyNameId,
          PublisherType,
          role,
          ClaimantInfo,
          PublisherInfo,
        } = item;
        this.store.dispatch(
          new fromRoot.UpdateField({
            object: 'workClaims',
            newValue: {
              claimId,
              ownership,
              shares,
              ClaimantPartyId,
              ClaimantPartyNameId,
              ClaimantIPBaseNameNumber,
              ClaimantIPI,
              ClaimantName,
              agreementId,
              linkCreator,
              parentId: PublisherParentId,
              isPublisherFromWork,
              PublisherIPNameNumber,
              PublisherIPI,
              PublisherPartyId,
              PublisherPartyNameId,
              PublisherType,
              role,
              ClaimantInfo,
              PublisherInfo,
            },
            type,
          }),
        );
        this.dialogRef?.close();
      });
    this.updateDataTable = !this.updateDataTable;
    this.updateDataTable$.next(this.updateDataTable);
  }
}
