import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { DateTimeUtils, TerritoryUtils } from '@ice';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { STEPPER_ERRORS } from 'config/constants/formly.constants';
import { get, intersection, isEmpty, isFinite, last, some, uniqBy } from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export class FormlyValidatorUtils {
  static checkRepeaterDuplicates(control: AbstractControl, resultControlName: string, valuesKey = 'key', selectFromParentValue = false, removePrefix = false) {
    setTimeout(() => {
      const controlValue = (control.value || '').trim();
      if (control && controlValue && get(control, 'parent.parent.parent.controls')) {
        const valueFromDifferentControl = selectFromParentValue && get(control, `parent.controls[${valuesKey}].value`);
        const valueToCompare = valueFromDifferentControl || controlValue;
        const values = control.parent.parent.value;
        const normalizedValues = removePrefix
          ? [...values, valueToCompare].map(value => ({ [valuesKey]: last((value[valuesKey] || '').split(':')) }))
          : [...values, valueToCompare];

        const hasDuplicate = uniqBy(normalizedValues, value => value[valuesKey]).length !== normalizedValues.length;
        this.changeValidatorValue(control.parent.parent.parent.controls[resultControlName], controlValue, values, hasDuplicate);
      }
    }, 1000);
  }

  static checkRepeaterDuplicatesOnDestroy(control: AbstractControl, resultControlName: string, valuesKey = 'key', removePrefix = false) {
    if (control && control.parent) {
      const values = removePrefix ? control.value.map(value => ({ [valuesKey]: last((value[valuesKey] || '').split(':')) })) : control.value;
      const hasDuplicate = uniqBy(values, value => value[valuesKey]).length !== values.length;
      this.changeValidatorValue(control.parent.controls[resultControlName], 'A', values, hasDuplicate);
    }
  }

  static changeValidatorValue(validator: AbstractControl, value: string, values: any[], hasDuplicate: boolean) {
    if (validator) {
      if (!value || (values && value && !hasDuplicate)) {
        validator.setValue('');
      } else {
        validator.setValue('error');
      }
    }
  }

  static checkRepeaterMinimum1(control: AbstractControl, resultControlName: string) {
    const errorValidatorField: AbstractControl = control.parent.controls[resultControlName];
    if (errorValidatorField) {
      if (control.value && control.value.length === 0) {
        errorValidatorField.setValue(STEPPER_ERRORS.MINIMUM1);
      } else if (errorValidatorField.value && errorValidatorField.value === STEPPER_ERRORS.MINIMUM1) {
        errorValidatorField.setValue('');
      }
      errorValidatorField.updateValueAndValidity();
    }
  }

  static checkDuplicatedCombinationTerritoriesSocieties(getExtendedToP$: Observable<any>, control: FormControl, errorFieldName: string) {
    const societiesArray = control.parent.get('societies').value?.split(',') || '';
    const territoriesFormatted = TerritoryUtils.formatShareTerritoriesForRegistration((control.parent.get('territories').value || '').split(','));
    const errorsField = control.parent.get(errorFieldName).errors || {};
    const { duplicatedCombination, ...errors } = errorsField;
    control.parent.get(errorFieldName).setErrors((Object.keys(errors).length && errors) || null);
    return getExtendedToP$
      .pipe(
        map(
          data =>
            !some(
              data,
              row =>
                !!intersection(get(row, 'societyIds', []), societiesArray).length &&
                !!intersection(TerritoryUtils.formatShareTerritoriesForRegistration(get(row, 'territories.inExTisns')), territoriesFormatted).length,
            ),
        ),
        take(1),
      )
      .toPromise();
  }

  static getDurationValidator(translate: TranslateService) {
    return {
      expression: (c: any) => !c.value || /^([0-9]{1,4}:[0-9]{2})$/.test(c.value) || (/^([0-9]{1,4}:?[0-9]{2}:[0-9]{2})$/.test(c.value) && c.value !== '00:00'),
      message: (error: any, field: FormlyFieldConfig) => translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.DURATION_FORMAT_ERROR'),
    };
  }

  static getMaxDurationValidator(translate: TranslateService) {
    return {
      expression: (c: any) => !c.value || DateTimeUtils.setDurationToSeconds(c.value) < 36000000,
      message: (error: any, field: FormlyFieldConfig) => translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.MAX_DURATION_ERROR'),
    };
  }

  static getReferenceValidator(translate: TranslateService, messageId: string, ipiBaseFormatAvailable = false) {
    return {
      expression: (control: FormControl) =>
        new Promise(resolve => {
          if (control && control.value) {
            if (ipiBaseFormatAvailable) {
              if (/^(I-[0-9]{9}-[0-9])$/.test(control.value)) {
                resolve(true);
              }
            }
            const value: string[] = control.value.split(':');
            if (value && value.length === 1) {
              if (!isFinite(Number(value[0]))) {
                resolve(false);
              }
            } else {
              if (!isFinite(Number(value[1]))) {
                resolve(false);
              }
            }
          }
          resolve(true);
        }),
      message: translate.instant(messageId),
    };
  }

  static getDatepickerRangeSinceDateShouldBeBeforeToDateValidators({
    translate,
    toDateModelKey,
    toDateLabel,
  }: {
    translate: TranslateService;
    toDateModelKey: string;
    toDateLabel: string;
  }): any {
    return {
      expression: (control: FormControl) => {
        const otherFieldControlValue = control?.parent?.get(toDateModelKey)?.value;
        return !control.value || !otherFieldControlValue || moment(control.value).isBefore(otherFieldControlValue) || moment(control.value).isSame(otherFieldControlValue);
      },
      message: translate.instant('SINCE_DATE_SHOULD_BE_BEFORE_TO_DATE', { toDateLabel }),
    };
  }

  static getDatepickerRangeToDateShouldBeAfterSinceDateoValidators({
    translate,
    sinceDateModelKey,
    sinceDateLabel,
  }: {
    translate: TranslateService;
    sinceDateModelKey: string;
    sinceDateLabel: string;
  }): any {
    return {
      expression: (control: FormControl) => {
        const otherFieldControlValue = control?.parent?.get(sinceDateModelKey)?.value;
        return !control.value || !otherFieldControlValue || moment(control.value).isAfter(otherFieldControlValue) || moment(control.value).isSame(otherFieldControlValue);
      },
      message: translate.instant('TO_DATE_SHOULD_BE_AFTER_SINCE_DATE', { sinceDateLabel }),
    };
  }

  static isNumber({ translate }: { translate: TranslateService }): ValidationErrors {
    return {
      expression: (control: FormControl) => {
        if (!control.value || !control.value?.trim?.()) return true;
        return isFinite(Number(control.value));
      },
      message: translate.instant('COMMON.NUMBER_REQUIRED'),
    };
  }
}
