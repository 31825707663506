import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightOwnershipTableItem, DateTimeUtils, SharePictureUtils, SharesFilter, TerritoryUtils, WorkUtils } from '@ice';
import { SharePictureRepertoireTypes } from '@ice/components/card-with-shares-repertoire/card-with-shares-repertoire';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { DialogUtils } from '@ice/utils/dialog/dialog.utils';
import { LocalStorageUtils } from '@ice/utils/local-storage/localstorage.utils';
import { SocietiesUtils } from '@ice/utils/societies/societies.utils';
import { DefaultProjectorFn, MemoizedSelector, Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { PB_PUBLISHER_EXTENDED_VIEWER, ROLES } from 'config/constants/global.constants';
import { OrganizationType } from 'config/constants/organizations.constants';
import { DEFAULT_DISTRIBUTION_SOCIETY_MR, DEFAULT_DISTRIBUTION_SOCIETY_PR, DEFAULT_FILTER_TERRITORY, workDetailRepertoireVisibleColumns } from 'config/constants/shares.constants';
import { CopyrightSharesDataTable } from 'config/data-table-builders/copyright.shares';
import { SharesFilterConfig } from 'config/filter-builders/shares-filter';
import { SectionsConfig } from 'config/sections-config';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { IceFacade } from 'facades/ice.facade';
import { cloneDeep, get, last, some, toNumber, trim } from 'lodash';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';

export class TabWorkRepertoires implements SectionTabConfig {
  private hierarchySort$ = new BehaviorSubject(true);
  private workId: string;
  private filterSharePicture;
  private viewSharesRestrictively = false;
  unsubscribeAll = new Subject();

  constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    protected dialog: MatDialog,
    protected commonApiService: CommonApiService,
    protected detailService: DetailService,
    protected nsService: NamespaceService,
    protected iceFacade: IceFacade,
    protected fieldValidatorService: FieldValidatorService,
    protected permissionsService: PermissionsService,
  ) {
    this.store
      .pipe(select(fromRoot.getUserCurrentOrganization), withLatestFrom(this.store.select(fromRoot.getUserDetailRoles)), takeUntil(this.unsubscribeAll))
      .subscribe(([currentOrganization, roles]) => {
        this.viewSharesRestrictively = currentOrganization?.type === OrganizationType.publisher && [ROLES.publisherExtendedViewer].includes(roles);
      });
    this.getWorkId();
    this.getFilterSharePicture();
  }

  onDestroy = () => {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  };

  getConf(): IceGroupComponent[] {
    const copyrightSharesDataTable = new CopyrightSharesDataTable({
      translate: this.translate,
      fuseTranslationLoader: this.fuseTranslationLoader,
      store: this.store,
      dialog: this.dialog,
      permissionsService: this.permissionsService,
    });

    return [
      {
        group: [
          {
            type: 'cardWithSharesRepertoire',
            config: {
              title: this.translate.instant('WORKS.SHARE_PICTURE.REPERTOIRE'),
              loading: this.store.select(fromRoot.getLoadingShares),
              noPermissionTitle: this.translate.instant('WORKS.SHARE_PICTURE.NO_PERMISSION'),
              shareTypes: [
                {
                  type: SharePictureRepertoireTypes.REPERTOIRE,
                  label: this.translate.instant('WORKS.SHARE_PICTURE.REPERTOIRE'),
                  checked: true,
                },
              ],
              filter: this.getFilterSharesForRepertoire(this.store, this.translate, this.nsService),
              table: {
                class: {
                  default: 'work-share-pictures-table',
                },
                model: {
                  repertoire: this.returnData(this.store, fromRoot.getRepertoireRowsSharePictureWithClaimants).pipe(
                    switchMap(repertoireRowsSharePictureWithClaimants => {
                      const hasMrShareTotalValues = some(repertoireRowsSharePictureWithClaimants, 'rawMrShare.totalShare');
                      const hasPrShareTotalValues = some(repertoireRowsSharePictureWithClaimants, 'rawPrShare.totalShare');
                      if (this.viewSharesRestrictively && !hasMrShareTotalValues && !hasPrShareTotalValues) {
                        return of([]);
                      }
                      return of(repertoireRowsSharePictureWithClaimants);
                    }),
                  ),
                  default: of([]),
                },
                requestStatus: this.store.pipe(select(fromRoot.getSharesRequestStatus)),
                schema: this.store.pipe(
                  select(fromRoot.getEditMode),
                  map(editMode => copyrightSharesDataTable.getDataTableRepertoire(null, !editMode, null, true)),
                ),
                selectionType: this.store.pipe(
                  select(fromRoot.getEditMode),
                  map(editMode => (editMode && SelectionType.checkbox) || SelectionType.single),
                ),
                select: {
                  default: {
                    onSelect: (event: any[]) => {
                      copyrightSharesDataTable.navigateToIPSTab(event);
                    },
                    onMouseSelect: row => {
                      if (row.activatedColumn === 'prRepertoireName' && !!row.prRepertoireId) {
                        this.store.dispatch(
                          new fromRoot.OpenNewTab({
                            path: [`copyright/${SectionsConfig.REPERTOIRES.name}/${row.prRepertoireId}`],
                          }),
                        );
                      } else if (row.activatedColumn === 'mrRepertoireName' && !!row.mrRepertoireId) {
                        this.store.dispatch(
                          new fromRoot.OpenNewTab({
                            path: [`copyright/${SectionsConfig.REPERTOIRES.name}/${row.mrRepertoireId}`],
                          }),
                        );
                      } else {
                        copyrightSharesDataTable.openIpsInNewTab(row);
                      }
                    },
                  },
                },
                visibleColumns: {
                  repertoire: of(workDetailRepertoireVisibleColumns),
                  default: of(['']),
                },
                sorts: [{ prop: 'hierarchy', dir: 'asc' }],
                sortReset: true,
                disabledSort: this.hierarchySort$,
                onSort: sort => (get(sort, '[0].prop') === 'hierarchy' ? this.hierarchySort$.next(true) : this.hierarchySort$.next(false)),
              },
              totals: {
                totalParser: (rows: any) => SharePictureUtils.getSharesTotals(rows),
                publisherSubTotalLabel: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_PUBLISHER_SUB_TOTAL'),
                writerSubTotalLabel: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_CREATOR_SUB_TOTAL'),
                specialSubTotalLabel: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_SPECIAL_SUB_TOTAL'),
                totalClaimsLabel: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_TOTAL'),
                class: {
                  repertoire: of('hierarchy-colo-totals work-detail-alert-col-margin'),
                  default: of('hierarchy-colo-totals work-detail-alert-col-margin'),
                },
              },
            },
          },
        ],
      },
    ];
  }

  getFilterSharesForRepertoire(store: Store<fromRoot.RootState>, translate: TranslateService, nsService: NamespaceService): SharesFilter {
    return {
      submitShortcutEnable: true,
      formClass: 'bg-filter-form ice-pt-30 ice-mt-0',
      model: of({}).pipe(
        withLatestFrom(store.pipe(select(fromRoot.getUserDetail))),
        map(([model, userDetail]: [any, any]) => {
          const userName = userDetail?.cognitoUserName;
          let lastFilter: any = {};
          if (
            userName &&
            LocalStorageUtils.getSharePictureFilter(userName) &&
            WorkUtils.removeLeadingZeros(this.workId) === WorkUtils.removeLeadingZeros(LocalStorageUtils.getSharePictureFilter(userName).workId)
          ) {
            lastFilter = LocalStorageUtils.getSharePictureFilter(userName);
          }
          const mrFilter =
            (lastFilter && lastFilter?.mrDistributionSocietyId && SocietiesUtils.getSocietyCode(SharePictureUtils.formatSocietyCode(lastFilter?.mrDistributionSocietyId))) || '000';
          const prFilter =
            (lastFilter && lastFilter?.prDistributionSocietyId && SocietiesUtils.getSocietyCode(SharePictureUtils.formatSocietyCode(lastFilter?.prDistributionSocietyId))) || '000';
          return {
            ...model,
            ...lastFilter,
            territory: (lastFilter && lastFilter?.country) || DEFAULT_FILTER_TERRITORY,
            mrSociety: mrFilter !== '000' ? mrFilter : DEFAULT_DISTRIBUTION_SOCIETY_MR,
            prSociety: prFilter !== '000' ? prFilter : DEFAULT_DISTRIBUTION_SOCIETY_PR,
            repertoire: true,
            removeE: (this.filterSharePicture && this.filterSharePicture?.removeE) || false,
            removeSE: (this.filterSharePicture && this.filterSharePicture?.removeSE) || false,
          };
        }),
      ),
      formBuilder: SharesFilterConfig.getFormInlineForRepertoire(store, translate, nsService.getNamespace()),
      forceSubmitDisabled: {
        default: false,
      },
      onSubmit: data => {
        store.pipe(select(fromRoot.getRouterParams), take(1)).subscribe(params => {
          SharesFilterConfig.doSubmit({ ...data, repertoire: true }, store, nsService.getNamespace(), params.key);
        });
      },
    };
  }

  returnData(
    store: Store<fromRoot.RootState>,
    selector: MemoizedSelector<object, CopyrightOwnershipTableItem[], DefaultProjectorFn<CopyrightOwnershipTableItem[]>>,
  ): Observable<any[]> {
    return store.pipe(
      withLatestFrom(store.select(selector), store.select(fromRoot.getFilterSharePicture)),
      map(([_storeData, data, filter]) => {
        const cleanData = this.cleanModel(data);
        return this.applyFilter(WorkUtils.cleanWorkDataTableLabels(cleanData), filter);
      }),
    );
  }

  cleanModel(data: any[]): any[] {
    return (data || []).map(row => {
      delete row.mrInfoTooltip;
      delete row.prInfoTooltip;
      return row;
    });
  }

  applyLocalFilter(data: any[], localFilter: any) {
    const formattedData = cloneDeep(data);
    if (localFilter && formattedData) {
      const { country, distributionDate, mrRightType, mrDistributionSocietyId, prRightType, prDistributionSocietyId, usageDate } = localFilter;
      return formattedData.filter(item => {
        const manuscripShares = item.manuscripShares || [];
        return (
          manuscripShares.some(share => !!share.rightTypes.find(type => type === mrRightType)) &&
          manuscripShares.some(share => !!share.rightTypes.find(type => type === prRightType)) &&
          this.compareFilterSociety(mrDistributionSocietyId, item.mrSocietyList) &&
          this.compareFilterSociety(prDistributionSocietyId, item.prSocietyList) &&
          manuscripShares.some(share => DateTimeUtils.isDateBetween(usageDate, share.priorRoyaltiesDate, share.endDate)) &&
          manuscripShares.some(share => DateTimeUtils.isDateBetween(distributionDate, share.startDate, share.postTermCollectionDate)) &&
          manuscripShares.some(share =>
            TerritoryUtils.reduceGroupedTerritories(share.territories).some(territory => toNumber(TerritoryUtils.trimTerritoryPrefix(territory)) === country),
          )
        );
      });
    }
    return formattedData;
  }

  compareFilterSociety(filterSocietyString, itemSocietyCodes) {
    const filterSocietyNumber = toNumber(`${last((filterSocietyString || '').split(':'))}`.replace('NaN', ''));
    const itemCodes = itemSocietyCodes.split(',') || [];
    return filterSocietyNumber === 0 || itemCodes.some(code => filterSocietyNumber === toNumber(trim(code)));
  }

  applyFilter(data: any[], _filter: any): any[] {
    let formattedData = cloneDeep(data);
    if (data && _filter) {
      if (_filter.removeE) {
        formattedData = this.filterData(formattedData, 'E');
      }
      if (_filter.removeSE) {
        formattedData = this.filterData(formattedData, 'SE');
      }
    }
    return formattedData;
  }

  filterData(data: any[], role: string): any[] {
    return data.filter(row => row.role.replace(/&nbsp;/g, '') !== role);
  }

  getWorkId() {
    this.store.pipe(select(fromRoot.getRouterParams), take(1)).subscribe(params => {
      this.workId = params.key;
    });
  }

  getFilterSharePicture() {
    this.store.pipe(select(fromRoot.getFilterSharePicture), take(1)).subscribe(data => {
      this.filterSharePicture = data;
    });
  }
}
