import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { OrganizationType } from 'config/constants/organizations.constants';
import { FormConfig, SearchExpressions } from './form-config';

export class OrganizationsSearchExpressions extends SearchExpressions {
  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any) {
    switch (key) {
      case 'id':
        return `{"equals":{"attributes.id":"${value}"}}`;
      case 'type':
        return value !== 'ALL' ? `{"equals":{"${'attributes.' + key}":"${value}"}}` : `{"wildcard":{"${'attributes.' + key}":"*"}}`;
      case 'idOrganization':
        return `{"wildcard":{"attributes.id":"*${value}*"}}`;
      case 'idOrganizationOrName':
        return `{"or":[{"wildcard":{"attributes.id":"*${value}*"}},{"wildcard":{"attributes.name":"*${value}*"}}]}`;
    }
    return `{"wildcard":{"${'attributes.' + key}":"*${value}*"}}`;
  }

  getExtraSearchExpression() {
    return '';
  }
}

export class SearchUserManagementOrganizationsForm implements FormConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getForm(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'name',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('ORGANIZATIONS.NAME'), required: false },
          },
          {
            className: 'flex-1',
            key: 'id',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('ORGANIZATIONS.ID'), required: false },
          },
          {
            className: 'flex-1',
            key: 'type',
            type: 'select',
            templateOptions: {
              label: this.translate.instant('ORGANIZATIONS.TYPE'),
              options: [
                { label: this.translate.instant('ORGANIZATIONS.VALUES_ALL'), value: null },
                { label: this.translate.instant('ORGANIZATIONS.PUBLISHER'), value: OrganizationType.publisher },
                { label: this.translate.instant('ORGANIZATIONS.SOCIETY'), value: OrganizationType.society },
                { label: this.translate.instant('ORGANIZATIONS.ICE'), value: OrganizationType.ice },
                { label: this.translate.instant('ORGANIZATIONS.STAGE'), value: OrganizationType.stage },
              ],
              required: false,
            },
          },
        ],
      },
    ];
  }
}
