import { RelationsUtils, SimpleDatatableLayout, SimpleDatatableValue, SimpleDatatableValueType } from '@ice';
import { TranslateService } from '@ngx-translate/core';
import { AGREEMENT_XREF_TYPE } from 'config/constants/agreements.constants';
import { RELATIONTYPES } from 'config/constants/relation.constants';
import { isArray, isObject, toPairs } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const column = (name: string, prop: string, flexGrow: number = 1, className: string = '', tooltip?: string) => ({ name, prop, flexGrow, cellClass: className, tooltip });

export const columnWithIcons = (name: string, prop: string, flexGrow: number = 1, icons: string, className: string) => ({ name, prop, flexGrow, icons, cellClass: className });

export const columnWithTooltipAndHeaderclass = (name: string, prop: string, flexGrow: number = 1, tooltip: string, headerClass: string, className: string) => ({
  name,
  prop,
  flexGrow,
  tooltip,
  headerClass,
  cellClass: className,
});

export const columnWithTooltipAndHeaderclassFixed = (name: string, prop: string, flexGrow: number = 1, tooltip: string, headerClass: string, className: string, width: number) => ({
  name,
  prop,
  flexGrow,
  tooltip,
  headerClass,
  cellClass: className,
  width,
  resizeable: false,
  canAutoResize: false,
});

export const columnWithIconsAndHeader = (name: string, prop: string, flexGrow: number = 1, headerClass: string, className: string, icons: string) => ({
  name,
  prop,
  flexGrow,
  headerClass,
  cellClass: className,
  icons,
});
export class DatatableUtils {
  static getDataTableDefaultMessages(translate) {
    return {
      loadingMessage: { emptyMessage: translate.instant('ROOT.LOADING_MESSAGE') },
      noResultsMessage: {
        emptyMessage: translate.instant('ROOT.NO_RESULTS_MESSAGE_TAB'),
      },
    };
  }
  static getDatatableSimpleListFromRows(
    rows: Array<any>,
    translate: TranslateService,
    labelTranslations: SimpleDatatableLayout,
    groupRelationTranslations?: SimpleDatatableLayout,
  ) {
    if (!isArray(rows) || !isObject(labelTranslations)) {
      return [];
    }
    return rows.map(row => ({
      ...row,
      id: row?.value,
      label: (Object.keys(labelTranslations).includes(row.label) && labelTranslations[row.label].label) || row.label,
      labelXrefTooltip: RelationsUtils.getXrefTypeFromValueCounterClaim(row.label, translate),
      value: (Object.keys(labelTranslations).includes(row.label) && this.getDatatableSimpleListValue(labelTranslations[row.label], row.value, row)) || row.value,
      groupRelation:
        (groupRelationTranslations &&
          row.type === AGREEMENT_XREF_TYPE.GROUP &&
          (row.relation === RELATIONTYPES.GROUP
            ? groupRelationTranslations['included'].label
            : row.relation === RELATIONTYPES.RECIPIENT
            ? groupRelationTranslations['related'].label
            : '')) ||
        '',
    }));
  }

  static getDatatableSimpleListFromAttributes(originObject: any, labelTranslations: SimpleDatatableLayout): { label: string; value: string }[] {
    if (!isObject(originObject) || !isObject(labelTranslations)) {
      return [];
    }
    return toPairs(labelTranslations).map(([key, simpleDatatableValue]: [string, SimpleDatatableValue]) => ({
      label: simpleDatatableValue.label,
      value: this.getDatatableSimpleListValue(simpleDatatableValue, originObject[key]),
      tooltip: this.getDatatableSimpleListTooltip(simpleDatatableValue, originObject[key]),
      ...((simpleDatatableValue.rowClass && { rowClass: simpleDatatableValue.rowClass }) || {}),
      ...(simpleDatatableValue.icons || {}),
    }));
  }

  static getDatatableSimpleListValue(simpleDatatableValue: SimpleDatatableValue, value: any, row?): string {
    if (simpleDatatableValue.customValueParser) {
      return simpleDatatableValue.customValueParser(row || value);
    }
    if (!simpleDatatableValue.valueType) {
      return value || '-';
    }
    switch (simpleDatatableValue.valueType) {
      case SimpleDatatableValueType.BOOLEAN:
        return value ? 'Y' : 'N';
      case SimpleDatatableValueType.NUMBER:
        return value ? value : 0;
      case SimpleDatatableValueType.STRING:
        return value ? value : '-';
    }
  }

  static getDatatableSimpleListTooltip(simpleDatatableValue: SimpleDatatableValue, value: any): string {
    return simpleDatatableValue.tooltipParser ? simpleDatatableValue.tooltipParser(value) : null;
  }

  static getCommonTablesHeight(left$: Observable<any[]>, right$: Observable<any[]>, maxHeight: number = 296): Observable<string> {
    return combineLatest([left$, right$]).pipe(map(([compareValues, analysisValues]) => `${this.getHeight(compareValues, analysisValues, maxHeight)}px`));
  }

  static getHeight(compareValues: any[], analysisValues: any[], maxHeight: number = 296): number {
    const compareLength = compareValues?.length || 0;
    const analysisLength = analysisValues?.length || 0;
    return this.getCardMinHeight(Math.max(compareLength, analysisLength) * 41 + 61, maxHeight);
  }

  static getCardMinHeight(totalHeight: number, maxHeight: number = 296): number {
    const minHeightNoDataNoScroll = 120;
    return Math.max(Math.min(totalHeight, maxHeight), minHeightNoDataNoScroll) || maxHeight;
  }
}
