import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ICE } from 'config/constants/global.constants';
import { claimInitModels } from 'config/stepper-builders/claim/new-claim-init';
import { StepType } from 'config/stepper-builders/stepper-config';
import _, { get, isEqual, remove } from 'lodash';
import { of } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';

enum newClaimActionsTypes {
  newStandardClaim = 'NEW_STANDARD_CLAIM',
  newUnknownPublisher = 'NEW_UNKNOWN_PUBLISHER',
  newUnknownAuthorComposer = 'NEW_UNKNOWN_AUTHOR_COMPOSER',
}

export class DialogNewClaim {
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private store: Store<fromRoot.RootState>,
    private storeNewItem: Store<fromForm.NewSectionItemState>,
    private steps: StepType[],
  ) {}

  openDialog(isEdition: boolean = false) {
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialog-wrapper-height-auto',
        layouts: [this.getNewClaimLayout(isEdition)],
      },
    });
  }

  getNewClaimLayout(isEdition: boolean = false) {
    const buttons = [
      {
        color: 'accent',
        label: 'CLAIM.NEW_STANDARD_CLAIM',
        action: newClaimActionsTypes.newStandardClaim,
      },
      {
        color: 'accent',
        label: 'CLAIM.NEW_UNKNOWN_PUBLISHER',
        action: newClaimActionsTypes.newUnknownPublisher,
      },
      {
        color: 'accent',
        label: 'CLAIM.NEW_UNKNOWN_AUTHOR_COMPOSER',
        action: newClaimActionsTypes.newUnknownAuthorComposer,
      },
    ];
    if (!isEdition) {
      remove(buttons, button => isEqual(button?.action, newClaimActionsTypes.newStandardClaim));
    }
    return {
      title: of(this.translate.instant('CLAIM.NEW_CLAIM_DIALOG_TITLE')),
      className: 'w350 flex-items-center',
      layout: [
        {
          group: [
            {
              type: 'buttonsGroup',
              config: {
                emitEvent: (action: any) => {
                  let actionNumber: number;
                  switch (action) {
                    case newClaimActionsTypes.newStandardClaim:
                      {
                        actionNumber = 0;
                      }
                      break;
                    case newClaimActionsTypes.newUnknownPublisher:
                      {
                        actionNumber = 1;
                      }
                      break;
                    case newClaimActionsTypes.newUnknownAuthorComposer: {
                      actionNumber = 2;
                    }
                  }
                  this.dialogRef.close();
                  if (!isEdition) {
                    this.doNotEditAction(actionNumber);
                  } else {
                    this.openWorkCreationDialog(actionNumber);
                  }
                },
                config: {
                  type: 'matBtn',
                  layout: 'column',
                  customClass: 'new-claim-dialog-buttons',
                  buttons,
                },
              },
            },
          ],
        },
      ],
    };
  }

  private doNotEditAction(actionNumber: number) {
    if (actionNumber === 0) {
      this.openWorkCreationDialog(actionNumber);
    } else {
      this.store.dispatch(new fromRoot.StartEditMode({ editing: true, editId: ICE, editName: ICE, newItemType: 'claim', model: actionNumber }));
    }
  }

  openWorkCreationDialog(modelIndex: number) {
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      panelClass: 'work-claims-dialog-container',
      data: {
        className: 'dialog-wrapper-width-1200 dialog-wrapper-height-80 dialog-edit-claim',
        layouts: [
          {
            title: of(this.translate.instant('CLAIM_REGISTRATION')),
            actions: [
              {
                tooltip: this.translate.instant('POPUP.CANCEL'),
                color: 'warn',
                icon: 'close',
                onClick: () => {
                  this.dialogRef.close();
                },
              },
              {
                tooltip: this.translate.instant('POPUP.CONFIRM'),
                icon: 'done',
                disabled: this.storeNewItem.select(fromForm.getNewSectionValidOrChangedForm).pipe(map(valid => !valid)),
                onClick: () => {
                  this.storeNewItem
                    .select(fromForm.getNewSectionItemFields)
                    .pipe(take(1))
                    .subscribe((item: any) => {
                      this.store.dispatch(
                        new fromRoot.UpdateField({
                          object: 'workClaims',
                          newValue: {
                            claimId: '',
                            ownership: get(item, 'ownership'),
                            shares: get(item, 'shares'),
                            ClaimantPartyId: get(item, 'ClaimantPartyId'),
                            ClaimantPartyNameId: get(item, 'ClaimantPartyNameId'),
                            ClaimantIPBaseNameNumber: get(item, 'ClaimantIPBaseNameNumber'),
                            ClaimantIPI: get(item, 'ClaimantIPI'),
                            ClaimantName: get(item, 'ClaimantName'),
                            agreementId: get(item, 'agreementId'),
                            linkCreator: get(item, 'linkCreator'),
                            parentId: get(item, 'PublisherParentId'),
                            isPublisherFromWork: get(item, 'isPublisherFromWork'),
                            PublisherIPNameNumber: get(item, 'PublisherIPNameNumber'),
                            PublisherIPI: get(item, 'PublisherIPI'),
                            PublisherPartyId: get(item, 'PublisherPartyId'),
                            PublisherPartyNameId: get(item, 'PublisherPartyNameId'),
                            PublisherType: get(item, 'PublisherType'),
                            role: get(item, 'role'),
                            ClaimantInfo: get(item, 'ClaimantInfo'),
                            PublisherInfo: get(item, 'PublisherInfo'),
                            societies: get(item, 'societies'),
                            startDate: get(item, 'startDate'),
                            endDate: get(item, 'endDate'),
                          },
                          type: 'add',
                        }),
                      );
                      this.dialogRef.close();
                    });
                },
              },
            ],
            layout: [this.editDialogLayout(modelIndex)],
          },
        ],
      },
    });
  }

  openAddDerivedAsSubmittedDialog({ initialModel, isAgreementOnly }) {
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      panelClass: 'work-claims-dialog-container',
      data: {
        className: 'dialog-wrapper-width-1200 dialog-wrapper-height-80 dialog-edit-claim',
        layouts: [
          {
            title: of(this.translate.instant('WORKS.SHARE_PICTURE.DIALOG.ADD_DERIVED_AS_SUBMITTED')),
            actions: [
              {
                tooltip: this.translate.instant('POPUP.CANCEL'),
                color: 'warn',
                icon: 'close',
                onClick: () => {
                  this.dialogRef.close();
                },
              },
              {
                tooltip: this.translate.instant('POPUP.CONFIRM'),
                icon: 'done',
                disabled: this.storeNewItem.select(fromForm.getNewSectionValidOrChangedForm).pipe(map(valid => !valid)),
                onClick: () => {
                  this.storeNewItem
                    .select(fromForm.getNewSectionItemFields)
                    .pipe(withLatestFrom(this.store.select(fromRoot.getRouterState)), take(1))
                    .subscribe(([item, routerState]) => {
                      this.store.dispatch(
                        new fromRoot.UpdateField({
                          object: 'workClaims',
                          newValue: {
                            claimId: '',
                            ownership: get(item, 'ownership'),
                            shares: get(item, 'shares'),
                            ClaimantPartyId: get(item, 'ClaimantPartyId'),
                            ClaimantPartyNameId: get(item, 'ClaimantPartyNameId'),
                            ClaimantIPBaseNameNumber: get(item, 'ClaimantIPBaseNameNumber'),
                            ClaimantIPI: get(item, 'ClaimantIPI'),
                            ClaimantName: get(item, 'ClaimantName'),
                            agreementId: get(item, 'agreementId'),
                            linkCreator: get(item, 'linkCreator'),
                            parentId: get(item, 'PublisherParentId'),
                            isPublisherFromWork: get(item, 'isPublisherFromWork'),
                            PublisherIPNameNumber: get(item, 'PublisherIPNameNumber'),
                            PublisherIPI: get(item, 'PublisherIPI'),
                            PublisherPartyId: get(item, 'PublisherPartyId'),
                            PublisherPartyNameId: get(item, 'PublisherPartyNameId'),
                            PublisherType: get(item, 'PublisherType'),
                            role: get(item, 'role'),
                            ClaimantInfo: get(item, 'ClaimantInfo'),
                            PublisherInfo: get(item, 'PublisherInfo'),
                            societies: get(item, 'societies'),
                            startDate: get(item, 'startDate'),
                            endDate: get(item, 'endDate'),
                          },
                          type: 'add',
                        }),
                      );
                      this.dialogRef.close();
                      // switch to `work-claims` AKA "Submitted Claims" tab because we are creating a new claim
                      // so the user can see it before saving
                      const [sectionDomain, section, key, tab, editMode] = routerState.paths;
                      this.store.dispatch(
                        new fromRoot.Go({
                          path: [sectionDomain, section, key, 'work-claims', editMode],
                        }),
                      );
                    });
                },
              },
            ],
            layout: [
              ...(isAgreementOnly
                ? [
                    {
                      group: [
                        {
                          type: 'htmlTemplate',
                          config: {
                            htmlClass: 'ice-error-msg',
                            htmlTemplate: of(`
                                <p class="text-14">${this.translate.instant('WORKS.SHARE_PICTURE.DIALOG.MULTIPLE_SCOPES_ON_PARENT')}</p>
                                `),
                          },
                        },
                      ],
                    },
                  ]
                : []),
              {
                group: [
                  {
                    type: 'stepper',
                    config: {
                      stepperConfig: {
                        model: initialModel,
                        steps: this.steps,
                      },
                      change: model => {
                        this.store.dispatch(new fromForm.SaveField(model));
                      },
                      setValidForm: isValid => this.store.dispatch(new fromForm.SetIsValidAndChangedForm(isValid)),
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });
  }

  editDialogLayout(modelIndex: number): IceGroupComponent {
    return {
      group: [
        {
          type: 'stepper',
          config: {
            stepperConfig: {
              model: claimInitModels[modelIndex],
              steps: this.steps,
            },
            change: model => this.store.dispatch(new fromForm.SaveField(model)),
            setValidForm: isValid => this.store.dispatch(new fromForm.SetIsValidAndChangedForm(isValid)),
          },
        },
      ],
    };
  }
}
