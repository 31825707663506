import * as fromApiCalls from 'config/api-calls';
import * as fromApiCallConstructors from 'config/api-calls/constructors';
import { CounterClaimInitModel } from 'config/constants/counter-claims.constants';
import { REPORT_TYPES } from 'config/constants/reports.constants';
import { DashboardBulkUpdates } from 'config/dashboard-config/dashboard-bulk-updates';
import { DashboardConflictsCounterClaims } from 'config/dashboard-config/dashboard-conflicts-counterclaims';
import { ConflictsActionsDataTable } from 'config/data-table-builders/conflicts.actions';
import { ConflictsActivityDataTable } from 'config/data-table-builders/conflicts.activity';
import { ConflictsAgreementConflictDataTable } from 'config/data-table-builders/conflicts.agreement-conflict';
import { CopyrightAgreementGroupDataTable } from 'config/data-table-builders/copyright.agreement-group';
import { CopyrightAgreementsDataTable } from 'config/data-table-builders/copyright.agreements';
import { CopyrightBulkUpdatesDataTable } from 'config/data-table-builders/copyright.bulk-updates';
import { CopyrightIpsDataTable } from 'config/data-table-builders/copyright.ips';
import { CopyrightRepertoiresDataTable } from 'config/data-table-builders/copyright.repertoires';
import { CopyrightReportsDataTable } from 'config/data-table-builders/copyright.reports';
import { CopyrightSocietiesDataTable } from 'config/data-table-builders/copyright.societies';
import { CopyrightTerritoriesDataTable } from 'config/data-table-builders/copyright.territories';
import { CopyrightWorksDataTable } from 'config/data-table-builders/copyright.works';
import { UserManagementOrganizationsDataTable } from 'config/data-table-builders/user-management-organizations';
import { UserManagementUsersDataTable } from 'config/data-table-builders/user-management-users';
import { SearchConflictsCounterClaimsActionsForm } from 'config/search-form-builders/search-conflicts-actions';
import { SearchConflictsActivityForm } from 'config/search-form-builders/search-conflicts-activity';
import { SearchConflictsAgreementConflictForm } from 'config/search-form-builders/search-conflicts-agreement-conflict';
import { SearchConflictsCounterClaimsForm } from 'config/search-form-builders/search-conflicts-counter-claims';
import { SearchCopyrightAgreementGroupForm } from 'config/search-form-builders/search-copyright-agreement-group';
import { SearchCopyrightBulkUpdatesForm } from 'config/search-form-builders/search-copyright-bulk-updates';
import { SearchCopyrightIpsForm } from 'config/search-form-builders/search-copyright-ips';
import { SearchCopyrightRepertoiresForm } from 'config/search-form-builders/search-copyright-repertoires';
import { SearchCopyrightSocietiesForm } from 'config/search-form-builders/search-copyright-societies';
import { SearchCopyrightAgreementsForm } from 'config/search-form-builders/search-copyright.agreements';
import { SearchCopyrightTerritoriesForm } from 'config/search-form-builders/search-copyright.territories';
import { SearchCopyrightWorksForm } from 'config/search-form-builders/search-copyright.works';
import { SearchReportsForm } from 'config/search-form-builders/search-reports';
import { SearchUserManagementOrganizationsForm } from 'config/search-form-builders/search-user-management-organizations';
import { SearchUserManagementUsersForm } from 'config/search-form-builders/search-user-management-users';
import { SectionCopyrightIps } from 'config/sections-config/section-copyright-ips';
import { AgreementConflictSteps } from 'config/stepper-builders/agreement-conflict/agreement-conflict-steps';
import { AgreementGroupSteps } from 'config/stepper-builders/agreement-group/agreement-group-steps';
import { AgreementInitModel } from 'config/stepper-builders/agreements/section-new-agreements-init';
import { AgreementSteps } from 'config/stepper-builders/agreements/stepper-agreements';
import { BulkUpdatesSteps } from 'config/stepper-builders/bulk-updates/stepper-bulk-updates';
import { ClaimSteps } from 'config/stepper-builders/claim/claim-steps';
import { claimInitModels } from 'config/stepper-builders/claim/new-claim-init';
import { CounterclaimNotificationsSteps } from 'config/stepper-builders/counterclaim-notifications/counterclaim-notifications-steps';
import { CounterClaimRegistrationSteps } from 'config/stepper-builders/counterclaim-registation/counter-claim-registration-steps';
import { CounterClaimSupportSteps } from 'config/stepper-builders/counterclaim-support/counter-claim-support-steps';
import { OrganizationInitModel } from 'config/stepper-builders/organizations/section-new-organizations-init';
import { OrganizationSteps } from 'config/stepper-builders/organizations/stepper-organizations';
import { RepertoireSteps } from 'config/stepper-builders/repertoires/repertoire-steps';
import { ReportWorkDetailsIPSharesFormatter } from 'config/stepper-builders/reports/report-ips-shares/report-work-details-formatter';
import { ReportWorkDetailsSteps } from 'config/stepper-builders/reports/report-ips-shares/report-work-details-steps';
import { ReportListWorksFormatter } from 'config/stepper-builders/reports/report-list-works-header/report-list-works-formatter';
import { ReportListWorksSteps } from 'config/stepper-builders/reports/report-list-works-header/report-list-works-steps';
import { ReportShareFormatter } from 'config/stepper-builders/reports/report-share/report-share-formatter';
import { ReportShareSteps } from 'config/stepper-builders/reports/report-share/report-share-steps';
import { UserInitModel } from 'config/stepper-builders/users/section-new-users-init';
import { UserSteps } from 'config/stepper-builders/users/user-steps';
import { WorkInitModel } from 'config/stepper-builders/works/new-work-init';
import { WorksSteps } from 'config/stepper-builders/works/works-steps';
import { TabAgreementConflict } from 'config/tabs-data-builders/agreement-conflict/tab-agreement-conflict';
import { TabAgreementGroutpAuditHistory } from 'config/tabs-data-builders/agreement-group/tab-agreement-group-audit-history';
import { TabAgreementGroupDetail } from 'config/tabs-data-builders/agreement-group/tab-agreement-group-details';
import { TabAgreementAuditHistory } from 'config/tabs-data-builders/agreements/tabs/tab-agreement-audit-history';
import { TabAgreementDetail } from 'config/tabs-data-builders/agreements/tabs/tab-agreement-detail';
import { TabAgreementLinkedWriters } from 'config/tabs-data-builders/agreements/tabs/tab-agreement-linked-writers';
import { TabAgreementsWorks } from 'config/tabs-data-builders/agreements/tabs/tab-agreement-works';
import { TabAgreementWriters } from 'config/tabs-data-builders/agreements/tabs/tab-agreement-writers';
import { TabActivityMatchAnalysis } from 'config/tabs-data-builders/cases/tabs/tab-activity-match-analysis';
import { TabActivityMergeAnalysis } from 'config/tabs-data-builders/cases/tabs/tab-activity-merge-analysis';
import { TabCounterClaimsActions } from 'config/tabs-data-builders/counter-claims/tabs/tab-counter-claim-actions';
import { TabCounterClaimsXref } from 'config/tabs-data-builders/counter-claims/tabs/tab-counter-claim-xref';
import { TabCounterClaimsDetail } from 'config/tabs-data-builders/counter-claims/tabs/tab-counter-claims';
import { TabCounterClaimsAuditHistory } from 'config/tabs-data-builders/counter-claims/tabs/tab-counter-claims-audit-history';
import { TabCounterClaimsDocuments } from 'config/tabs-data-builders/counter-claims/tabs/tab-counter-claims-documents';
import { TabCounterClaimsOtherCounterClaims } from 'config/tabs-data-builders/counter-claims/tabs/tab-counter-claims-other-counter-claims';
import { AnalysisAndComparisonsConfig, FormsAndTablesConfig } from 'config/tabs-data-builders/dynamic-content-data';
import { TabIpAgreementChains } from 'config/tabs-data-builders/ips/tabs/tab-ip-agreement-chains';
import { TabIpAgreements } from 'config/tabs-data-builders/ips/tabs/tab-ip-agreements';
import { TabIpAlternativeNames } from 'config/tabs-data-builders/ips/tabs/tab-ip-alternative-names';
import { TabIpsAuditHistory } from 'config/tabs-data-builders/ips/tabs/tab-ip-audit-history';
import { TabIpDetail } from 'config/tabs-data-builders/ips/tabs/tab-ip-detail';
import { TabIpLinked } from 'config/tabs-data-builders/ips/tabs/tab-ip-linked';
import { TabIpRelations } from 'config/tabs-data-builders/ips/tabs/tab-ip-relations';
import { TabIpWorks } from 'config/tabs-data-builders/ips/tabs/tab-ip-works';
import { TabIpXref } from 'config/tabs-data-builders/ips/tabs/tab-ip-xref';
import { TabIpClauses } from 'config/tabs-data-builders/ips/tabs/tap-ip-clauses';
import { TabOrganizationDetail } from 'config/tabs-data-builders/organizations/tabs/tab-detail';
import { TabOrganizationUsers } from 'config/tabs-data-builders/organizations/tabs/tab-users';
import { TabRepertoireAdditionalCollectors } from 'config/tabs-data-builders/repertoires/tabs/tab-additional-collectors';
import { TabRepertoireContractingCompanies } from 'config/tabs-data-builders/repertoires/tabs/tab-contracting-companies';
import { TabRepertoireCreatorAffiliations } from 'config/tabs-data-builders/repertoires/tabs/tab-creator-affiliations';
import { TabRepertoireExcludedAgreements } from 'config/tabs-data-builders/repertoires/tabs/tab-excluded-agreements';
import { TabRepertoireExcludedWorks } from 'config/tabs-data-builders/repertoires/tabs/tab-excluded-works';
import { TabRepertoireIncludedWorks } from 'config/tabs-data-builders/repertoires/tabs/tab-included-works';
import { TabRepertoireAuditHistory } from 'config/tabs-data-builders/repertoires/tabs/tab-repertoire-audit-history';
import { TabRepertoireDetail } from 'config/tabs-data-builders/repertoires/tabs/tab-repertoire-detail';
import { TabRepertoireRightTypes } from 'config/tabs-data-builders/repertoires/tabs/tab-right-types';
import { TabNotes } from 'config/tabs-data-builders/shared/tab-notes';
import { TabSocietyDetail } from 'config/tabs-data-builders/societies/tabs/tab-society-detail';
import { TabTerritoryDetail } from 'config/tabs-data-builders/territories/tabs/tab-territory-detail';
import { TabUsersDetail } from 'config/tabs-data-builders/users/tabs/tab-users-detail';
import { TabAllAgreements } from 'config/tabs-data-builders/works/tabs/tab-all-agreements';
import { TabWorkAllClaims } from 'config/tabs-data-builders/works/tabs/tab-all-claims';
import { TabConflicts } from 'config/tabs-data-builders/works/tabs/tab-conflicts';
import { TabEditClaim } from 'config/tabs-data-builders/works/tabs/tab-edit-claim';
import { TabExtendedToP } from 'config/tabs-data-builders/works/tabs/tab-extended-top';
import { TabOtherParties } from 'config/tabs-data-builders/works/tabs/tab-other-parties';
import { TabSourceWorks } from 'config/tabs-data-builders/works/tabs/tab-source-works';
import { TabWorkActivity } from 'config/tabs-data-builders/works/tabs/tab-work-activity';
import { TabWorksAuditHistory } from 'config/tabs-data-builders/works/tabs/tab-work-audit-history';
import { TabWorkClaims } from 'config/tabs-data-builders/works/tabs/tab-work-claims';
import { TabWorkIpClauses } from 'config/tabs-data-builders/works/tabs/tab-work-clauses';
import { TabWorkDetail } from 'config/tabs-data-builders/works/tabs/tab-work-detail';
import { TabWorksInstruments } from 'config/tabs-data-builders/works/tabs/tab-work-instruments';
import { TabWorkRepertoires } from 'config/tabs-data-builders/works/tabs/tab-work-repertoires';
import { TabWorksSocietyAttributes } from 'config/tabs-data-builders/works/tabs/tab-work-society-attributes';
import { DashBoardInitialModel } from 'models/home/dashboard-initial-model';
import { SearchActionsParser } from 'models/search-parsers/search-action-parser';
import { SearchActivityParser } from 'models/search-parsers/search-activity-parser';
import { SearchAgreementConflictParser } from 'models/search-parsers/search-agreement-conflict-parser';
import { SearchAgreementGroupParser } from 'models/search-parsers/search-agreement-group-parser';
import { SearchAgreementsParser } from 'models/search-parsers/search-agreements-parser';
import { SearchBulkUpdatesParser } from 'models/search-parsers/search-bulk-updates-parser';
import { SearchIpsParser } from 'models/search-parsers/search-ips-parser';
import { SearchOrganizationsParser } from 'models/search-parsers/search-organizations-parser';
import { SearchRepertoiresParser } from 'models/search-parsers/search-repertoires-parser';
import { SearchReportsParser } from 'models/search-parsers/search-reports-parser';
import { SearchSocietiesParser } from 'models/search-parsers/search-societies-parser';
import { SearchTerritoryParser } from 'models/search-parsers/search-territory-parser';
import { SearchUsersParser } from 'models/search-parsers/search-users-parser';
import { SearchWorkParser } from 'models/search-parsers/search-work-parser';
import { RefreshDetail } from 'store/root';
import { TabAgreementXrefs } from 'config/tabs-data-builders/agreements/tabs/tab-agreement-xrefs';
import { isBackground } from 'config/api-calls/api-call.factory';
import { TAB_WORK_ALL_CLAIMS } from 'config/constants';
import { ReportWorkDetailsGenerateFicheFormatter } from '../stepper-builders/reports/report-work-details-generate-fiche/report-work-details-generate-fiche-formatter';
import { ReportWorkDetailsGenerateFicheSteps } from '../stepper-builders/reports/report-work-details-generate-fiche/report-work-details-generate-fiche-steps';
import { ApiCallSequence, SequenceType } from './api-call';
import { SectionConflictsActivity } from './section-conflicts-activity';
import { SectionConflictsAgreementConflict } from './section-conflicts-agreement-conflict';
import { SectionConflictsCounterClaims } from './section-conflicts-counterclaims';
import { SectionConflictsCounterClaimsActions } from './section-conflicts-counterclaims-actions';
import { SectionCounterClaimsSupport } from './section-conflicts-counterclaims-support';
import { SectionAgreementGroup } from './section-copyright-agreement-group';
import { SectionCopyrightAgreements } from './section-copyright-agreements';
import { SectionCopyrightBulkUpdates } from './section-copyright-bulk-updates';
import { SectionConflictsCounterclaimNotifications } from './section-copyright-counterclaim-notifications';
import { SectionCopyrightRepertoires } from './section-copyright-repertoires';
import { SectionCopyrightReports } from './section-copyright-reports';
import { SectionCopyrightSocieties } from './section-copyright-societies';
import { SectionCopyrightTerritories } from './section-copyright-territories';
import { SectionCopyrightWorks } from './section-copyright-works';
import { SectionUserManagementOrganizations } from './section-user-management-organizations';
import { SectionUserManagementUsers } from './section-user-management-users';
import type { SectionModelMap } from './sections-config';

const BASE_TAB_AUDIT_HISTORY_API_CALLS_BEFORE: ApiCallSequence = [fromApiCalls.getEventTypes];

const TAB_AUDIT_HISTORY_COMMON_API_CALLS: ApiCallSequence = [
  ...BASE_TAB_AUDIT_HISTORY_API_CALLS_BEFORE,
  fromApiCalls.getEventDescriptions,
  fromApiCalls.getWorkAuditHistoryFilterStartDate,
  fromApiCalls.getWorkAuditHistoryFilterEndDate,
  SequenceType.Merge,
];

const TAB_AUDIT_HISTORY_IPS_API_CALLS: ApiCallSequence = [
  ...BASE_TAB_AUDIT_HISTORY_API_CALLS_BEFORE,
  fromApiCalls.getIpsEventDescriptions,
  fromApiCalls.getIpsWorkAuditHistoryFilterStartDate,
  fromApiCalls.getIpsWorkAuditHistoryFilterEndDate,
  SequenceType.Merge,
];

export const sections: SectionModelMap = {
  works: {
    config: SectionCopyrightWorks,
    apiCalls: detail => {
      const isAllClaimsTab = window.location.href.endsWith(TAB_WORK_ALL_CLAIMS);
      return [
        [
          { ...fromApiCalls.getAgreementCount, ...isBackground },
          ...fromApiCallConstructors.getWorkRelationsRepeater({ ...fromApiCalls.getSourceWorks, ...isBackground }, detail),
          SequenceType.Merge,
        ],
        [
          { ...fromApiCalls.getAuditHistory, ...isBackground },
          { ...fromApiCalls.getTerritoriesCountries, ...isBackground },
          // this call is heavy and is not immediately needed when rendering any other tab
          { ...fromApiCalls.getAllClaimsWork, ...isBackground, awaitFor: isAllClaimsTab ? 0 : 1_000 },
        ],
      ];
    },
    search: {
      datatable: (tservice, tloader, store) => new CopyrightWorksDataTable(tservice, tloader),
      cleaner: SearchWorkParser,
      form: (fuseTranslateService, fuseTranslationLoaderService, searchService, store, fieldValidatorService) =>
        new SearchCopyrightWorksForm(fuseTranslateService, fuseTranslationLoaderService, fieldValidatorService),
    },
    stepper: {
      steps: WorksSteps,
      initModel: WorkInitModel,
    },
    subSections: {
      claim: {
        stepper: {
          steps: ClaimSteps,
          initModel: claimInitModels,
        },
      },
    },
    tabs: {
      details: {
        content: TabWorkDetail,
      },
      'work-claims': {
        content: TabWorkClaims,
      },
      'all-claims': {
        content: TabWorkAllClaims,
      },
      'conflicts-cc': {
        content: TabConflicts,
        apiCalls: [fromApiCalls.getWorkConflicts, fromApiCalls.getWorkCounterclaimsAttributes],
      },
      agreements: {
        content: TabAllAgreements,
        apiCalls: detail => [
          [...fromApiCallConstructors.getWorkRelationsRepeater(fromApiCalls.getSourceWorks, detail), fromApiCalls.getAgreementApplications, SequenceType.Concat],
        ],
      },
      'source-works': {
        content: TabSourceWorks,
        apiCalls: detail => [
          [
            ...fromApiCallConstructors.getWorkRelationsRepeater(fromApiCalls.getSourceWorks, detail),
            fromApiCalls.getWorkInternalRelations,
            fromApiCalls.getWorkExternalRelations,
            SequenceType.Concat,
          ],
        ],
      },
      'society-attributes': {
        content: TabWorksSocietyAttributes,
      },
      'extended-top': {
        content: TabExtendedToP,
      },
      'other-parties': {
        content: TabOtherParties,
      },
      instruments: {
        content: TabWorksInstruments,
      },
      'work-clauses': {
        content: TabWorkIpClauses,
        defaultHidden: true,
      },
      repertoires: {
        content: TabWorkRepertoires,
      },
      notes: {
        content: TabNotes,
      },
      'audit-history': {
        content: TabWorksAuditHistory,
        apiCalls: detail => [...fromApiCallConstructors.getWorkRelationsRepeater(fromApiCalls.getSourceWorks, detail), ...TAB_AUDIT_HISTORY_COMMON_API_CALLS],
      },
      'work-activity': {
        content: TabWorkActivity,
      },
      'edit-claim': {
        content: TabEditClaim,
        defaultHidden: true,
      },
    },
  },
  ips: {
    config: SectionCopyrightIps,
    apiCalls: [
      fromApiCalls.getIpsParentRelationshipsData,
      fromApiCalls.getIpsChildrenRelationshipsData,
      fromApiCalls.getIpsWorks,
      fromApiCalls.getIpsAgreements,
      SequenceType.Concat,
    ],
    search: {
      datatable: (tservice, tloader, store) => new CopyrightIpsDataTable(tservice, tloader, store),
      cleaner: SearchIpsParser,
      form: (fuseTranslateService, fuseTranslationLoaderService) => new SearchCopyrightIpsForm(fuseTranslateService, fuseTranslationLoaderService),
    },
    tabs: {
      details: {
        container: FormsAndTablesConfig,
        content: TabIpDetail,
        refreshAction: RefreshDetail,
      },
      agreements: {
        content: TabIpAgreements,
      },
      'agreement-chains': {
        content: TabIpAgreementChains,
        apiCalls: [fromApiCalls.getAgreementChains],
      },
      relations: {
        container: FormsAndTablesConfig,
        content: TabIpRelations,
      },
      xref: {
        content: TabIpXref,
      },
      'alternative-names': {
        content: TabIpAlternativeNames,
        defaultHidden: true,
      },
      'audit-history': {
        content: TabIpsAuditHistory,
        apiCalls: [fromApiCalls.getAllPartiesAuditHistory, ...TAB_AUDIT_HISTORY_IPS_API_CALLS],
      },
      notes: {
        content: TabNotes,
      },
      works: {
        content: TabIpWorks,
      },
      clauses: {
        content: TabIpClauses,
        defaultHidden: true,
        apiCalls: fromApiCalls.getIpsWorkClauses,
      },
      'linked-publishers': {
        content: TabIpLinked,
        apiCalls: detail => (detail?.id ? [fromApiCalls.getIpsLinked] : []),
      },
      'linked-writers': {
        content: TabIpLinked,
        apiCalls: detail => (detail?.id ? [fromApiCalls.getIpsLinked] : []),
      },
    },
  },
  agreements: {
    config: SectionCopyrightAgreements,
    apiCalls: [
      fromApiCalls.getAgreementCounterclaims,
      fromApiCalls.getAgreementExcludedWorksCounter,
      fromApiCalls.getAgreementRetentions,
      fromApiCalls.getAgreementLinkedWritersCounter,
      fromApiCalls.getAuditHistory,
      SequenceType.Concat,
    ],
    search: {
      datatable: (tservice, tloader, store) => new CopyrightAgreementsDataTable(tservice, tloader, store),
      cleaner: SearchAgreementsParser,
      form: (fuseTranslateService, fuseTranslationLoaderService, searchService, store, fieldValidatorService) =>
        new SearchCopyrightAgreementsForm(fuseTranslateService, fuseTranslationLoaderService, searchService, fieldValidatorService),
    },
    stepper: {
      steps: AgreementSteps,
      initModel: AgreementInitModel,
    },
    tabs: {
      details: {
        content: TabAgreementDetail,
        apiCalls: [fromApiCalls.getAgreementSubmitterPartyRelations, fromApiCalls.getAgreementsWorksCount],
      },
      'included-works': {
        content: TabAgreementsWorks,
        apiCalls: [fromApiCalls.getAgreementWorks, fromApiCalls.getAgreementOriginalPublishersList],
      },
      'excluded-works': {
        content: TabAgreementsWorks,
        apiCalls: [fromApiCalls.getAgreementWorks],
      },
      'linked-writers': {
        content: TabAgreementLinkedWriters,
        apiCalls: fromApiCalls.getAgreementLinkedWriters,
      },
      'audit-history': {
        content: TabAgreementAuditHistory,
        apiCalls: TAB_AUDIT_HISTORY_COMMON_API_CALLS,
      },
      'cross-references': {
        content: TabAgreementXrefs,
        apiCalls: fromApiCalls.getAgreementXrefs,
      },
      writers: {
        content: TabAgreementWriters,
        apiCalls: detail => (detail?.attributes?.id ? [fromApiCalls.getAgreementWritersSummary] : []),
      },
      notes: {
        content: TabNotes,
      },
    },
  },
  repertoires: {
    config: SectionCopyrightRepertoires,
    apiCalls: detail => [[fromApiCalls.getAuditHistory, SequenceType.Merge]],
    search: {
      datatable: (tservice, tloader, store) => new CopyrightRepertoiresDataTable(tservice, tloader),
      cleaner: SearchRepertoiresParser,
      form: (fuseTranslateService, fuseTranslationLoaderService) => new SearchCopyrightRepertoiresForm(fuseTranslateService, fuseTranslationLoaderService),
    },
    stepper: {
      steps: RepertoireSteps,
      initModel: {},
    },
    tabs: {
      details: {
        content: TabRepertoireDetail,
      },
      'included-works': {
        content: TabRepertoireIncludedWorks,
        apiCalls: fromApiCalls.getIncludedWorks,
      },
      'creator-affiliations': {
        content: TabRepertoireCreatorAffiliations,
      },
      'right-types': {
        content: TabRepertoireRightTypes,
      },
      'additional-collectors': {
        content: TabRepertoireAdditionalCollectors,
      },
      'contracting-companies': {
        content: TabRepertoireContractingCompanies,
      },
      'excluded-agreements': {
        content: TabRepertoireExcludedAgreements,
        apiCalls: fromApiCalls.getExcludedAgreements,
      },
      'excluded-works': {
        content: TabRepertoireExcludedWorks,
        apiCalls: fromApiCalls.getExcludedWorks,
      },
      'audit-history': {
        content: TabRepertoireAuditHistory,
        apiCalls: TAB_AUDIT_HISTORY_COMMON_API_CALLS,
      },
      notes: {
        content: TabNotes,
      },
    },
  },
  societies: {
    config: SectionCopyrightSocieties,
    search: {
      datatable: (tservice, tloader, store) => new CopyrightSocietiesDataTable(tservice, tloader),
      cleaner: SearchSocietiesParser,
      form: (fuseTranslateService, fuseTranslationLoaderService) => new SearchCopyrightSocietiesForm(fuseTranslateService, fuseTranslationLoaderService),
    },
    tabs: {
      details: {
        content: TabSocietyDetail,
      },
    },
  },
  territories: {
    config: SectionCopyrightTerritories,
    search: {
      datatable: (tservice, tloader, store) => new CopyrightTerritoriesDataTable(tservice, tloader),
      cleaner: SearchTerritoryParser,
      form: (fuseTranslateService, fuseTranslationLoaderService) => new SearchCopyrightTerritoriesForm(fuseTranslateService, fuseTranslationLoaderService),
    },
    tabs: {
      details: {
        content: TabTerritoryDetail,
        apiCalls: [fromApiCalls.getIncludedCountries, fromApiCalls.getSuccessorsList, fromApiCalls.getPredecessorsList, fromApiCalls.getIncludedCountriesMembers],
      },
    },
  },
  organizations: {
    config: SectionUserManagementOrganizations,
    apiCalls: fromApiCalls.getOrganizationsAgreementApplications,
    search: {
      datatable: (tservice, tloader, store) => new UserManagementOrganizationsDataTable(tservice, tloader),
      cleaner: SearchOrganizationsParser,
      form: (fuseTranslateService, fuseTranslationLoaderService) => new SearchUserManagementOrganizationsForm(fuseTranslateService, fuseTranslationLoaderService),
    },
    stepper: {
      steps: OrganizationSteps,
      initModel: OrganizationInitModel,
    },
    tabs: {
      details: {
        content: TabOrganizationDetail,
        apiCalls: fromApiCalls.getOrganizationsPartyNames,
      },
      users: {
        content: TabOrganizationUsers,
        apiCalls: fromApiCalls.getOrganizationsUsers,
      },
    },
  },
  users: {
    config: SectionUserManagementUsers,
    search: {
      datatable: (tservice, tloader, store) => new UserManagementUsersDataTable(tservice, tloader),
      cleaner: SearchUsersParser,
      form: (fuseTranslateService, fuseTranslationLoaderService, searchService, store, fieldValidatorService) =>
        new SearchUserManagementUsersForm(store, fuseTranslateService, fuseTranslationLoaderService, fieldValidatorService),
    },
    stepper: {
      steps: UserSteps,
      initModel: UserInitModel,
    },
    tabs: {
      details: {
        content: TabUsersDetail,
      },
    },
  },
  activity: {
    config: SectionConflictsActivity,
    search: {
      datatable: (tservice, tloader, store) => new ConflictsActivityDataTable(tservice, tloader, store),
      cleaner: SearchActivityParser,
      form: (fuseTranslateService, fuseTranslationLoaderService, searchService, store) =>
        new SearchConflictsActivityForm(store, fuseTranslateService, fuseTranslationLoaderService),
    },
    tabs: {
      analysis: {
        defaultHidden: true,
        container: AnalysisAndComparisonsConfig,
        content: TabActivityMatchAnalysis,
        apiCalls: [fromApiCalls.getWorkAnalysis, fromApiCalls.getWorkToCompare, fromApiCalls.getWorksToComparesTitles],
      },
      'analysis-merge': {
        defaultHidden: true,
        container: AnalysisAndComparisonsConfig,
        content: TabActivityMergeAnalysis,
        apiCalls: [fromApiCalls.getWorkAnalysis, fromApiCalls.getWorkToCompare, fromApiCalls.getWorksToComparesTitles, fromApiCalls.scopeOfConflicts],
      },
    },
  },
  'counter-claims-actions': {
    config: SectionConflictsCounterClaimsActions,
    search: {
      datatable: (tservice, tloader, store, newItem, permissionsService) => new ConflictsActionsDataTable(tservice, tloader, store, newItem, permissionsService),
      cleaner: SearchActionsParser,
      form: (fuseTranslateService, fuseTranslationLoaderService) => new SearchConflictsCounterClaimsActionsForm(fuseTranslateService, fuseTranslationLoaderService),
    },
  },
  'counter-claims': {
    config: SectionConflictsCounterClaims,
    apiCalls: [fromApiCalls.getOtherCounterClaimsCount, fromApiCalls.getClaimGraphCounterClaim, fromApiCalls.getAuditHistoryCounterClaims, SequenceType.Merge],
    search: {
      searchSection: 'counter-claims-actions',
      datatable: (tservice, tloader, store, newItem, permissionsService) => new ConflictsActionsDataTable(tservice, tloader, store, newItem, permissionsService),
      cleaner: SearchActionsParser,
      form: (fuseTranslateService, fuseTranslationLoaderService) => new SearchConflictsCounterClaimsActionsForm(fuseTranslateService, fuseTranslationLoaderService),
    },
    stepper: {
      steps: CounterClaimRegistrationSteps,
      initModel: CounterClaimInitModel,
    },
    homeConfig: {
      cleaner: SearchActionsParser,
      content: DashboardConflictsCounterClaims,
      initialModel: DashBoardInitialModel,
      location: '/conflicts/counter-claims/dashboard',
      apiCalls: fromApiCallConstructors.getCounterClaimsDashboardCounters,
    },
    tabs: {
      details: {
        content: TabCounterClaimsDetail,
      },
      documents: {
        content: TabCounterClaimsDocuments,
      },
      xref: {
        content: TabCounterClaimsXref,
      },
      'other-counter-claims': {
        content: TabCounterClaimsOtherCounterClaims,
        apiCalls: fromApiCalls.getOtherCounterClaims,
      },
      actions: {
        content: TabCounterClaimsActions,
      },
      'audit-history': {
        content: TabCounterClaimsAuditHistory,
      },
    },
  },
  'counter-claim-support': {
    config: SectionCounterClaimsSupport,
    search: {
      form: (fuseTranslateService, fuseTranslationLoaderService) => new SearchConflictsCounterClaimsForm(fuseTranslateService, fuseTranslationLoaderService),
    },
    stepper: {
      steps: CounterClaimSupportSteps,
      initModel: {},
    },
  },
  'agreement-conflict': {
    config: SectionConflictsAgreementConflict,
    search: {
      datatable: (tservice, tloader, store) => new ConflictsAgreementConflictDataTable(tservice, tloader),
      cleaner: SearchAgreementConflictParser,
      form: (fuseTranslateService, fuseTranslationLoaderService) => new SearchConflictsAgreementConflictForm(fuseTranslateService, fuseTranslationLoaderService),
    },
    stepper: {
      steps: AgreementConflictSteps,
      initModel: { cases: [], agreements: [] },
    },
    tabs: {
      details: {
        content: TabAgreementConflict,
      },
    },
  },
  'agreement-group': {
    config: SectionAgreementGroup,
    apiCalls: [fromApiCalls.getAuditHistory, SequenceType.Merge],
    search: {
      datatable: (tservice, tloader, store) => new CopyrightAgreementGroupDataTable(tservice, tloader),
      cleaner: SearchAgreementGroupParser,
      form: (fuseTranslateService, fuseTranslationLoaderService) => new SearchCopyrightAgreementGroupForm(fuseTranslateService, fuseTranslationLoaderService),
    },
    stepper: {
      steps: AgreementGroupSteps,
      initModel: { description: '', ipNameKey: '', ipName: '', partyNameId: '' },
    },
    tabs: {
      details: {
        content: TabAgreementGroupDetail,
      },
      'audit-history': {
        content: TabAgreementGroutpAuditHistory,
        apiCalls: TAB_AUDIT_HISTORY_COMMON_API_CALLS,
      },
    },
  },
  'bulk-updates': {
    config: SectionCopyrightBulkUpdates,
    apiCalls: [],
    search: {
      datatable: (tservice, tloader, store) => new CopyrightBulkUpdatesDataTable(tservice, tloader, store),
      cleaner: SearchBulkUpdatesParser,
      form: (fuseTranslateService, fuseTranslationLoaderService, searchService, store) =>
        new SearchCopyrightBulkUpdatesForm(fuseTranslateService, fuseTranslationLoaderService, store),
    },
    homeConfig: {
      cleaner: SearchBulkUpdatesParser,
      content: DashboardBulkUpdates,
      initialModel: {},
      location: '/bulk-updates/dashboard',
      apiCalls: [fromApiCalls.getBulkUpdateJobs],
    },
    stepper: {
      steps: BulkUpdatesSteps,
      initModel: {},
    },
  },
  'counterclaim-notifications': {
    config: SectionConflictsCounterclaimNotifications,
    stepper: {
      steps: CounterclaimNotificationsSteps,
      initModel: {},
    },
  },
  reports: {
    config: SectionCopyrightReports,
    subSections: {
      [REPORT_TYPES.SHARE]: {
        stepper: {
          steps: ReportShareSteps,
          initModel: null,
          formatter: ReportShareFormatter.formatter,
        },
      },
      [REPORT_TYPES.IPS_SHARES]: {
        stepper: {
          steps: ReportWorkDetailsSteps,
          initModel: null,
          formatter: ReportWorkDetailsIPSharesFormatter.formatter,
          parseClone: ReportWorkDetailsIPSharesFormatter.parseClone,
        },
      },
      [REPORT_TYPES.WORK_DETAILS_GENERATE_FICHE]: {
        stepper: {
          steps: ReportWorkDetailsGenerateFicheSteps,
          initModel: null,
          formatter: ReportWorkDetailsGenerateFicheFormatter.formatter,
          parseClone: ReportWorkDetailsGenerateFicheFormatter.parseClone,
        },
      },
      [REPORT_TYPES.LIST_WORKS_HEADER]: {
        stepper: {
          steps: ReportListWorksSteps,
          initModel: null,
          formatter: ReportListWorksFormatter.formatter,
          parseClone: ReportListWorksFormatter.parseClone,
        },
      },
    },
    search: {
      datatable: (tservice, tloader, store) => new CopyrightReportsDataTable(tservice, tloader),
      cleaner: SearchReportsParser,
      form: (fuseTranslateService, fuseTranslationLoaderService, searchService, store, fieldValidatorService) =>
        new SearchReportsForm(fuseTranslateService, fuseTranslationLoaderService, searchService, store, fieldValidatorService),
    },
    tabs: {
      details: {
        content: TabAgreementGroupDetail,
      },
    },
  },
};
