import { isObject } from 'lodash';

/**
 * Calculate the size of a JSON object in KB.
 *
 * If `options.formatted` is true, the result will be a string in the format 'XKB'.
 * If `options.formatted` is false (or not provided), the result will be the size in KB as a number.
 *
 * @param jsonObject The object to be analyzed.
 * @param [options] Options for the function.
 * @param [options.formatted] If true, the result will be a string in the format 'XKB'.
 * @returns The size of the object in KB.
 */
export function getSizeInKB(jsonObject: object, options: { formatted: true }): string;
export function getSizeInKB(jsonObject: object, options?: { formatted?: false }): number;

export function getSizeInKB(jsonObject, options: { formatted?: boolean } = {}): string | number {
  const { formatted } = options;
  // Convert the JSON object to a string
  const jsonString = JSON.stringify(jsonObject);

  // Use TextEncoder to get the byte size
  const byteSize = new TextEncoder().encode(jsonString).length;

  // Calculate size in KB (1 KB = 1024 bytes)
  const sizeInKB = byteSize / 1024;
  if (!formatted) return sizeInKB;

  return Intl.NumberFormat().format(Math.round(sizeInKB)) + 'KB';
}
export interface JsonSize {
  size: string;
  children: { [key: string]: JsonSize };
}
export function analyzeJsonSize(jsonObject: any): JsonSize {
  if (!isObject(jsonObject)) {
    return { size: '0KB', children: {} };
  }

  const size = getSizeInKB(jsonObject, { formatted: true });

  const children: { [key: string]: JsonSize } = {};
  Object.keys(jsonObject).forEach(key => {
    children[key] = analyzeJsonSize(jsonObject[key]);
  });

  return { size, children };
}
